import React, { useId, useMemo, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  Typography,
  Grid,
  IconButton,
  Stack,
  Box,
  FormControl,
  Autocomplete,
  TablePagination,
  ListItem
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  addNewEmploymentEvent,
  createEmploymentSchema,
  selectCalendarItems,
  selectEmploymentCategoryTableData,
  selectEmploymentTypes,
  selectWorkingPatterns
} from '@features/employee-profile';
import {
  AddIcon,
  AlertIcon,
  ChevronDownIcon,
  AddNewRow,
  CancelButton,
  UpdateButton,
  RowCenterStack,
  StyledModalContent,
  StyledAutocomplete,
  StyledTransparentInput,
  StyledTransparentInputDatePicker
} from '@/shared';
import { useAppSelector } from '@app/hooks';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DayOfWeek, EmploymentContractType, EmploymentEvent, FlsaCode, SalaryPayType, WorkDay } from '@thrivea/organization-client';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import { zodResolver } from '@hookform/resolvers/zod';
import { snakeCase } from 'lodash';

interface EmployeeTableProps {
  isEditable: boolean;
}

const employmentContractTypes = Object.keys(EmploymentContractType)
  .filter((key) => !isNaN(Number(key)))
  .slice(1) //exclude first option
  .map((key) => ({
    id: parseInt(key, 10) - 1, //deduct by 1 as we are removing first option
    // removes underscores and sets only the first letter to uppercase
    name: EmploymentContractType[Number(key)].toLowerCase()
  }));

const flsaCodes = Object.keys(FlsaCode)
  .filter((key) => !isNaN(Number(key)))
  .slice(1) //exclude first option
  .map((key) => ({
    id: parseInt(key, 10) - 1, //deduct by 1 as we are removing first option
    // removes underscores and sets only the first letter to uppercase
    name: FlsaCode[Number(key)].toLowerCase()
  }));

const salaryPayTypes = Object.keys(SalaryPayType)
  .slice(1) //exclude first option
  .filter((key) => !isNaN(Number(key)))
  .map((key) => ({
    id: parseInt(key, 10) - 1, //deduct by 1 as we are removing first option
    // removes underscores and sets only the first letter to uppercase
    name: SalaryPayType[Number(key)].toLowerCase()
  }));

export const EmploymentCategoryTable = ({ isEditable }: EmployeeTableProps) => {
  const id = useId();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation(['common', 'employee_profile', 'employment_contract_type', 'salary_pay_type', 'flsa_codes']);
  const dispatch = useDispatch();
  const [workingDays, setWorkingDays] = useState<WorkDay[]>([]);
  const [totalWeeklyHours, setTotalWeeklyHours] = useState<number>();
  const employmentCategorySchema = useMemo(() => createEmploymentSchema(t), [t]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, errors },
    setValue
  } = useForm<EmploymentEvent>({
    mode: 'all',
    resolver: zodResolver(employmentCategorySchema),
    defaultValues: {
      effectiveDate: '',
      employmentContract: 0,
      employmentTypeId: '',
      flsaCode: 0,
      salaryPayType: 0,
      vacationCalendarId: '',
      workingPatternId: '',
      reason: ''
    }
  });

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const resetStates = () => {
    reset({
      effectiveDate: '',
      employmentContract: 0,
      employmentTypeId: '',
      flsaCode: 0,
      salaryPayType: 0,
      vacationCalendarId: '',
      workingPatternId: '',
      reason: ''
    });
    setWorkingDays([]);
    setTotalWeeklyHours(undefined);
  };
  const handleModalOpen = () => {
    resetStates();
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    resetStates();
    setIsModalOpen(false);
  };

  const handleRowEdit = (event: React.MouseEvent<HTMLElement>, id: string) => {
    event.stopPropagation();
    // setActiveEditModal(id);
  };

  const employmentCategoryTableData = useAppSelector(selectEmploymentCategoryTableData);
  const employmentTypes = useAppSelector(selectEmploymentTypes);
  const workingPatterns = useAppSelector(selectWorkingPatterns);
  const calendarItems = useAppSelector(selectCalendarItems);

  const onSubmit = (data: EmploymentEvent) => {
    dispatch(addNewEmploymentEvent(data));
    resetStates();
    setIsModalOpen(false);
  };

  return (
    <Stack
      gap={2}
      sx={{
        height: '100%',
        justifyContent: 'stretch',
        padding: 0
      }}
    >
      <Paper
        elevation={0}
        sx={{
          width: '100%',
          overflow: 'hidden',
          '&.MuiPaper-root': {
            backgroundColor: (theme) => (employmentCategoryTableData!.length === 0 ? theme.palette.primary.light : 'transparent')
          }
        }}
      >
        <Stack
          gap={2}
          sx={{
            flex: 1,
            justifyContent: 'stretch'
          }}
        >
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{
              flex: 1,
              position: 'relative'
            }}
          >
            {employmentCategoryTableData.length === 0 && (
              <Stack
                sx={{
                  width: '100%',
                  height: 250,
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '16px',
                  '& svg': {
                    width: 24,
                    height: 24
                  }
                }}
              >
                <AlertIcon />
                <Typography>{t('empty_table', { ns: 'employee_profile' })}</Typography>
                <AddNewRow variant="outlined" endIcon={<AddIcon />} onClick={handleModalOpen} disabled={!isEditable}>
                  {t('add_new_row', { ns: 'common' })}
                </AddNewRow>
              </Stack>
            )}
            {employmentCategoryTableData.length !== 0 && (
              <>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('effective_date', { ns: 'employee_profile' })}</TableCell>
                      <TableCell>{t('employment_contract', { ns: 'employee_profile' })}</TableCell>
                      <TableCell>{t('employment_type_id', { ns: 'employee_profile' })}</TableCell>
                      <TableCell>{t('flsa_code', { ns: 'employee_profile' })}</TableCell>
                      <TableCell>{t('salary_pay_type', { ns: 'employee_profile' })}</TableCell>
                      <TableCell>{t('vacation_calendar_id', { ns: 'employee_profile' })}</TableCell>
                      <TableCell>{t('working_pattern', { ns: 'employee_profile' })}</TableCell>
                      <TableCell>{t('reason', { ns: 'employee_profile' })}</TableCell>
                      {/* <TableCell
                        sx={{
                          position: 'sticky',
                          right: 0,
                          backgroundColor: (theme) => theme.palette.common.white,
                          minWidth: '90px',
                          height: '100%',
                          boxShadow: '-5px 0px 3px 0px rgba(0,0,0,0.12)'
                        }}
                      /> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {employmentCategoryTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
                      <TableRow hover key={rowIndex}>
                        <TableCell>{row.effectiveDate}</TableCell>
                        <TableCell>{t(employmentContractTypes[row.employmentContract].name, { ns: 'employment_contract_type' })}</TableCell>
                        <TableCell>{employmentTypes.find((employmentType) => employmentType.id === row.employmentTypeId)!.name}</TableCell>
                        <TableCell>{t(flsaCodes[row.flsaCode].name, { ns: 'flsa_codes' })}</TableCell>
                        <TableCell> {t(salaryPayTypes[row.salaryPayType].name, { ns: 'salary_pay_type' })}</TableCell>
                        <TableCell>{calendarItems.find((calendar) => calendar.id === row.vacationCalendarId)!.name}</TableCell>
                        <TableCell>{workingPatterns.find((workingPattern) => workingPattern.id === row.workingPatternId)!.name}</TableCell>
                        <TableCell>{row.reason}</TableCell>
                        {/* TODO - Implement after adding edit/delete actions */}
                        {/* <TableCell
                          sx={{
                            position: 'sticky',
                            right: 0,
                            backgroundColor: (theme) => theme.palette.common.white,
                            minWidth: '90px',
                            height: '100%',
                            boxShadow: '-5px 0px 3px 0px rgba(0,0,0,0.12)',
                            textAlign: 'center'
                          }}
                        >
                          <IsolatedMenu id={crypto.randomUUID()} handleRowEdit={(e) => handleRowEdit(e, crypto.randomUUID())} />
                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20]}
                  component="div"
                  count={employmentCategoryTableData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <AddNewRow
                  variant="outlined"
                  endIcon={<AddIcon />}
                  onClick={handleModalOpen}
                  disabled={!isEditable}
                  sx={{
                    ml: 2,
                    mb: 2
                  }}
                >
                  {t('add_new_row', { ns: 'common' })}
                </AddNewRow>
              </>
            )}
          </TableContainer>
        </Stack>
        <Modal
          open={isModalOpen}
          onClose={handleModalClose}
          sx={{
            background: 'rgba(217, 217, 217, 0.60)',
            backdropFilter: 'blur(10px)'
          }}
        >
          <StyledModalContent
            sx={{
              position: 'relative',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: {
                xs: 'auto',
                lg: '1316px'
              },
              height: {
                xs: '80%',
                lg: 'auto'
              },
              padding: '120px',
              display: 'flex',
              borderRadius: '20px',
              boxShadow: '0px 5px 12px 0px rgba(0, 0, 0, 0.10)'
            }}
          >
            <Typography
              variant="h3"
              sx={{
                mb: 3
              }}
            >
              {t('add_new_row', { ns: 'common' })}
            </Typography>
            <Grid
              container
              columnSpacing={2}
              rowGap={2}
              sx={{
                mt: 2,
                mb: 4
              }}
            >
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                  width: '100%'
                }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    padding: 2
                  }}
                >
                  <Grid item xs={12} sm={6} lg={4}>
                    <Controller
                      name="effectiveDate"
                      control={control}
                      rules={{
                        required: true
                      }}
                      render={({ field, fieldState }) => (
                        <FormControl fullWidth>
                          <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePicker
                              {...field}
                              className="MuiDate-root"
                              format="yyyy-MM-dd"
                              value={field.value ? DateTime.fromISO(field.value) : null}
                              disableFuture
                              slots={{ textField: StyledTransparentInputDatePicker }}
                              slotProps={{
                                textField: {
                                  id: id + field.name,
                                  placeholder: 'YYYY-MM-DD',
                                  variant: 'filled',
                                  label: t(snakeCase(field.name), { ns: 'employee_profile' }),
                                  error: !!fieldState.error,
                                  helperText: fieldState.error?.message,
                                  required: true,
                                  fullWidth: true,
                                  InputProps: {
                                    disableUnderline: true,
                                    required: true,
                                    onBlur: () => {
                                      field.onBlur();
                                    }
                                  },
                                  InputLabelProps: {
                                    shrink: true
                                  }
                                }
                              }}
                              onChange={(date: DateTime | null) => {
                                if (date) {
                                  const jsDate = date.toJSDate();
                                  const stringDate = DateTime.fromJSDate(jsDate).toFormat('yyyy-MM-dd');
                                  field.onChange(stringDate);
                                }
                              }}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <Controller
                      name="employmentContract"
                      control={control}
                      rules={{
                        required: true
                      }}
                      render={({ field, fieldState }) => (
                        <StyledAutocomplete
                          popupIcon={<ChevronDownIcon />}
                          disableClearable={field.value !== null}
                          options={employmentContractTypes}
                          getOptionLabel={(option) => t(option.name, { ns: 'employment_contract_type' })}
                          onChange={(_, value) => field.onChange(value!.id)}
                          value={employmentContractTypes.find((option) => option.id === field.value) || null}
                          renderInput={(params) => (
                            <StyledTransparentInput
                              {...params}
                              id={id + field.name}
                              label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              required
                              onBlur={() => field.onBlur()}
                            />
                          )}
                          renderOption={(props, option) => (
                            <ListItem {...props} key={crypto.randomUUID()}>
                              {t(option.name, { ns: 'employment_contract_type' })}
                            </ListItem>
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="employmentTypeId"
                      control={control}
                      rules={{
                        required: true
                      }}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          popupIcon={<ChevronDownIcon />}
                          disableClearable={field.value !== null}
                          limitTags={2}
                          options={employmentTypes}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value, reason) => {
                            if (reason === 'blur' && !value) {
                              setValue('employmentTypeId', '');
                            } else {
                              field.onChange(value!.id);
                            }
                          }}
                          renderInput={(params) => (
                            <StyledTransparentInput
                              {...params}
                              id={id + field.name}
                              label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              required
                              onBlur={() => field.onBlur()}
                            />
                          )}
                          renderOption={(props, option) => (
                            <ListItem {...props} key={crypto.randomUUID()}>
                              {t(option.name, { ns: 'employment_contract_type' })}
                            </ListItem>
                          )}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={4}>
                    <Controller
                      name="flsaCode"
                      control={control}
                      rules={{
                        required: true
                      }}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          popupIcon={<ChevronDownIcon />}
                          disableClearable={field.value !== null}
                          options={flsaCodes}
                          getOptionLabel={(option) => t(option.name, { ns: 'flsa_codes' })}
                          onChange={(_, value) => field.onChange(value!.id)}
                          value={flsaCodes.find((option) => option.id === field.value) || null}
                          renderInput={(params) => (
                            <StyledTransparentInput
                              {...params}
                              id={id + field.name}
                              label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              required
                              onBlur={() => field.onBlur()}
                            />
                          )}
                          renderOption={(props, option) => (
                            <ListItem {...props} key={crypto.randomUUID()}>
                              {t(option.name, { ns: 'flsa_codes' })}
                            </ListItem>
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <Controller
                      name="salaryPayType"
                      control={control}
                      rules={{
                        required: true
                      }}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          popupIcon={<ChevronDownIcon />}
                          disableClearable={field.value !== null}
                          options={salaryPayTypes}
                          getOptionLabel={(option) => t(option.name, { ns: 'salary_pay_type' })}
                          onChange={(_, value) => {
                            field.onChange(value!.id);
                          }}
                          value={salaryPayTypes.find((option) => option.id === field.value) || null}
                          renderInput={(params) => (
                            <StyledTransparentInput
                              {...params}
                              id={id + field.name}
                              label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              required
                              onBlur={() => field.onBlur()}
                            />
                          )}
                          renderOption={(props, option) => (
                            <ListItem {...props} key={crypto.randomUUID()}>
                              {t(option.name, { ns: 'salary_pay_type' })}
                            </ListItem>
                          )}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={4}>
                    <Controller
                      name="vacationCalendarId"
                      control={control}
                      rules={{
                        required: true
                      }}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          popupIcon={<ChevronDownIcon />}
                          disableClearable={field.value !== null}
                          limitTags={2}
                          options={calendarItems}
                          getOptionLabel={(option) => option.name}
                          onChange={(_, value, reason) => {
                            if (reason === 'blur' && !value) {
                              setValue('vacationCalendarId', '');
                            } else {
                              field.onChange(value!.id);
                            }
                          }}
                          renderInput={(params) => (
                            <StyledTransparentInput
                              {...params}
                              id={id + field.name}
                              label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              required
                              onBlur={() => field.onBlur()}
                            />
                          )}
                          renderOption={(props, option) => (
                            <ListItem {...props} key={crypto.randomUUID()}>
                              {option.name}
                            </ListItem>
                          )}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={4}>
                    <Controller
                      name="workingPatternId"
                      control={control}
                      rules={{
                        required: true
                      }}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          popupIcon={<ChevronDownIcon />}
                          disableClearable={field.value !== null}
                          limitTags={2}
                          options={workingPatterns}
                          getOptionLabel={(option) => option.name}
                          onChange={(_, value, reason) => {
                            if (reason === 'blur' && !value) {
                              setValue('workingPatternId', '');
                            } else {
                              const workingPattern = workingPatterns.find((wp) => wp.id === value!.id);
                              setWorkingDays(workingPattern!.workWeek!.workDays);
                              setTotalWeeklyHours(workingPattern!.workWeek!.workDays.reduce((acc, workDay) => acc + workDay.hours, 0));
                              field.onChange(value!.id);
                            }
                          }}
                          renderInput={(params) => (
                            <StyledTransparentInput
                              {...params}
                              id={id + field.name}
                              label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              required
                              onBlur={() => field.onBlur()}
                            />
                          )}
                          renderOption={(props, option) => (
                            <ListItem {...props} key={crypto.randomUUID()}>
                              {option.name}
                            </ListItem>
                          )}
                        />
                      )}
                    />
                    <RowCenterStack
                      sx={{
                        width: '100%',
                        mt: 6
                      }}
                    >
                      {workingDays.length > 0 &&
                        workingDays
                          .sort((workDayOne, workDayTwo) => (workDayOne.day > workDayTwo.day ? 1 : -1))
                          .map((workDay) => (
                            <Stack
                              key={workDay.id}
                              sx={{
                                position: 'relative',
                                alignItems: 'center',
                                '&:first-of-type': {
                                  borderLeft: (theme) => `1px solid ${theme.palette.divider}`
                                }
                              }}
                            >
                              <Typography
                                sx={{
                                  textTransform: 'capitalize',
                                  position: 'absolute',
                                  top: '-12px',
                                  left: '50%',
                                  transform: 'translateX(-50%)',
                                  fontSize: '12px'
                                }}
                              >
                                {DayOfWeek[workDay.day].slice(0, 3)}
                              </Typography>
                              <Box
                                className="Mui-WorkDay"
                                sx={{
                                  borderRight: (theme) => `1px solid ${theme.palette.divider}`,
                                  backgroundColor: 'transparent',
                                  padding: 1,
                                  width: 60,
                                  height: 40,
                                  textAlign: 'center',
                                  fontWeight: 700,
                                  lineHeight: 1.5
                                }}
                              >
                                {workDay.hours}
                              </Box>
                            </Stack>
                          ))}
                      {totalWeeklyHours && (
                        <RowCenterStack
                          gap={3}
                          sx={{
                            ml: 4,
                            mt: -2
                          }}
                        >
                          <Typography variant="body2">{t('weekly_total', { ns: 'employee_profile' })}</Typography>
                          <Typography
                            sx={{
                              fontWeight: 600
                            }}
                          >
                            {totalWeeklyHours} {t('hours', { ns: 'common' })}
                          </Typography>
                        </RowCenterStack>
                      )}
                    </RowCenterStack>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <Controller
                      name="reason"
                      control={control}
                      render={({ field, fieldState }) => (
                        <StyledTransparentInput
                          {...field}
                          id={id + field.name}
                          label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          onChange={(e) => field.onChange(e)}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <RowCenterStack
                  gap={2}
                  sx={{
                    justifyContent: 'center'
                  }}
                >
                  <CancelButton variant="outlined" onClick={handleModalClose}>
                    {t('cancel', { ns: 'common' })}
                  </CancelButton>
                  <UpdateButton variant="contained" disabled={Object.values(errors).length !== 0} type="submit">
                    {t('update', { ns: 'common' })}
                  </UpdateButton>
                </RowCenterStack>
              </Box>
            </Grid>
            <IconButton
              disableRipple
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                zIndex: 1
              }}
              onClick={handleModalClose}
            >
              <CloseIcon />
            </IconButton>
          </StyledModalContent>
        </Modal>
      </Paper>
    </Stack>
  );
};
