import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';

export const mainLightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#251D38',
      light: '#DDD5E8',
      dark: '#7B61FF'
    },
    secondary: {
      main: '#2FBCC4',
      dark: '#CEAEF7',
      light: '#F5EDFF'
    },
    action: {
      focus: '#9747FF'
    },
    background: {
      default: '#EAEAEA',
      paper: '#FFFFFF'
    },
    text: {
      primary: '#251D38',
      secondary: '#424343'
    },
    success: {
      main: '#2FBCC4'
    },
    grey: {
      50: '#F3F2F3',
      100: '#DADADA',
      200: '#A1A1A1',
      400: '#9098a5',
      500: '#C4C4C4',
      600: '#F2F2F2',
      700: '#EEEEEE',
      800: '#E7E7E7',
      900: '#898989'
    }
  },
  typography: {
    h1: {
      fontSize: 36,
      fontWeight: 800
    },
    h2: {
      fontSize: '2.25rem',
      fontWeight: 300
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 700
    },
    h4: {
      fontSize: 24,
      fontWeight: 700,
      color: '#251D38'
    },
    h5: {
      fontSize: 20
    },
    h6: {
      fontSize: 16
    },
    subtitle1: {
      fontSize: 20,
      fontWeight: 700
    },
    subtitle2: {
      fontSize: '20px',
      fontWeight: 700,
      color: '#7B61FF'
    },
    body1: {
      fontSize: '16px',
      fontWeight: 400,
      color: '#251D38',
      lineHeight: 'normal'
    },
    body2: {
      fontSize: '14px',
      fontWeight: '400',
      color: '#251D38',
      lineHeight: 'normal'
    },
    button: {
      fontSize: '16px',
      fontFamily: 'Karla',
      fontWeight: 400,
      minWidth: 90,
      textTransform: 'capitalize'
    },
    fontFamily: [
      'Karla',
      'Syncopate',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h4: {
          fontSize: 24
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        background: 'background.default',
        '&::-webkit-scrollbar': {
          width: 7,
          height: 7
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(37, 29, 56, 0.45)',
          borderRadius: '7px',
          border: '4px solid rgba(0, 0, 0, 0'
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          '&.MuiMenuItem-root.Mui-focusVisible': {
            backgroundColor: 'rgba(47, 188, 196, 0.40)'
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        disableFocusRipple: true,
        disableTouchRipple: true
      }
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiTabs: {
      defaultProps: {
        TabIndicatorProps: {
          hidden: true
        }
      },
      styleOverrides: {
        flexContainer: {
          gap: '4px'
        }
      }
    },
    MuiTab: {
      defaultProps: {},
      styleOverrides: {
        root: {
          minHeight: 'auto',
          lineHeight: 'normal',
          padding: '7px 18px',
          backgroundColor: 'grey[600]',
          borderRadius: '16px',
          '&.Mui-selected': {
            backgroundColor: '#CEAEF7'
          }
        }
      }
    },
    MuiInputBase: {
      defaultProps: {
        disableInjectingGlobalStyles: true
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            '& svg': {
              display: 'none'
            }
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'common.black',
          fontWeight: 500,
          '&.Mui-disabled': {
            color: '#908C98'
          }
        }
      }
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          width: 340,
          color: '#000',
          border: 0,
          borderRadius: 4,
          backgroundColor: '#EEEEF1'
        }
      }
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          backgroundColor: '#FFFFFF',
          fontWeight: 700,
          '&.Mui-selected': {
            backgroundColor: '#0047FF'
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiButtonBase-root.Mui-disabled': {
            color: '#FFFFFF',
            backgroundColor: '#0000008a'
          }
        }
      }
    },
    MuiStack: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '& .MuiListItemButton-root': {
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiFilledInput-root': {
            paddingTop: '12px'
          }
        },
        listbox: {
          overflowX: 'hidden',
          '& .MuiAutocomplete-option.MuiListItem-root': {
            padding: '8px',
            borderLeft: `7px solid`,
            borderColor: 'transparent',
            borderRadius: '4px',
            transition: 'border-color, background-color 0.6 ease-in-out'
          },
          '& .MuiAutocomplete-option.MuiListItem-root[aria-selected="true"]': {
            backgroundColor: '#DDD5E8',
            borderColor: '#9747FF'
          }
        },
        option: {
          backgroundColor: 'inherit',
          borderRadius: '7px',
          '&.Mui-focused.Mui-focusVisible': {
            backgroundColor: 'rgba(47, 188, 196, 0.40)',
            padding: '7px'
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minWidth: 70,
          borderRadius: '7px',
          margin: '0 8px',
          borderLeft: '7px solid',
          borderColor: 'transparent',
          '&:hover': {
            borderColor: '#9747FF'
          },
          '&.Mui-selected': {
            backgroundColor: '#CEAEF7',
            marginLeft: '8px'
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 14,
          '&.Mui-error': {
            color: '#9747FF'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          '& .MuiMenuItem-root': {
            borderRadius: '10px'
          },
          '& .Mui-selected': {
            backgroundColor: '#CEAEF7'
          }
        }
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: 'inherit'
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          tableLayout: 'fixed'
        }
      }
    },
    MuiAvatar: {
      defaultProps: {
        slotProps: {
          img: {
            loading: 'lazy'
          }
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 450,
      md: 900,
      lg: 1200,
      xl: 1718
    }
  }
});
