import { ChangeEvent, forwardRef, useId } from 'react';
import { MasterInput, RowCenterStack } from '@/shared';

interface MainInputProps {
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  label?: string;
  value?: any;
  required?: boolean;
  disabled?: boolean;
}

export const MainInput = forwardRef<HTMLInputElement, MainInputProps>(({ label, required, disabled, value, onChange }, ref) => {
  const inputId = useId();

  return (
    <RowCenterStack
      gap={1}
      sx={{
        position: 'relative',
        width: '100%',
        height: 50,
        backgroundColor: (theme) => (disabled ? theme.palette.grey[50] : theme.palette.common.white),
        pointerEvents: disabled ? 'none' : 'auto',
        display: 'flex',
        flexShrink: 0,
        padding: '16px 11px',
        border: '1px solid',
        borderRadius: '4px',
        borderColor: (theme) => (disabled ? 'transparent' : theme.palette.primary.main),
        color: (theme) => (disabled ? theme.palette.grey[200] : theme.palette.common.black),

        ':focus-within': {
          borderColor: (theme) => theme.palette.secondary.main
        }
      }}
    >
      <MasterInput
        id={inputId}
        required={required}
        label={label ?? 'Placeholder text'}
        value={value}
        onChange={onChange}
        ref={ref}
        disabled={disabled}
        InputLabelProps={{ shrink: true }}
      />
    </RowCenterStack>
  );
});
