import { forwardRef, useEffect, useState, useId, useMemo, ForwardedRef } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { createIdentificationSchema, selectIdentificationCategory, selectProfileNotification, updateIdentificationRequested } from '@features/employee-profile';
import { Controller, useForm } from 'react-hook-form';
import { Identification, UpdateIdentificationRequest } from '@thrivea/organization-client';
import { Box, CircularProgress, Grid, IconButton, Stack, Typography } from '@mui/material';
import {
  RowCenterStack,
  VisuallyHidden,
  EditIcon,
  CancelEditIcon,
  StyledFormHeader,
  StyledFormWrapper,
  StyledCancelButton,
  StyledFormSubmitButton,
  StyledFilledInput,
  ActionStatus
} from '@/shared';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { snakeCase } from 'lodash';

interface IdentificationCategoryProps {
  ref: ForwardedRef<HTMLDivElement>;
  employeeId: string;
}

export const IdentificationCategory = forwardRef<HTMLDivElement, IdentificationCategoryProps>(({ employeeId }, ref) => {
  const id = useId();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'employee_profile']);
  const name = t('identification', { ns: 'employee_profile' });
  const identificationCategory = useAppSelector(selectIdentificationCategory);
  const { status, category } = useAppSelector(selectProfileNotification);
  const isPendingAndMatchingName = status === ActionStatus.Pending && category === 'identification';
  const [isEditable, setIsEditable] = useState(false);
  const identificationCategorySchema = useMemo(() => createIdentificationSchema(t), [t]);

  const {
    formState: { dirtyFields, errors },
    control,
    handleSubmit,
    reset
  } = useForm<Identification>({
    mode: 'all',
    resolver: zodResolver(identificationCategorySchema),
    defaultValues: {
      passportNumber: identificationCategory.passportNumber,
      nationalId: identificationCategory.nationalId,
      niNumber: identificationCategory.niNumber,
      ssn: identificationCategory.ssn
    }
  });

  const handleCloseEditable = () => {
    setIsEditable(false);
    reset({
      passportNumber: identificationCategory.passportNumber,
      nationalId: identificationCategory.nationalId,
      niNumber: identificationCategory.niNumber,
      ssn: identificationCategory.ssn
    });
  };

  const handleToggleEditable = () => {
    setIsEditable(!isEditable);
  };

  const onSubmit = (data: Identification) => {
    dispatch(
      updateIdentificationRequested({
        employeeId,
        passportNumber: data.passportNumber,
        nationalId: data.nationalId,
        ssn: data.ssn,
        niNumber: data.niNumber
      } as UpdateIdentificationRequest)
    );
    setIsEditable(false);
  };

  useEffect(() => {
    reset({
      passportNumber: identificationCategory.passportNumber,
      nationalId: identificationCategory.nationalId,
      niNumber: identificationCategory.niNumber,
      ssn: identificationCategory.ssn
    });
  }, [identificationCategory]);

  return (
    <StyledFormWrapper isEditable={isEditable} id={snakeCase(name)} ref={ref}>
      <Box
        component="form"
        name={name}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          backgroundColor: 'transparent'
        }}
      >
        <Stack>
          <StyledFormHeader isEditable={isEditable} className="Mui-ProfileFiledHeader">
            <RowCenterStack gap={1}>
              {isPendingAndMatchingName && <CircularProgress size={24} thickness={4} />}
              <Typography component="h3" variant="h5" fontWeight="bold">
                {name}
              </Typography>
            </RowCenterStack>
            <IconButton
              disableRipple
              onClick={handleToggleEditable}
              sx={{
                opacity: '0',
                display: isEditable ? 'none' : 'inline-flex'
              }}
            >
              <VisuallyHidden component="span">Edit {name}</VisuallyHidden>
              <EditIcon />
            </IconButton>
            <RowCenterStack
              gap={2}
              sx={{
                display: isEditable ? 'flex' : 'none'
              }}
            >
              <StyledCancelButton variant="contained" onClick={handleCloseEditable} startIcon={<CancelEditIcon />}>
                {t('cancel', { ns: 'common' })}
              </StyledCancelButton>
              <StyledFormSubmitButton
                type="submit"
                disabled={Object.values(dirtyFields).length === 0 || Object.values(errors).length !== 0}
                variant="contained"
              >
                {t('done', { ns: 'common' })}
              </StyledFormSubmitButton>
            </RowCenterStack>
          </StyledFormHeader>
          <Grid
            container
            spacing={2}
            sx={{
              padding: 2
            }}
          >
            <Grid item xs={12} sm={6} lg={4}>
              <Controller
                name="passportNumber"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => field.onChange(e)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Controller
                name="nationalId"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => field.onChange(e)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Controller
                name="niNumber"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (val.match(/[^0-9]/)) {
                        return e.preventDefault();
                      }
                      field.onChange(e);
                    }}
                    inputProps={{ inputMode: 'numeric' }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Controller
                name="ssn"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => field.onChange(e)}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </StyledFormWrapper>
  );
});
