import { createGrpcWebTransport } from '@connectrpc/connect-web';
import { createPromiseClient } from '@connectrpc/connect';
import {
  PostService,
  PublishShoutoutRequest,
  RetrievePostsRequest,
  RetrievePostsResponse,
  CommentOnPostRequest,
  Comment,
  DeleteCommentRequest,
  Post,
  ReactToPostRequest,
  Reaction,
  ReactToCommentRequest,
  WithdrawPostReactionRequest,
  WithdrawCommentReactionRequest,
  RetrievePinnedPostsRequest,
  PinPostRequest,
  UnpinPostRequest,
  DeletePostRequest,
  RetrievePinnedPostRequest,
  RetrieveRecentPostsByAuthorRequest,
  RetrieveRecentPostsByAuthorResponse,
  SaveDraftPostRequest,
  DeleteDraftPostsRequest,
  RetrieveLastDraftPostRequest,
  DraftPost,
  EditShoutoutRequest,
  EditCommentRequest
} from '@thrivea/networking-client';
import { Empty } from '@bufbuild/protobuf';
import { tokenRequest } from '@utils/authConfig';
import { msalInstance } from '@/main';

export const postClient = createPromiseClient(
  PostService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    useBinaryFormat: true
  })
);

export const retrievePosts = async (request: RetrievePostsRequest): Promise<RetrievePostsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  const headers: HeadersInit = new Headers({ Authorization: `Bearer ${account.accessToken}` });
  return await postClient.retrievePosts(request, { headers });
};

export const deletePost = async (request: DeletePostRequest, signalRConnectionId: string): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.deletePost(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}`, 'x-signalr-connection': signalRConnectionId })
  });
};

export const publishShoutout = async (request: PublishShoutoutRequest, signalRConnectionId: string): Promise<Post> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.publishShoutout(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}`, 'x-signalr-connection': signalRConnectionId })
  });
};

export const commentOnPost = async (request: CommentOnPostRequest, signalRConnectionId: string): Promise<Comment> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.commentOnPost(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}`, 'x-signalr-connection': signalRConnectionId })
  });
};

export const deleteComment = async (request: DeleteCommentRequest, signalRConnectionId: string): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.deleteComment(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}`, 'x-signalr-connection': signalRConnectionId })
  });
};

export const reactToPost = async (request: ReactToPostRequest, signalRConnectionId: string): Promise<Reaction> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.reactToPost(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}`, 'x-signalr-connection': signalRConnectionId })
  });
};

export const withdrawPostReaction = async (request: WithdrawPostReactionRequest, signalRConnectionId: string): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.withdrawPostReaction(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}`, 'x-signalr-connection': signalRConnectionId })
  });
};

export const reactToComment = async (request: ReactToCommentRequest, signalRConnectionId: string): Promise<Reaction> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.reactToComment(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}`, 'x-signalr-connection': signalRConnectionId })
  });
};

export const withdrawCommentReaction = async (request: WithdrawCommentReactionRequest, signalRConnectionId: string): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.withdrawCommentReaction(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}`, 'x-signalr-connection': signalRConnectionId })
  });
};

export const retrievePinnedPosts = async (request: RetrievePinnedPostsRequest): Promise<RetrievePostsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.retrievePinnedPosts(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const pinPost = async (request: PinPostRequest, signalRConnectionId: string): Promise<Post> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.pinPost(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}`, 'x-signalr-connection': signalRConnectionId })
  });
};

export const unpinPost = async (request: UnpinPostRequest, signalRConnectionId: string): Promise<Post> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.unpinPost(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}`, 'x-signalr-connection': signalRConnectionId })
  });
};

export const retrievePinnedPost = async (request: RetrievePinnedPostRequest): Promise<Post> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.retrievePinnedPost(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrieveRecentPostsByAuthor = async (request: RetrieveRecentPostsByAuthorRequest): Promise<RetrieveRecentPostsByAuthorResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.retrieveRecentPostsByAuthor(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const saveDraftPost = async (request: SaveDraftPostRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.saveDraftPost(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrieveLastDraftPost = async (request: RetrieveLastDraftPostRequest): Promise<DraftPost> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.retrieveLastDraftPost(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const deleteDraftPosts = async (request: DeleteDraftPostsRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.deleteDraftPosts(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const editShoutout = async (request: EditShoutoutRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.editShoutout(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const editComment = async (request: EditCommentRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.editComment(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};
