import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RetrieveUserResponse } from '@thrivea/organization-client';
import { RootState } from '@app/store';
import { ActionStatus } from '@/shared/shared.model';
import { initials } from '@utils/initials';

export interface UserState {
  id: string;
  firstName: string;
  lastName: string;
  profilePictureUrl: string;
  userStatus: ActionStatus;
  displayName: string;
  tenantId: string;
}

// initialized with space because of the selectCurrentUserInitials logic (firstName[0])
const initialState: UserState = {
  id: '',
  firstName: ' ',
  lastName: ' ',
  profilePictureUrl: '',
  userStatus: ActionStatus.Idle,
  displayName: '',
  tenantId: ''
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    retrieveUserRequested: (state) => {
      state.userStatus = ActionStatus.Pending;
    },
    retrieveUserSucceeded: (state, action: PayloadAction<RetrieveUserResponse>) => {
      state.id = action.payload.id;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.profilePictureUrl = action.payload.profilePictureUrl;
      state.userStatus = ActionStatus.Idle;
      state.displayName = action.payload.displayName;
      state.tenantId = action.payload.tenantId;
    },
    retrieveUserFailed: (state) => {
      state.userStatus = ActionStatus.Failed;
    },
    updateCurrentUserProfilePicture: (state, action: PayloadAction<string>) => {
      state.profilePictureUrl = action.payload;
    },
    updateCurrentUserDisplayName: (state, action: PayloadAction<string>) => {
      state.displayName = action.payload;
    }
  }
});

export const selectCurrentUserDisplayName = (state: RootState) => state.user.displayName;
export const selectCurrentUserFirstName = (state: RootState) => state.user.firstName;
export const selectCurrentUserLastName = (state: RootState) => state.user.lastName;
export const selectCurrentUserProfilePictureUrl = (state: RootState) => state.user.profilePictureUrl;
export const selectCurrentEmployeeId = (state: RootState) => state.user.id;
export const selectCurrentUserFullName = createSelector(
  [selectCurrentUserDisplayName, selectCurrentUserFirstName, selectCurrentUserLastName],
  (displayName, firstName, lastName) => (displayName === '' ? firstName + ' ' + lastName : displayName)
);
initials;
export const selectCurrentUserInitials = createSelector([selectCurrentUserFullName], (name) => initials(name));

export const selectIsCurrentUserLoaded = (state: RootState) => state.user.id !== '';
export const selectCurrentUserTenantId = (state: RootState) => state.user.tenantId;

export const { retrieveUserRequested, retrieveUserSucceeded, retrieveUserFailed, updateCurrentUserProfilePicture, updateCurrentUserDisplayName } =
  userSlice.actions;
export default userSlice.reducer;
