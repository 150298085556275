import {
  styled,
  alpha,
  TextField,
  TextFieldProps,
  IconButton,
  IconButtonProps,
  Stack,
  StackProps,
  Switch,
  SwitchProps,
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  Button,
  ButtonProps,
  AppBar,
  AppBarProps,
  Paper,
  PaperProps,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Select,
  SelectProps,
  AccordionProps,
  AccordionSummaryProps,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  InputBase,
  Snackbar,
  InputLabel,
  InputLabelProps,
  AlertProps,
  Alert,
  alertClasses,
  svgIconClasses,
  ModalProps,
  Modal,
  inputBaseClasses,
  filledInputClasses,
  formControlClasses,
  inputLabelClasses,
  ListItemButtonProps,
  ListItemButton,
  listItemButtonClasses,
  typographyClasses,
  Card,
  CardProps,
  CardContent,
  CardContentProps,
  CardActions,
  CardActionsProps,
  autocompleteClasses,
  CheckboxProps,
  Checkbox,
  checkboxClasses,
  TableRow,
  TableRowProps,
  tableRowClasses,
  tableCellClasses,
  TableHead,
  TableHeadProps,
  inputAdornmentClasses,
  ListSubheaderProps,
  ListSubheader,
  SpeedDial,
  SpeedDialProps,
  speedDialClasses,
  Chip,
  ChipProps,
  buttonClasses,
  outlinedInputClasses,
  chipClasses,
  textFieldClasses,
  Theme,
  FormControlLabel,
  FormControlLabelProps,
  TableCell,
  ListItem,
  listItemClasses,
  Tab,
  Tabs,
  TabsProps,
  tabsClasses,
  tabClasses,
  TabProps,
  MenuItem,
  MenuItemProps,
  FilledInput,
  TableCellProps
} from '@mui/material';
import { forwardRef, ReactNode } from 'react';
import { CheckedDarkIcon, ChevronDownIcon } from '@/shared';
import { SnackbarProps } from '@mui/base';
import { ErrorOutline, Height, MoreHoriz } from '@mui/icons-material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import popperClasses from '@mui/material/Popper/popperClasses';

interface StyledTooltipProps extends TooltipProps {
  customWidth?: number;
  parentColor?: boolean;
}

interface OnboardingAccordionSummaryProps extends AccordionSummaryProps {
  isStepStarted?: boolean;
  isStepCompleted?: boolean;
}

interface StyledEditButtonProps extends ButtonProps {
  isStepCompleted?: boolean;
}

export interface StyledNavAppBarProps extends AppBarProps {
  open?: boolean;
  width?: number;
}

interface StyledFormField extends StackProps {
  isEditable?: boolean;
}

interface StyledImageGradientProps extends BoxProps {
  isEditable?: boolean;
  width: number;
  height: number;
}

interface AdminSettingsPermissionGroupCardProps extends CardProps {
  locked?: boolean;
}

interface NotificationSnackbarProps extends SnackbarProps {
  isRead: boolean;
}

export const StyledIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  color: theme.palette.text.primary,
  borderRadius: 0,
  fontFamily: 'inherit',
  fontSize: '0.875rem',
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem'
}));

export const StyledPaperShadow = styled(Paper)<PaperProps>(({ theme }) => ({
  boxShadow: '0 2px 1px #0000000d, 0 0 1px #00000040',
  borderRadius: 0
}));

export const RowCenterStack = styled(Stack)<StackProps>(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start'
}));

export const ColumnStartAlignStack = styled(Stack)<StackProps>(() => ({
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between'
}));

export const MaterialUISwitch = styled(Switch)<SwitchProps>(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: theme.palette.common.white,
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          theme.palette.common.white
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff'
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`
    }
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2
  }
}));

export const StyledBodyCopy = styled(Typography)<TypographyProps>(() => ({
  textTransform: 'initial',
  fontWeight: 'bold'
}));

export const StyledPostButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: 'primary.main',
  flexGrow: 1,
  padding: '0',
  minHeight: '2.3rem',
  gap: '0.5rem',
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.secondary.main,
    '& path': {
      fill: theme.palette.secondary.main
    }
  }
}));

export const StyledNavAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'width'
})<StyledNavAppBarProps>(({ theme, open, width }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${width}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: width
  })
}));

export const StyledMain = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'width'
})<{
  open?: boolean;
  width?: number;
}>(({}) => ({
  padding: 0,
  width: '100%',
  height: '100%',
  zIndex: 2,
  '& .MuiPaper-root': {
    boxShadow: 'none',
    backgroundColor: 'transparent'
  }
}));

export const StyledTooltip = styled(
  forwardRef(({ className, customWidth, ...props }: StyledTooltipProps, ref) => <Tooltip ref={ref} {...props} classes={{ popper: className }} />)
)(({ theme, customWidth }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: customWidth || 300,
    padding: 0,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    borderRadius: '0.5rem'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.customTheme.contBgr,
    fontSize: '1.8rem',
    boxShadow: 'inherit',
    '&:before': {
      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
    }
  }
}));

export const StyledAlert = styled(({ ...props }: AlertProps) => (
  <Alert
    {...props}
    iconMapping={{
      warning: <ErrorOutline />,
      info: <MoreHoriz />,
      success: <CheckedDarkIcon />
    }}
  />
))(({ theme }) => ({
  [`&.${alertClasses.root}`]: {
    minHeight: 32,
    padding: '0 6px',
    alignItems: 'center'
  },
  [`&.${alertClasses.colorWarning}`]: {
    backgroundColor: '#BF5AC2',
    color: theme.palette.common.white
  },
  [`&.${alertClasses.colorSuccess}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.customTheme.contColor
  },
  [`&.${alertClasses.colorInfo}`]: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.customTheme.contColor
  },
  [`& .${alertClasses.message}`]: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  [`& .${alertClasses.icon}`]: {
    color: theme.palette.common.black,
    [`& .${svgIconClasses.root}`]: {
      fontSize: 'unset',
      width: 32,
      height: 32
    }
  }
}));

export const StyledModalContent = styled(Stack)<StackProps>(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '900px',
  backgroundColor: theme.palette.customTheme.contBgr,
  boxShadow: '1.5rem',
  padding: '1rem',
  [theme.breakpoints.up('sm')]: {
    // styles for screens wider than 'sm'
    minWidth: '780px'
  },
  [theme.breakpoints.down('md')]: {
    // styles for screens smaller than 'md'
    minWidth: 'calc(100% - 12px)'
  }
}));

export const VisuallyHidden = styled(Box)<BoxProps>({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

export const BootstrapSelect = styled(Select)<SelectProps>(({ theme }) => ({
  height: 50,
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    fontSize: 14,
    width: '100%',
    padding: '10px 12px',
    textAlign: 'left',
    webkitAppearance: 'none',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow'])
  },
  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: theme.palette.grey[800]
  },
  'div[role="combobox"]': {
    padding: '15px 12px 10px',
    backgroundColor: theme.palette.grey[800],
    textFillColor: theme.palette.common.black,
    border: '1px solid',
    borderColor: 'transparent',
    transition: theme.transitions.create(['border-color']),
    fontSize: 16
  },
  'div[role="combobox"].Mui-disabled': {
    backgroundColor: 'transparent',
    borderColor: 'transparent'
  },
  'div[aria-expanded="true"]': {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.secondary.main,
    '& ~ .MuiSvg-icon': {
      transform: 'rotate(180deg)'
    }
  },
  '& .MuiInputBase-root.Mui-focused': {
    '& input': {
      backgroundColor: theme.palette.common.white
    }
  },
  '& .MuiFormHelperText-root': {
    textAlign: 'right'
  },
  '& .MuiInputBase-inputMultiline': {
    padding: 0
  },
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.common.white
  },
  '& .MuiSvg-icon': {
    userSelect: 'none',
    width: 12,
    height: 7,
    display: 'inline-block',
    flexShrink: 0,
    fontSize: '1.5rem',
    position: 'absolute;',
    right: '15px',
    top: '42%',
    pointerEvents: 'none',
    color: 'rgba(0, 0, 0, 0.54)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  '& fieldset': {
    border: 0
  }
}));

export const StyledCheckBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: '50px',
  height: '50px',
  position: 'relative',
  '& .checkmark': {
    strokeDasharray: 130,
    strokeDashoffset: 130,
    strokeWidth: 5,
    stroke: theme.palette.common.white,
    fill: 'none',
    strokeLinecap: 'round',
    animation: 'stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards'
  },
  '& .checkmark-circle': {
    strokeDasharray: 166,
    strokeDashoffset: 166,
    strokeWidth: 5,
    stroke: theme.palette.success.main,
    fill: theme.palette.success.main,
    strokeLinecap: 'round'
  },
  '@keyframes stroke': {
    '100%': {
      strokeDashoffset: 0
    }
  }
}));

export const MasterInput = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '&.MuiTextField-root': {
    position: 'static',
    width: '100%',

    '& label': {
      backgroundColor: theme.palette.common.white,
      color: 'inherit',
      top: '-4px'
    },
    '& label.Mui-disabled': {
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.grey[200]
    }
  },
  '& .MuiOutlinedInput-root': {
    height: 50,
    '& fieldset': {
      border: 0,
      padding: 0,
      height: '100%'
    },
    '& input': {
      padding: 0
    }
  }
}));

export const AutoCompleteInput = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '&.MuiTextField-root': {
    width: '100%',
    backgroundColor: theme.palette.common.white,

    '& label': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      top: '-4px'
    },
    '& label.Mui-disabled': {
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.grey[200]
    }
  },
  '& .MuiOutlinedInput-root': {
    height: 50,
    '& fieldset': {
      borderColor: theme.palette.customTheme.contColor,
      padding: '16px 11px',
      height: '100%'
    },
    '& input': {
      padding: '16px 11px'
    },
    '&:focus fieldset': {
      borderColor: theme.palette.secondary.main
    }
  },
  '& .Mui-disabled': {
    backgroundColor: theme.palette.grey[50],
    borderColor: 'transparent',
    '& svg, path': {
      color: theme.palette.grey[200],
      fill: theme.palette.grey[200]
    }
  }
}));

export const OnboardingAccordion = styled((props: AccordionProps) => <Accordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  borderRadius: '10px',
  '&::before': {
    display: 'none'
  }
}));

export const OnboardingAccordionSummary = styled(({ isStepStarted, isStepCompleted, ...props }: OnboardingAccordionSummaryProps) => (
  <AccordionSummary expandIcon={!isStepCompleted && <ChevronDownIcon />} {...props} />
))(({ theme, isStepStarted, isStepCompleted }) => ({
  height: 56,
  padding: theme.spacing(2),
  borderRadius: '10px',
  borderLeft: `8px solid ${!isStepStarted ? theme.palette.grey[500] : isStepCompleted ? theme.palette.secondary.main : theme.palette.primary.dark}`,
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  '&.Mui-expanded': {
    marginTop: theme.spacing(2)
  },
  '&.Mui-disabled': {
    borderColor: 'transparent'
  },
  '& .MuiAccordionSummary-content': {
    margin: 0
  },
  '&:hover .MuiButtonBase-root': {
    display: 'inline-flex'
  }
}));

export const OnboardingAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    padding: '32px 0 20px 40px'
  },
  [theme.breakpoints.down('lg')]: {
    padding: '24px'
  }
}));

export const StyledEditButton = styled(({ isStepCompleted, ...props }: StyledEditButtonProps) => <Button {...props} />)(({ theme, isStepCompleted }) => ({
  backgroundColor: isStepCompleted ? theme.palette.secondary.main : theme.palette.primary.dark,
  padding: '12px 24px 12px 20px',
  borderRadius: '10px',
  height: 43
}));

export const AppBarSearch = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  marginLeft: 0,
  width: '100%',
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  }
}));

export const AppBarSearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.black,
  '& svg': {
    zIndex: 1,
    opacity: 0.3
  }
}));

export const AppBarStyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.common.black,
  backgroundColor: theme.palette.secondary.light,
  borderRadius: theme.spacing(2),
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '25ch',
      '&:focus': {
        width: '35ch'
      }
    }
  }
}));

export const UpdateButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.customTheme.focusItem,
  color: theme.palette.common.white,
  borderRadius: '10px'
}));

export const CancelButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.grey[600],
  borderColor: theme.palette.grey[900],
  color: theme.palette.text.secondary,
  borderRadius: '10px'
}));

export const StyledHomeLinkButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.customTheme.focusItem,
  color: theme.palette.common.white,
  borderRadius: '10px',
  textTransform: 'uppercase',
  padding: '12px 32px'
})) as typeof Button;

export const EmployeeModalTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiInputBase-root': {
    '& .MuiAutocomplete-endAdornment': {
      right: '17px'
    }
  },
  '& .MuiInputBase-root.Mui-focused': {
    '& fieldset': {
      borderWidth: '1px',
      borderColor: theme.palette.secondary.main
    }
  }
}));

export const AddNewRow = styled(Button)<ButtonProps>(({ theme }) => ({
  borderColor: theme.palette.customTheme.contColor,
  borderRadius: '10px',
  color: theme.palette.customTheme.contColor,
  fontWeight: 700,
  '& path': {
    fill: theme.palette.customTheme.contColor
  },
  '&.Mui-disabled': {
    opacity: '0.25',
    backgroundColor: '#1F2020',
    borderColor: 'transparent',
    color: theme.palette.common.white,
    '& path': {
      fill: theme.palette.common.white
    }
  },
  '&:hover': {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    '& path': {
      fill: theme.palette.common.white
    }
  }
}));

export const NotificationSnackbar = styled(Snackbar, {
  shouldForwardProp: (prop) => prop !== 'isRead'
})<NotificationSnackbarProps>(({ theme, isRead }) => ({
  backgroundColor: isRead ? '#9747FF1A' : theme.palette.common.white,
  color: theme.palette.common.black,
  borderRadius: '8px',
  padding: '8px 8px 8px 20px',
  position: 'static',
  minHeight: 60,
  boxShadow: theme.shadows[1],
  [theme.breakpoints.up('sm')]: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
}));

export const BootstrapInput = styled(TextField)<TextFieldProps>(({ theme, disabled }) => ({
  height: 50,
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: disabled ? theme.palette.grey[800] : theme.palette.common.white,
    fontSize: 14,
    width: '100%',
    padding: '16px 12px 8px',
    textAlign: 'left',
    webkitAppearance: 'none',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&::placeholder': {
      opacity: 1,
      textFillColor: theme.palette.common.black
    },
    '& .MuiSelect-select': {
      height: 50,
      padding: '10px 32px 10px 12px'
    },
    '&::selection': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.4)
    }
  },
  '& .MuiInputBase-input.Mui-disabled': {
    backgroundColor: theme.palette.grey[800],
    border: 0,
    textFillColor: theme.palette.common.black
  },
  '& .MuiInputBase-root.Mui-focused': {
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.common.white,
    '& input': {
      backgroundColor: theme.palette.common.white
    },
    '& fieldset': {
      display: 'none',
      border: '1px solid',
      borderColor: theme.palette.secondary.main
    }
  },
  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: theme.palette.grey[800]
  },
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.common.white
  },
  '&.MuiDate-root': {
    '& .MuiInputLabel-root': {
      top: '-10px',
      fontSize: '12px'
    }
  },
  '& .MuiButtonBase-root.Mui-disabled': {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  '& .MuiInputLabel-root': {
    textTransform: 'capitalize',
    left: '-4px',
    fontSize: '12px',
    color: theme.palette.common.black
  },
  '& .MuiFormHelperText-root': {
    textAlign: 'right'
  },
  '& .MuiInputBase-inputMultiline': {
    padding: 0
  },
  '& .MuiInputBase-root fieldset': {
    border: disabled ? 0 : '1px solid',
    borderColor: theme.palette.customTheme.contColor
  }
}));

export const StyledDatePickerFilledInput = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiInputBase-input': {
    '&::placeholder': {
      opacity: 1,
      color: theme.palette.customTheme.contColor,
      textFillColor: theme.palette.customTheme.contColor
    },
    '&::selection': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.4)
    }
  },
  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: theme.palette.customTheme.primaryA
  },
  '& .MuiInputBase-root': {
    paddingLeft: '8px'
  },
  '& .MuiFilledInput-root': {
    backgroundColor: theme.palette.customTheme.primaryA,
    height: '50px',
    fontSize: '14px',
    overflow: 'hidden',
    borderRadius: '4px',
    border: '1px solid',
    paddingTop: '12px',
    borderColor: 'transparent',
    boxShadow: 'none',
    transition: theme.transitions.create(['border-color', 'background-color']),
    '&.Mui-focused': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.secondary.main
    },
    '&.Mui-error': {
      borderColor: theme.palette.customTheme.focusItem
    },
    '& .MuiInputBase-input': {
      padding: '0 3px',
      backgroundColor: 'transparent',
      textFillColor: theme.palette.customTheme.contColor,
      fontSize: 16,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }
  },
  '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl': {
    fontSize: '12px'
  },
  '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.Mui-error': {
    color: theme.palette.customTheme.focusItem
  },
  '& .MuiFilledInput-root .MuiAutocomplete-endAdornment': {
    right: 20
  },
  '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
    color: theme.palette.customTheme.focusItem
  }
}));

export const StyledFilledInput = styled(
  forwardRef<HTMLDivElement, TextFieldProps>(({ placeholder = '-', ...props }, ref) => (
    <TextField
      ref={ref}
      {...props}
      variant="filled"
      fullWidth
      InputProps={{
        ...props.InputProps,
        disableUnderline: true,
        placeholder: placeholder
      }}
      InputLabelProps={{
        ...props.InputLabelProps,
        shrink: true
      }}
    />
  ))
)(({ theme, disabled }) => ({
  '& .MuiInputBase-input': {
    '&::selection': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.4)
    }
  },
  '& .MuiSvgIcon-root': {
    width: 16,
    height: 16,
    marginLeft: '2px'
  },
  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: theme.palette.customTheme.primaryA,
    opacity: 0.8,
    cursor: 'not-allowed'
  },
  '& .MuiInputBase-root': {
    paddingLeft: '8px'
  },
  '& .MuiFilledInput-root': {
    backgroundColor: theme.palette.customTheme.primaryA,
    height: 'auto',
    minHeight: '50px',
    fontSize: '14px',
    overflow: 'hidden',
    borderRadius: '4px',
    border: '1px solid',
    paddingTop: '12px',
    borderColor: 'transparent',
    boxShadow: 'none',
    transition: theme.transitions.create(['border-color', 'background-color']),
    '&.Mui-focused': {
      borderColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.common.white
    },
    '&.Mui-error': {
      borderColor: theme.palette.customTheme.focusItem
    },
    '& .MuiInputBase-input': {
      padding: '4px',
      backgroundColor: 'transparent',
      textFillColor: theme.palette.customTheme.contColor,
      fontSize: 16,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }
  },
  '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl': {
    fontSize: '12px'
  },
  '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.Mui-error': {
    color: theme.palette.customTheme.focusItem
  },
  '& .MuiFilledInput-root .MuiAutocomplete-endAdornment': {
    right: 20
  },
  '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
    color: theme.palette.customTheme.focusItem
  }
}));

export const StyledTransparentInput = styled(StyledFilledInput, {
  shouldForwardProp: (prop) => prop !== 'width'
})<{
  width?: number;
}>(({ theme, width }) => ({
  [`& .${filledInputClasses.root}`]: {
    backgroundColor: 'inherit',
    borderColor: theme.palette.customTheme.contColor,
    '&:hover': {
      backgroundColor: 'inherit'
    }
  },
  [`&.${formControlClasses.root}`]: {
    width: width
  }
}));

export const StyledInsertLinkInput = styled(StyledFilledInput, {
  shouldForwardProp: (prop) => prop !== 'width'
})<{
  width?: number;
}>(({ theme, width }) => ({
  [`& .${filledInputClasses.root}`]: {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.customTheme.contColor,
    padding: '7px 11px',
    '&:hover': {
      backgroundColor: theme.palette.common.white
    }
  },
  [`&.${formControlClasses.root}`]: {
    width: width
  }
}));

export const StyledTransparentInputDatePicker = styled(StyledDatePickerFilledInput, {
  shouldForwardProp: (prop) => prop !== 'width'
})<{
  width?: number;
}>(({ theme, width }) => ({
  [`& .${filledInputClasses.root}`]: {
    backgroundColor: 'inherit',
    borderColor: theme.palette.customTheme.contColor,
    '&:hover': {
      backgroundColor: 'inherit'
    }
  },
  [`&.${formControlClasses.root}`]: {
    width: width
  }
}));

export const StyledWorkDayInput = styled(StyledFilledInput, {
  shouldForwardProp: (prop) => prop !== 'width'
})<{
  width: number;
}>(({ theme, width }) => ({
  [`& .${filledInputClasses.root}`]: {
    backgroundColor: 'inherit',
    borderRadius: 0,
    border: 0,
    borderRight: `1px solid ${theme.palette.divider}`,
    textAlign: 'center',
    fontWeight: 700,
    padding: '0 12px',
    '&:hover': {
      backgroundColor: 'inherit',
      borderColor: theme.palette.divider
    },
    [`&.${filledInputClasses.focused}`]: {
      borderColor: theme.palette.divider
    }
  },
  [`& .${inputLabelClasses.root}`]: {
    textTransform: 'uppercase',
    fontWeight: 400,
    position: 'absolute',
    top: '-16px',
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: '12px'
  },
  [`&.${formControlClasses.root}`]: {
    width: width,
    '&:first-of-type': {
      [`& .${filledInputClasses.root}`]: {
        borderLeft: `1px solid ${theme.palette.divider}`
      }
    }
  }
}));

const StyledFormWrapperComponent = forwardRef<HTMLDivElement, StyledFormField>(({ isEditable, ...otherProps }, ref) => <Stack ref={ref} {...otherProps} />);

export const StyledFormWrapper = styled(StyledFormWrapperComponent)`
  ${({ theme, isEditable }) => ({
    backgroundColor: theme.palette.customTheme.contBgr,
    padding: 0,
    border: '1px solid',
    borderColor: theme.palette.customTheme.borderMaster,
    borderRadius: 10,
    margin: '16px 0',
    '& .Mui-ProfileFiledHeader': {
      transition: theme.transitions.create(['background-color, border'])
    },
    '&:hover, &:focus-within': {
      ...(!isEditable
        ? {
            '& .Mui-ProfileFiledHeader': {
              borderLeft: '8px solid',
              borderRadius: 10,
              borderColor: theme.palette.primary.dark,
              backgroundColor: isEditable ? theme.palette.customTheme.drawerBackground : theme.palette.grey[700]
            }
          }
        : {}),
      '& button': {
        opacity: 1
      }
    }
  })}
`;

export const StyledFormWrapperSettingsPage = styled(StyledFormWrapperComponent)`
  ${({ theme, isEditable }) => ({
    backgroundColor: theme.palette.customTheme.contBgr,
    padding: 0,
    borderRadius: 10,
    '& .Mui-ProfileFiledHeader': {
      transition: theme.transitions.create(['background-color, border'])
    },
    '&:hover, &:focus-within': {
      ...(!isEditable
        ? {
            '& .Mui-ProfileFiledHeader': {
              borderLeft: '8px solid',
              borderRadius: 10,
              borderColor: theme.palette.primary.dark,
              backgroundColor: isEditable ? theme.palette.customTheme.drawerBackground : theme.palette.grey[700]
            }
          }
        : {}),
      '& button': {
        opacity: 1
      }
    }
  })}
`;

export const StyledFormHeader = styled(({ isEditable, ...otherProps }: StyledFormField) => <Stack {...otherProps} />)`
  ${({ theme, isEditable }) => ({
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: isEditable ? theme.palette.customTheme.drawerBackground : theme.palette.customTheme.solidBgr,
    padding: theme.spacing(2),
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
    transition: theme.transitions.create('backgroundColor', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })}
`;

export const StyledFormLabel = styled(InputLabel)<InputLabelProps>(({ shrink = true }) => ({
  fontSize: '12px',
  top: '14px',
  left: 0,
  zIndex: 10
}));

export const StyledAudienceLabel = styled(FormControlLabel)<FormControlLabelProps>(({ theme }) => ({
  [`&.Mui-disabled`]: {
    [`& .${typographyClasses.root}`]: {
      color: theme.palette.common.black
    }
  }
}));

export const StyledFormSubmitButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.common.white,
  padding: '12px 24px 12px 20px',
  height: 43,
  borderRadius: '8px'
}));

export const StyledImportPeopleButton = styled(LoadingButton)<LoadingButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.common.white,
  padding: '12px 24px',
  borderRadius: 10
}));

export const StyledCancelButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  padding: '12px 24px 12px 20px',
  height: 43,
  borderRadius: '8px'
}));

export const StyledAutocomplete = styled(Autocomplete)`
  position: relative;

  & .MuiInputBase-root.MuiOutlinedInput-root {
    padding: 8px 10px;
  }

  & .MuiChip-root {
    margin-top: 4px;
    margin-left: -2px;
    height: 24px;
    background-color: ${({ theme }) => theme.palette.primary.dark};
    color: ${({ theme }) => theme.palette.common.white};

    & svg {
      color: ${({ theme }) => theme.palette.common.white};
    }
  }

  & .MuiButtonBase-root.Mui-disabled {
    display: none;
  }

  & .muiautocomplete-popupindicator: {
    position: 'absolute';
    right: 20;
  }

  ,
  & .MuiAutocomplete-tag:last-of-type {
    background-color: #0000008a;
    color: ${({ theme }) => theme.palette.common.white};
    border-radius: 16px;
    min-width: 30px;
    text-align: center;
    margin-top: 4px;
    margin-left: -2px;
    height: 24px;
    opacity: 0.38;
  }
` as typeof Autocomplete;

export const StyledImageGradient = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isEditable'
})<StyledImageGradientProps>(({ isEditable, width, height }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  borderRadius: '7px',
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: `linear-gradient(0deg, rgba(37, 29, 56, ${isEditable ? 0.5 : 0}) 0%, rgba(37, 29, 56, ${isEditable ? 0.5 : 0}) 100%)`,
  width,
  height
}));

export const StyledBlurryModal = styled(Modal)<ModalProps>(({}) => ({
  background: 'rgba(217, 217, 217, 0.60)',
  backdropFilter: 'blur(10px)'
}));

export const StyledSearch = styled(TextField)<TextFieldProps>(({ theme }) => ({
  [`& .${inputBaseClasses.root}`]: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    borderRadius: '4px',
    padding: '0 11px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    '& fieldset': {
      borderWidth: 1,
      borderColor: theme.palette.customTheme.contColor,
      transition: theme.transitions.create('border-color', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      '& svg': {
        fill: theme.palette.customTheme.contColor
      }
    },
    [`&.${inputBaseClasses.focused}`]: {
      width: '100%',
      border: 0,
      '& fieldset': {
        borderWidth: 1,
        borderColor: theme.palette.secondary.main
      },
      '& svg': {
        fill: theme.palette.secondary.main
      }
    }
  },
  [`& .${inputBaseClasses.input}`]: {
    height: 40,
    padding: 0
  }
}));

export const StyledFixedWidthTypography = styled(Typography)<TypographyProps>(() => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: 250
}));

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  [`&.${listItemButtonClasses.root}`]: {
    borderRadius: '7px',
    margin: '3px 0',
    padding: '8px 16px 8px 24px',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.customTheme.headerColorSub,
    transition: theme.transitions.create('border-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard
    }),
    '&::before': {
      content: 'none'
    }
  },
  '&:hover': {
    borderLeft: `8px solid ${theme.palette.primary.dark}`
  },
  [`&.${listItemButtonClasses.selected}`]: {
    backgroundColor: theme.palette.secondary.dark,
    [`& .${typographyClasses.root}`]: {
      fontWeight: 700
    }
  },
  [`&.${listItemButtonClasses.focusVisible}`]: {
    backgroundColor: theme.palette.secondary.dark
  }
})) as typeof ListItemButton;

export const StyledListItemButtonNested = styled(StyledListItemButton)<ListItemButtonProps>(({ theme }) => ({
  [`&.${listItemButtonClasses.root}`]: {
    padding: '2px 16px',
    position: 'relative'
  }
}));

export const StyledListItemButtonNestedWithIndicator = styled(StyledListItemButton)<ListItemButtonProps>(({ theme }) => ({
  [`&.${listItemButtonClasses.root}`]: {
    padding: '2px 16px 2px 32px',
    position: 'relative',
    '&::before': {
      content: '""',
      width: 5,
      height: 5,
      borderRadius: '50%',
      backgroundColor: theme.palette.customTheme.contColor,
      position: 'absolute',
      left: 15,
      top: '50%',
      transform: 'translateY(-50%)'
    }
  }
}));

export const StyledSettingsContainer = styled(Stack)<StackProps>(({ theme }) => ({
  backgroundColor: theme.palette.customTheme.contBgr,
  borderRadius: theme.spacing(2),
  border: '1px solid',
  borderColor: theme.palette.customTheme.borderMaster,
  minHeight: '100%'
}));

export const StyledAdminSettingsPermissionGroupCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'locked'
})<AdminSettingsPermissionGroupCardProps>(({ theme, locked }) => ({
  position: 'relative',
  width: '100%',
  minHeight: 226,
  backgroundColor: locked ? 'transparent' : theme.palette.grey[800],
  borderRadius: theme.spacing(2),
  border: locked ? `1px solid ${theme.palette.common.black}` : 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transition: theme.transitions.create('background-color', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  '&:hover': {
    backgroundColor: theme.palette.customTheme.drawerBackground
  }
}));

export const StyledAdminSettingsPermissionGroupCardContent = styled(CardContent)<CardContentProps>(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 10,
  textAlign: 'center'
}));

export const StyledAdminSettingsPermissionGroupCardActions = styled(CardActions)<CardActionsProps>(({ theme }) => ({
  padding: `0 ${theme.spacing(2)} ${theme.spacing(2)}`
}));

export const StyledAdminSettingsPermissionListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'to'
})<{
  to?: string;
}>(({ theme }) => ({
  padding: '12px 24px',
  width: '100%',
  backgroundColor: theme.palette.common.white,
  borderRadius: '7px',
  transition: theme.transitions.create('border-left', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  '&:hover': {
    borderLeft: `7px solid ${theme.palette.customTheme.focusItem}`
  }
}));

export const StyledCountOpenDrawerButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.customTheme.focusItem,
  padding: 0,
  margin: 0,
  backgroundColor: 'transparent',
  textDecoration: 'underline',
  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline'
  }
}));

export const StyledAdminSettingsPermissionMembersInput = styled(StyledFilledInput, {
  shouldForwardProp: (prop) => prop !== 'width'
})<{
  width?: number;
}>(({ width = '100%' }) => ({
  [`& .${filledInputClasses.root}`]: {
    border: 0,
    backgroundColor: 'inherit',
    borderColor: 'transparent',
    paddingTop: 0,
    [`& .${inputAdornmentClasses.root}`]: {
      [`& .${svgIconClasses.root}`]: {
        width: 24,
        height: 24
      }
    },
    '&:hover': {
      backgroundColor: 'inherit'
    }
  },
  [`& .${filledInputClasses.input}`]: {
    '&::placeholder': {
      textFillColor: 'rgba(37, 29, 56, 0.45)',
      fontSize: 12
    }
  },
  [`& .${inputBaseClasses.focused}.${filledInputClasses.root}`]: {
    backgroundColor: 'inherit',
    borderColor: 'transparent'
  },
  [`&.${formControlClasses.root}`]: {
    width: width
  }
}));

export const StyledAdminSettingsAudienceMembersInput = styled(StyledFilledInput, {
  shouldForwardProp: (prop) => prop !== 'width'
})<{
  width?: number;
}>(({ width = '100%' }) => ({
  [`& .${filledInputClasses.root}`]: {
    border: 0,
    backgroundColor: 'inherit',
    borderColor: 'transparent',
    paddingTop: 0,
    '&:hover': {
      backgroundColor: 'inherit'
    }
  },
  [`& .${filledInputClasses.input}`]: {
    '&::placeholder': {
      textFillColor: 'rgba(37, 29, 56, 0.45)',
      fontSize: 12
    }
  },
  [`& .${inputBaseClasses.focused}.${filledInputClasses.root}`]: {
    backgroundColor: 'inherit',
    borderColor: 'transparent'
  },
  [`&.${formControlClasses.root}`]: {
    width: width
  }
}));

export const StyledMentionEmployeesInput = styled(StyledFilledInput, {
  shouldForwardProp: (prop) => prop !== 'width'
})<{
  width?: number;
}>(({ width = '100%' }) => ({
  [`& .${filledInputClasses.root}`]: {
    border: 0,
    backgroundColor: 'inherit',
    borderColor: 'transparent',
    paddingTop: 0,
    paddingRight: 0,
    '&:hover': {
      backgroundColor: 'inherit'
    }
  },
  [`& .${filledInputClasses.input}`]: {
    '&::placeholder': {
      textFillColor: 'rgba(37, 29, 56, 0.45)',
      fontSize: 12
    }
  },
  [`& .${inputBaseClasses.focused}.${filledInputClasses.root}`]: {
    backgroundColor: 'inherit',
    borderColor: 'transparent'
  },
  [`&.${formControlClasses.root}`]: {
    width: width
  },
  [`&.${inputBaseClasses.root}`]: {
    paddingRigh: 0
  }
}));

export const StyledAdminSettingsPermissionGroupAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`&.${autocompleteClasses.root}`]: {
    height: 50,
    paddingRight: theme.spacing(0.5),
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  }
})) as typeof Autocomplete;

export const StyledMentionEmployeesAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`&.${autocompleteClasses.root}`]: {
    height: 50,
    paddingRight: theme.spacing(0.5),
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  [`&.${autocompleteClasses.hasPopupIcon}`]: {
    [`& .${filledInputClasses.root}`]: {
      paddingRight: 0,
      paddingTop: 0
    }
  }
})) as typeof Autocomplete;

export const StyledAdminSettingsPermissionGroupWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'expanded'
})<StackProps & { expanded?: boolean; disabled?: boolean }>(({ theme, expanded, disabled }) => ({
  border: disabled ? 0 : `1px solid ${theme.palette.common.black}`,
  borderRadius: 4,
  transition: theme.transitions.create('max-height', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  maxHeight: expanded ? 550 : 60,
  overflowY: 'scroll'
}));

export const StyledAddNewGroupButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  borderRadius: '10px',
  color: theme.palette.common.white,
  fontWeight: 700,
  padding: '12px 24px',
  '& path': {
    fill: theme.palette.common.white
  },
  '&.Mui-disabled': {
    '& path': {
      fill: 'rgba(0, 0, 0, 0.12)'
    }
  }
}));

export const StyledPermissionGroupCheckbox = styled(Checkbox)<CheckboxProps>(({ theme }) => ({
  [`&.${checkboxClasses.root}`]: {
    color: theme.palette.common.black
  },
  [`&.${checkboxClasses.checked}`]: {
    color: theme.palette.customTheme.focusItem
  },
  [`&.${checkboxClasses.checked}.${checkboxClasses.disabled}`]: {
    color: theme.palette.grey[900],
    pointerEvents: 'none'
  }
}));

export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'editable'
})<TableRowProps & { editable?: boolean }>(({ theme, editable }) => ({
  [`&.${tableRowClasses.root}`]: {
    backgroundColor: theme.palette.common.white,
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.customTheme.zebra
    }
  },
  [`&.${tableRowClasses.hover}:hover`]: {
    backgroundColor: theme.palette.customTheme.drawerBackground
  },
  [`&.${tableRowClasses.root}.${tableRowClasses.selected}.${tableRowClasses.hover}`]: {
    backgroundColor: theme.palette.customTheme.drawerBackground
  },

  [`&.${tableRowClasses.selected}`]: {
    [`& .${checkboxClasses.root}`]: {
      borderLeft: `7px solid ${theme.palette.customTheme.focusItem}`,
      borderRadius: 4
    }
  },
  [`& .${tableCellClasses.root}`]: {
    border: 0,
    padding: `${editable ? 0 : theme.spacing(0.5)} ${theme.spacing(2)}`
  },
  [`& .${tableCellClasses.paddingCheckbox}`]: {
    border: 0,
    padding: 0
  },
  [`& .${checkboxClasses.root}`]: {
    borderLeft: '7px solid transparent',
    height: 50,
    backgroundColor: 'transparent'
  }
}));

export const StyledTableHead = styled(TableHead, {
  shouldForwardProp: (prop) => prop !== 'editable'
})<TableHeadProps & { editable?: boolean }>(({ theme, editable }) => ({
  borderRadius: 10,
  backgroundColor: theme.palette.common.white,
  [`& .${tableRowClasses.root}`]: {
    [`& .${tableCellClasses.root}`]: {
      border: 0
    },
    [`& .${tableCellClasses.paddingCheckbox}`]: {
      border: 0,
      padding: 0
    },
    [`& .${checkboxClasses.indeterminate}`]: {
      color: theme.palette.customTheme.focusItem
    }
  }
}));

export const StyledPermissionListItem = styled(Box)<BoxProps>(({ theme }) => ({
  padding: '24px 16px',
  gap: '30px',
  backgroundColor: 'transparent',
  borderRadius: theme.spacing(2)
}));

export const StyledAudiencePermissionItem = styled(Stack)<StackProps>(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(2),
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderRadius: theme.spacing(2)
}));

export const StyledAudienceListSubHeader = styled(ListSubheader)<ListSubheaderProps>(({ theme }) => ({
  position: 'relative',
  backgroundColor: 'transparent',
  padding: theme.spacing(1)
}));

export const StyledAudienceRoleSelect = styled(Select)<SelectProps>(({ theme, disabled }) => ({
  height: 50,
  opacity: disabled ? 0.5 : 1,
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    minWidth: {
      xs: '100%',
      md: 380
    },
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    fontSize: 14,
    width: '100%',
    padding: '10px 12px',
    textAlign: 'left',
    webkitAppearance: 'none',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow'])
  },
  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: theme.palette.grey[800]
  },
  'div[role="combobox"]': {
    padding: '15px 12px 10px',
    backgroundColor: 'transparent',
    textFillColor: theme.palette.common.black,
    border: '1px solid',
    borderColor: theme.palette.common.black,
    transition: theme.transitions.create(['border-color']),
    fontSize: 16
  },
  'div[role="combobox"].Mui-disabled': {
    backgroundColor: theme.palette.grey[800],
    borderColor: 'transparent'
  },
  'div[aria-expanded="true"]': {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.secondary.main,
    '& ~ .MuiSvg-icon': {
      transform: 'translateY(-45%) rotate(180deg)'
    }
  },
  '& .MuiInputBase-root.Mui-focused': {
    '& input': {
      backgroundColor: theme.palette.common.white
    }
  },
  '& .MuiFormHelperText-root': {
    textAlign: 'right'
  },
  '& .MuiInputBase-inputMultiline': {
    padding: 0
  },
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.common.white
  },
  '& .MuiSvg-icon': {
    userSelect: 'none',
    width: 12,
    height: 7,
    display: 'inline-block',
    flexShrink: 0,
    fontSize: '1.5rem',
    position: 'absolute;',
    right: '15px',
    top: '50%',
    transform: 'translateY(-50%) rotate(0deg)',
    pointerEvents: 'none',
    color: 'rgba(0, 0, 0, 0.54)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    '& svg': {
      display: 'block'
    }
  },
  '& fieldset': {
    border: 0
  }
}));

export const StyledAudienceRoleTableRowSelect = styled(Select)<SelectProps>(({ theme }) => ({
  width: '100%',
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    fontSize: 14,
    width: '100%',
    padding: '10px 12px',
    textAlign: 'left',
    webkitAppearance: 'none',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow'])
  },
  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: 'transparent'
  },
  'div[role="combobox"]': {
    padding: '15px 12px 10px',
    backgroundColor: 'transparent',
    textFillColor: theme.palette.common.black,
    border: '1px solid',
    borderColor: 'transparent',
    transition: theme.transitions.create(['border-color']),
    fontSize: 16
  },
  'div[role="combobox"].Mui-disabled': {
    backgroundColor: 'transparent',
    borderColor: 'transparent'
  },
  'div[aria-expanded="true"]': {
    '& ~ .MuiSvg-icon': {
      transform: 'translateY(-45%) rotate(180deg)'
    }
  },
  '& .MuiInputBase-root.Mui-focused': {
    '& input': {
      backgroundColor: theme.palette.common.white
    }
  },
  '& .MuiFormHelperText-root': {
    textAlign: 'right'
  },
  '& .MuiInputBase-inputMultiline': {
    padding: 0
  },
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.common.white
  },
  '& .MuiSvg-icon': {
    userSelect: 'none',
    width: 12,
    height: 7,
    display: 'inline-block',
    flexShrink: 0,
    fontSize: '1.5rem',
    position: 'absolute;',
    right: '15px',
    top: '50%',
    transform: 'translateY(-50%) rotate(0deg)',
    pointerEvents: 'none',
    color: 'rgba(0, 0, 0, 0.54)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  '& fieldset': {
    border: 0
  }
}));

export const StyledSpeedDial = styled(SpeedDial)<SpeedDialProps>(({ theme }) => ({
  position: 'absolute',
  [`& .${speedDialClasses.directionUp}, .${speedDialClasses.directionLeft}`]: {
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  [`& .${speedDialClasses.directionDown}, .${speedDialClasses.directionRight}`]: {
    top: theme.spacing(2),
    left: theme.spacing(2)
  }
}));

export const StyledInlinePostChip = styled(Chip)<ChipProps>(({ theme }) => ({
  backgroundColor: theme.palette.customTheme.focusItem,
  color: theme.palette.common.white
}));

export const StyledInlineCancelPostButton = styled(Button)<ButtonProps>(({ theme }) => ({
  border: `1px solid`,
  borderColor: theme.palette.common.black,
  padding: '12px 32px',
  backgroundColor: theme.palette.grey[800],
  fontSize: 16,
  fontWeight: 700,
  borderRadius: '10px',
  '&:hover': {
    backgroundColor: theme.palette.customTheme.contColor,
    color: theme.palette.common.white
  }
}));

export const StyledInlineSubmitPostButton = styled(Button)<ButtonProps>(({ theme }) => ({
  padding: '12px 32px',
  backgroundColor: theme.palette.customTheme.drawerBackground,
  fontSize: 16,
  fontWeight: 700,
  borderRadius: '10px',
  '&:hover': {
    backgroundColor: theme.palette.customTheme.contColor,
    color: theme.palette.common.white
  }
}));

export const StyledInlinePostButton = styled(LoadingButton)<LoadingButtonProps>(({ theme, loading, disabled }) => ({
  position: 'relative',
  border: `1px solid`,
  borderColor: theme.palette.common.black,
  padding: '12px 32px',
  backgroundColor: theme.palette.grey[800],
  fontSize: 16,
  fontWeight: 700,
  borderRadius: '10px',
  minWidth: 226,
  justifyContent: 'center',
  alignItems: 'center',
  [`& .${buttonClasses.endIcon}`]: {
    position: 'absolute',
    top: 2,
    right: 6,
    height: '90%',
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '7px 7px 0 7px'
  },
  '& svg': {
    opacity: loading || disabled ? 0.25 : 1
  },
  '&:hover': {
    backgroundColor: theme.palette.customTheme.contColor,
    color: theme.palette.common.white,
    [`& .${buttonClasses.startIcon}`]: {
      '& svg,path,g': {
        fill: theme.palette.common.white,
        stroke: theme.palette.common.white
      }
    },
    [`& .${buttonClasses.endIcon}`]: {
      backgroundColor: theme.palette.primary.dark,
      '& svg,path': {
        fill: theme.palette.common.white
      }
    }
  }
}));

export const StyledInlineMediaCard = styled(Stack)<StackProps>(({ theme }) => ({
  position: 'relative',
  backgroundColor: 'rgba(242, 242, 242, 0.35)',
  padding: 4,
  minHeight: 291,
  borderRadius: 16,
  alignItems: 'center',
  justifyContent: 'center',
  gap: 8,
  '& label:focus-within': {
    outline: 'none',
    border: 0
  }
}));

export const StyledInlineActionButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.customTheme.focusItem,
  color: theme.palette.common.white,
  borderRadius: '10px',
  padding: '12px 32px',
  [`&.${buttonClasses.disabled}`]: {
    opacity: 0.5,
    color: theme.palette.common.white
  },
  '&:hover': {
    backgroundColor: theme.palette.customTheme.focusItem
  }
}));

export const StyledInlinePostPaper = styled(Paper)<PaperProps>({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  overflowX: 'auto',
  maxHeight: 300,
  [`. ${autocompleteClasses.option}`]: {
    minWidth: 'auto',
    whiteSpace: 'nowrap'
  }
});

export const StyledInlinePostAudienceInput = styled(TextField)(({ theme }) => ({
  [`& .${inputBaseClasses.input}`]: {
    padding: '16px 0',
    width: 130,
    caretColor: theme.palette.secondary.main
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: 'transparent',
    border: 0
  },
  [`&.${textFieldClasses.root}`]: {
    backgroundColor: 'inherit',
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: 'inherit',
      borderColor: 'transparent'
    }
  }
}));

export const StyledInlinePostAudienceChip = styled(Chip)<ChipProps>(({ theme }) => ({
  [`&.${chipClasses.root}`]: {
    color: theme.palette.common.black,
    fontWeight: 400
  },
  [`& .${chipClasses.icon}`]: {
    color: theme.palette.customTheme.focusItem
  }
}));

export const StyledTransparentButton = styled(Button)<ButtonProps>(({ theme }) => ({
  minWidth: 'auto',
  padding: 0,
  margin: 0,
  fontWeight: 700,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent'
  }
}));

export const StyledTransparentAssetsActionButton = styled(StyledTransparentButton)<ButtonProps>(({ theme }) => ({
  minWidth: 'auto',
  width: '100%',
  margin: 0,
  fontWeight: 400,
  justifyContent: 'space-between',
  padding: '16px 12px',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent'
  }
}));

export const StyledAdminSettingsFixedHeightWrapper = styled(Stack)<StackProps>(({ theme }) => ({
  overflowY: 'scroll',
  transform: 'translate3d(0,0,0)',
  willTransform: 'transform',
  // padding: '0 8px',
  // margin: '0 -8px',
  backgroundColor: 'inherit',
  borderRadius: '10px',
  // border: '1px solid',
  // borderColor: theme.palette.customTheme.contentBorderColor,
  [theme.breakpoints.up('sm')]: {
    maxHeight: '100%'
  },
  [theme.breakpoints.up('lg')]: {
    maxHeight: 'calc(100vh - 136px)'
  }
}));

export const getBackgroundColor = (role: string, theme: Theme) => {
  switch (role) {
    case 'Owner':
      return theme.palette.secondary.main;
    case 'Editor':
    case 'Contributor':
    case 'Commenter':
      return theme.palette.customTheme.drawerBackground;
    default:
      return theme.palette.background.default;
  }
};

export const StyledAudienceListItemButton = styled(ListItemButton)(({ theme, selected }) => ({
  padding: '8px',
  borderLeft: `7px solid`,
  borderRadius: '4px',
  backgroundColor: 'transparent',
  borderColor: selected ? theme.palette.customTheme.focusItem : 'transparent',
  transition: 'border-color, background-color 0.6 ease-in-out',
  '&.Mui-selected': {
    margin: 0,
    backgroundColor: theme.palette.customTheme.drawerBackground
  }
}));

export const StyledPostAnalyticsAudienceButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.customTheme.contColor,
  padding: '3px 7px',
  minWidth: 126,
  fontSize: 12,
  wordWrap: 'break-word',
  borderRadius: '8px',
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.customTheme.focusItem
  }
}));

export const StyledProfileLink = styled(Button)<ButtonProps>(() => ({
  opacity: 0,
  fontWeight: 700,
  ml: 'auto',
  backgroundColor: 'transparent',
  transition: 'opacity 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline'
  }
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  width: 250,
  backgroundColor: 'transparent'
}));

export const StyledBuilderCTAButton = styled(Button)<ButtonProps>(({ theme }) => ({
  padding: '12px 32px',
  backgroundColor: theme.palette.customTheme.contColor,
  color: theme.palette.customTheme.contBgr,
  width: 183,
  marginTop: '30px',
  borderRadius: '10px',
  '&:hover': {
    backgroundColor: theme.palette.customTheme.focusItem
  }
}));

export const StyledEmployeeRecordMenuItem = styled(ListItemButton)(({ theme }) => ({
  [`&.${listItemButtonClasses.root}`]: {
    width: '100%',
    borderRadius: '7px',
    margin: '3px 0',
    padding: '8px 16px 8px 24px',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.customTheme.headerColorSub,
    transition: theme.transitions.create('border-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard
    }),
    '&::before': {
      content: 'none'
    }
  },
  '&:hover': {
    borderLeft: `8px solid ${theme.palette.primary.dark}`
  },
  [`&.${listItemButtonClasses.selected}`]: {
    backgroundColor: theme.palette.secondary.dark,
    [`& .${typographyClasses.root}`]: {
      fontWeight: 700
    }
  },
  [`&.${listItemButtonClasses.focusVisible}`]: {
    backgroundColor: theme.palette.secondary.dark
  }
}));

export const PrimaryTypographyHeader = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontWeight: 700,
  color: theme.palette.customTheme.navColor
}));

export const PrimaryTypographyBodyBold = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  color: theme.palette.customTheme.navColor
}));

export const StyledEmployeeRecordCategoryListItem = styled(ListItem)(({ theme }) => ({
  [`&.${listItemClasses.root}`]: {
    borderRadius: '8px',
    transition: theme.transitions.create('background-color, transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard
    })
  },
  [`&.${listItemClasses.root}:hover`]: {
    backgroundColor: theme.palette.customTheme.hoverItem,
    transform: 'translateX(5px)'
  }
}));

export const StyledEmployeeRecordAddSectionButton = styled(Button)(({ theme, disabled }) => ({
  padding: '12px 32px',
  backgroundColor: disabled ? theme.palette.customTheme.zebraFreezeOne : theme.palette.customTheme.contColor,
  color: disabled ? theme.palette.common.white : theme.palette.customTheme.contBgr,
  width: 192,
  marginTop: '30px',
  borderRadius: '10px',
  '&:hover': {
    backgroundColor: theme.palette.customTheme.focusItem
  }
}));

export const StyledEmployeeRecordSectionListItem = styled(ListItem)(() => ({
  [`&.${listItemClasses.root}`]: {
    height: 30,
    borderRadius: '8px',
    backgroundColor: 'transparent',
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent !important',
      paddingLeft: '8px !important'
    }
  },
  [`&.${listItemClasses.root}:hover`]: {
    backgroundColor: 'transparent'
  }
}));

export const StyledEmployeeRecordAutocompleteInput = styled(StyledFilledInput, {
  shouldForwardProp: (prop) => prop !== 'width'
})<{
  width?: number;
}>(({ theme, width }) => ({
  [`& .${filledInputClasses.root}`]: {
    border: '1px solid',
    borderBottom: 0,
    borderColor: 'transparent'
  },
  [`&.${formControlClasses.root}`]: {
    width: width,
    [`& .${filledInputClasses.focused}`]: {
      borderColor: theme.palette.customTheme.contColor,
      borderRadius: 0,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10
    }
  }
}));

export const StyledEmployeeRecordCheckbox = styled(Checkbox)<CheckboxProps>(({ theme }) => ({
  [`&.${checkboxClasses.root}`]: {
    color: theme.palette.common.black
  },
  [`&.${checkboxClasses.checked}`]: {
    color: theme.palette.customTheme.focusItem
  },
  [`&.${checkboxClasses.checked}.${checkboxClasses.disabled}`]: {
    color: theme.palette.grey[900],
    pointerEvents: 'none'
  }
}));

export const StyledEmployeeRecordAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`&.${autocompleteClasses.root}`]: {
    borderRadius: 'inherit'
  },
  [`&.${autocompleteClasses.root}`]: {
    borderRadius: 'inherit'
  },
  [`&. ${popperClasses.root}`]: {
    border: '1px solid',
    borderColor: theme.palette.customTheme.contColor
  }
}));

export const StyledEmplyoeeRecordAddToPageButton = styled(Button)(({ theme }) => ({
  padding: '12px 32px',
  backgroundColor: theme.palette.customTheme.contColor,
  color: theme.palette.customTheme.contBgr,
  width: 192,
  borderRadius: '10px',
  '&:hover': {
    backgroundColor: theme.palette.customTheme.focusItem
  }
}));

export const StyledEmplyoeeRecordCategoryPicker = styled(Stack)(({ theme }) => ({
  padding: '24px 16px',
  backgroundColor: theme.palette.grey[600],
  borderRadius: 16
})) as typeof Stack;

export const StyledEmployeeRecordTab = styled(Tab)(({ theme }) => ({
  borderRadius: '8px'
}));

interface EmployeeRecordFieldChipProps {
  backgroundColor: string;
  textColor: string;
  iconColor?: string;
}

enum ErbUsage {
  Selected,
  PresentInOtherSection,
  Unused
}

interface StyledEmployeeRecordFieldChipProps extends ChipProps {
  isSelected?: boolean;
  isCalculated?: boolean;
  isPresentInOtherSection?: boolean;
}

export const StyledEmployeeRecordFieldChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'isInGroup' && prop !== 'isSelected' && prop !== 'isCalculated' && prop !== 'isPresentInOtherSection'
})<StyledEmployeeRecordFieldChipProps>(({ theme, isSelected, isCalculated, isPresentInOtherSection }) => {
  const scalarNotCalculated: EmployeeRecordFieldChipProps[] = [
    {
      backgroundColor: theme.palette.customTheme.focusItem,
      textColor: theme.palette.common.white
    },
    {
      backgroundColor: theme.palette.secondary.dark,
      textColor: theme.palette.common.black
    },
    { backgroundColor: theme.palette.customTheme.solidBgr, textColor: theme.palette.common.black }
  ];

  const calculated: EmployeeRecordFieldChipProps[] = [
    {
      backgroundColor: '#BDE73E',
      textColor: theme.palette.common.white
    },
    {
      backgroundColor: '#A6FF47',
      textColor: theme.palette.common.black
    },
    { backgroundColor: theme.palette.customTheme.solidBgr, textColor: theme.palette.common.black }
  ];

  const chips = {
    scalarNotCalculated,
    calculated
  };

  const fieldType = isCalculated ? 'calculated' : 'scalarNotCalculated';

  let idx = ErbUsage.Unused;
  if (isPresentInOtherSection) idx = ErbUsage.PresentInOtherSection;
  if (isSelected) idx = ErbUsage.Selected;

  return {
    backgroundColor: chips[fieldType][idx].backgroundColor,
    color: chips[fieldType][idx].textColor,
    width: 'auto',
    height: 22,
    padding: '0 7px',
    borderRadius: '8px',
    '& .MuiChip-label': {
      padding: 0
    }
  };
});

export const StyledEmployeeRecordFieldColumnWidthTab = styled(Tab)<TabProps>(({ theme, disabled }) => ({
  backgroundColor: disabled ? theme.palette.customTheme.zebraFreezeTwo : theme.palette.customTheme.solidBgr,
  minWidth: 'auto',
  height: 20,
  padding: '0 4px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4
}));

export const StyledEmployeeRecordBuilderDrawerTabs = styled(Tabs)<TabsProps>(({ theme }) => ({
  minHeight: 'auto',
  [`& .${tabsClasses.flexContainer}`]: {
    direction: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  [`& .${tabClasses.root}`]: {
    fontWeight: 700
  }
}));

export const StyledEmployeeRecordBuilderAudienceButton = styled(StyledTransparentButton)(({ theme }) => ({
  color: theme.palette.customTheme.focusItem,
  textDecoration: 'underline',
  [`& .${typographyClasses.root}`]: {
    fontWeight: 700
  }
}));

export const StyledPeopleDirectoryHeaderStack = styled(Stack)(({ theme }) => ({
  padding: 16,
  border: '1px solid',
  borderColor: theme.palette.customTheme.drawerBackground,
  borderRadius: 16
}));

export const StyledPeopleDirectoryViewFilterButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.customTheme.drawerBackground,
  padding: '12px 16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 43,
  borderRadius: 8,
  fontWeight: 700,
  [`& .${buttonClasses.icon}`]: {
    marginRight: '12px'
  }
}));

export const StyledPeopleDirectoryFilterButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.customTheme.primaryA,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '12px',
  height: 43,
  borderRadius: 8
}));

export const StyledPeopleDirectoryShowActionsButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#1F2020',
  color: theme.palette.common.white,
  padding: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 43,
  borderRadius: 8,
  fontWeight: 700,
  [`& .${buttonClasses.icon}`]: {
    marginRight: '12px'
  }
}));

export const StyledPaginationMenuItem = styled(MenuItem)<MenuItemProps>(({ theme, selected }) => ({
  backgroundColor: selected ? theme.palette.customTheme.selectItem : 'transparent',
  borderRadius: 10
}));

export const StyledPeopleDirectoryDrawerChip = styled(Chip)<ChipProps>(({ theme }) => ({
  backgroundColor: theme.palette.customTheme.selectLabel,
  padding: '12px 16px',
  borderRadius: '8px',
  fontSize: 16,
  fontWeight: 700,
  height: 35,
  [`& .${chipClasses.label}`]: {
    padding: 0
  }
}));

export const StyledPeopleDirectorySearch = styled(TextField)<TextFieldProps>(({ theme }) => ({
  [`& .${inputBaseClasses.root}`]: {
    width: 348,
    backgroundColor: theme.palette.customTheme.primaryA,
    borderRadius: '4px',
    padding: '0 11px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    '& fieldset': {
      borderWidth: 1,
      borderColor: 'transparent',
      transition: theme.transitions.create('border-color', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      '& svg': {
        fill: theme.palette.customTheme.contColor
      }
    },
    [`&.${inputBaseClasses.focused}`]: {
      width: '100%',
      border: 0,
      '& fieldset': {
        borderWidth: 1,
        borderColor: theme.palette.secondary.main
      },
      '& svg': {
        fill: theme.palette.secondary.main
      }
    }
  },
  [`& .${inputBaseClasses.input}`]: {
    height: 50,
    padding: '0 8px'
  }
}));

export const StyledErbButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  padding: '12px 24px 12px 20px',
  borderRadius: '10px',
  height: 43
}));

export const StyledEmployeeGenericForm = styled(StyledFormWrapperComponent)`
  ${({ theme, isEditable }) => ({
    backgroundColor: theme.palette.customTheme.contBgr,
    padding: 0,
    border: '1px solid',
    borderColor: theme.palette.customTheme.borderMaster,
    borderRadius: 10,
    margin: '0 0 16px 0',
    '& .Mui-ProfileFiledHeader': {
      transition: theme.transitions.create(['background-color, border'])
    },
    '&:hover, &:focus-within': {
      ...(!isEditable
        ? {
            '& .Mui-ProfileFiledHeader': {
              borderLeft: '8px solid',
              borderRadius: 10,
              borderColor: theme.palette.primary.dark,
              backgroundColor: isEditable ? theme.palette.customTheme.drawerBackground : theme.palette.grey[700]
            }
          }
        : {}),
      '& button': {
        opacity: 1
      }
    }
  })}
`;

export const StyledPeopleDirectoryAutocompleteInput = styled(StyledFilledInput, {
  shouldForwardProp: (prop) => prop !== 'width'
})<{
  width?: number;
}>(({ width }) => ({
  [`& .${filledInputClasses.root}`]: {
    width,
    height: 50,
    border: 0,
    borderBottom: 0,
    borderColor: 'transparent',
    '&::before': {
      content: 'none'
    }
  },
  [`&.${formControlClasses.root}`]: {
    width: width,
    [`& .${filledInputClasses.focused}`]: {
      border: 0,
      borderBottom: 0,
      borderRadius: 0,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10
    }
  }
}));

export const StyledPeopleDirectoryCheckbox = styled(Checkbox)<CheckboxProps>(({ theme }) => ({
  [`&.${checkboxClasses.root}`]: {
    color: theme.palette.common.black,
    padding: 0
  },
  [`&.${checkboxClasses.checked}`]: {
    color: theme.palette.customTheme.focusItem
  },
  [`&.${checkboxClasses.checked}.${checkboxClasses.disabled}`]: {
    color: theme.palette.grey[900],
    pointerEvents: 'none'
  }
}));

export const StyledPeopleDirectoryFilterViewInput = styled(FilledInput, {
  shouldForwardProp: (prop) => prop !== 'width'
})<{
  width?: number;
}>(({ width, theme }) => ({
  minWidth: width,
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent'
  },
  '&::before': {
    content: 'none'
  },
  '&::after': {
    content: 'none'
  },
  '& .MuiInputBase-input': {
    '&::selection': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.4)
    }
  },
  [`&.Mui-focused`]: {
    backgroundColor: 'transparent'
  },
  [`& input`]: {
    padding: 0
  }
}));

export const StyledPeopleDirectoryViewFilterTab = styled(Tab, {
  shouldForwardProp: (prop) => prop !== 'isEditable' && prop !== 'isActive'
})<{
  isEditable?: boolean;
  isActive?: boolean;
}>(({ isEditable, isActive, theme }) => ({
  backgroundColor: isActive && !isEditable ? theme.palette.customTheme.drawerBackground : '#F7F5F7',
  padding: '12px 8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 43,
  borderRadius: 8,
  color: theme.palette.primary.main,
  opacity: 1,
  fontWeight: 700,
  '&:hover': {
    [`& .${tabClasses.icon}`]: {
      opacity: 1
    }
  },
  [`& .${tabClasses.icon}`]: {
    opacity: 0
  }
}));

export const StyledPeopleDirectoryAutocompleteListItem = styled(ListItem)(({ theme }) => ({
  [`&.${listItemClasses.root}`]: {
    minHeight: 46,
    py: 2,
    padding: 0,
    borderRadius: '8px',
    transition: theme.transitions.create('background-color, padding, height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    '&:hover': {
      backgroundColor: theme.palette.customTheme.hoverItem,
      paddingLeft: 8
    }
  }
}));

export const PeopleDirectoryTooltip = styled(
  forwardRef(({ className, customWidth, ...props }: StyledTooltipProps, ref) => <Tooltip ref={ref} {...props} classes={{ popper: className }} />)
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    width: 'fit-content',
    minHeight: 30,
    fontSize: 16,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: '8px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

export const StyledPeopleDirectoryTableCell = styled(TableCell)<TableCellProps>(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    height: 40,
    padding: '0 16px'
  }
}));
