import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  AudienceListItem,
  AudienceRole,
  CreateAudienceRequest,
  CreateAudienceResponse,
  DeleteAudienceRequest,
  RetrieveAudienceByIdRequest,
  RetrieveAudienceByIdResponse,
  RetrieveAudiencesRequest,
  RetrieveAudiencesResponse,
  UpdateAudienceRequest,
  UpdateAudienceResponse
} from '@thrivea/networking-client';
import {
  RetrieveEmployeesByQueryRequest,
  RetrieveEmployeesByQueryResponse,
  RetrieveEmployeesBySelectionRequest,
  RetrieveEmployeesBySelectionResponse,
  RetrieveEmployeesTotalCountBySelectedRequest,
  RetrieveEmployeesTotalCountBySelectedResponse
} from '@thrivea/organization-client';
import { RootState } from '@app/store';
import { ActionStatus } from '@shared/shared.model';
import { AudienceEmployeeWithRole } from './admin-settings.model';
import { EmployeeIdWithRole, LoadAudienceEmployeesRequest } from './permissions.model';
import { flattenData, groupEmployeesAlphabetically, groupOptionsByType } from '@/utils';

export interface AudienceGroupState {
  entities: {
    audienceGroups: {
      byId: { [key: string]: AudienceListItem };
      allIds: string[];
    };
    audienceGroupMembers: {
      byInitialId: { [key: string]: AudienceEmployeeWithRole };
      byUpdatedId: { [key: string]: AudienceEmployeeWithRole };
      initialIds: string[];
      addedIds: string[];
      removedIds: string[];
      updatedIds: string[];
    };
  };
  ui: {
    audienceGroupCreatedStatus: ActionStatus;
    audienceGroupsStatus: ActionStatus;
    audienceGroupsTotalCount: number;
    autocompleteSelectedEmployeesTotalCount: number;
    retrievedAudienceByIdStatus: ActionStatus;
    autocompleteItemsStatus: ActionStatus;
    hasMoreAudienceGroups: boolean;
    currentAudiencePage: number;
    audienceMembersTablePageIndex: number;
    employeesToBeAddedCount: number;
  };
  audienceGroupAutocompleteItems: RetrieveEmployeesByQueryResponse;
  currentAudienceRole: AudienceRole;
  currentAudienceGroup: RetrieveAudienceByIdResponse;
}

const initialState: AudienceGroupState = {
  entities: {
    audienceGroups: {
      byId: {},
      allIds: []
    },
    audienceGroupMembers: {
      byInitialId: {},
      byUpdatedId: {},
      initialIds: [],
      addedIds: [],
      removedIds: [],
      updatedIds: []
    }
  },
  ui: {
    audienceGroupCreatedStatus: ActionStatus.Idle,
    audienceGroupsStatus: ActionStatus.Idle,
    audienceGroupsTotalCount: 0,
    autocompleteSelectedEmployeesTotalCount: 0,
    retrievedAudienceByIdStatus: ActionStatus.Idle,
    autocompleteItemsStatus: ActionStatus.Idle,
    hasMoreAudienceGroups: false,
    currentAudiencePage: 1,
    audienceMembersTablePageIndex: 0,
    employeesToBeAddedCount: 0
  },
  audienceGroupAutocompleteItems: {} as RetrieveEmployeesByQueryResponse,
  currentAudienceRole: AudienceRole['COMMENTER'],
  currentAudienceGroup: {} as RetrieveAudienceByIdResponse
};

export const audienceGroupSlice = createSlice({
  name: 'audienceGroup',
  initialState,
  reducers: {
    retrieveAudienceGroupsRequested: (state, action: PayloadAction<RetrieveAudiencesRequest>) => {
      state.ui.audienceGroupsStatus = ActionStatus.Pending;

      if (action.payload.pageNumber === 1) {
        state.entities.audienceGroups.byId = {};
        state.entities.audienceGroups.allIds = [];
      }

      state.ui.currentAudiencePage = action.payload.pageNumber;
    },
    retrieveAudienceGroupsSucceded: (state, action: PayloadAction<RetrieveAudiencesResponse>) => {
      const { audiences, totalCount } = action.payload;

      for (const audience of audiences) {
        state.entities.audienceGroups.byId[audience.audienceId] = audience;
        state.entities.audienceGroups.allIds.push(audience.audienceId);
      }

      state.ui.audienceGroupsStatus = ActionStatus.Idle;
      state.ui.audienceGroupsTotalCount = totalCount;
      state.ui.hasMoreAudienceGroups = state.entities.audienceGroups.allIds.length < action.payload.totalCount;
    },
    retrieveAudienceGroupsFailed: (state) => {
      state.ui.audienceGroupsStatus = ActionStatus.Failed;
    },
    retrieveAudienceGroupAutocompleteItemsRequested: (state, _action: PayloadAction<RetrieveEmployeesByQueryRequest>) => {
      state.ui.autocompleteItemsStatus = ActionStatus.Pending;
    },
    retrieveAudienceGroupAutocompleteItemsSucceeded: (state, action: PayloadAction<RetrieveEmployeesByQueryResponse>) => {
      state.audienceGroupAutocompleteItems = action.payload;
      state.ui.autocompleteItemsStatus = ActionStatus.Idle;
    },
    retrieveAudienceGroupAutocompleteItemsFailed: (state) => {
      state.audienceGroupAutocompleteItems = {} as RetrieveEmployeesByQueryResponse;
      state.ui.autocompleteItemsStatus = ActionStatus.Failed;
    },
    createAudienceRequested: (state, action: PayloadAction<CreateAudienceRequest>) => {
      state.ui.audienceGroupCreatedStatus = ActionStatus.Pending;
    },
    createAudienceSucceeded: (state, action: PayloadAction<CreateAudienceResponse>) => {
      const audience = action.payload.audience!;

      state.entities.audienceGroups.byId[audience.audienceId] = audience;
      state.entities.audienceGroups.allIds.push(audience.audienceId);

      state.ui.audienceGroupCreatedStatus = ActionStatus.Idle;
    },
    createAudienceFailed: (state) => {
      state.ui.audienceGroupCreatedStatus = ActionStatus.Failed;
    },
    updateAudienceRequested: (state, action: PayloadAction<UpdateAudienceRequest>) => {},
    updateAudienceSucceeded: (state, action: PayloadAction<UpdateAudienceResponse>) => {
      const audience = action.payload.audience!;
      state.entities.audienceGroups.byId[audience.audienceId] = audience;
    },
    updateAudienceFailed: (state) => {},
    deleteAudienceRequested: (state, action: PayloadAction<DeleteAudienceRequest>) => {},
    deleteAudienceSucceeded: (state, action: PayloadAction<string>) => {
      const audienceGroupId = action.payload;

      delete state.entities.audienceGroups.byId[audienceGroupId];
      state.entities.audienceGroups.allIds = state.entities.audienceGroups.allIds.filter((audienceId) => audienceId !== audienceGroupId);
    },
    deleteAudienceFailed: (state) => {},
    loadAudienceGroupMembersRequested: (state, action: PayloadAction<LoadAudienceEmployeesRequest>) => {
      state.entities.audienceGroupMembers.byInitialId = {};
      state.entities.audienceGroupMembers.byUpdatedId = {};
      state.entities.audienceGroupMembers.initialIds = [];
      state.entities.audienceGroupMembers.addedIds = [];
      state.entities.audienceGroupMembers.removedIds = [];
      state.entities.audienceGroupMembers.updatedIds = [];

      state.ui.autocompleteSelectedEmployeesTotalCount = 0;
      state.ui.audienceMembersTablePageIndex = 0;
    },
    loadAudienceGroupMembersSucceeded: (state, action: PayloadAction<RetrieveEmployeesBySelectionResponse>) => {
      const { employees } = action.payload;

      for (const employee of employees) {
        state.entities.audienceGroupMembers.byInitialId[employee.employeeId] = {
          ...employee,
          role: state.currentAudienceGroup.employeeIdsWithRole.find((eidw) => eidw.employeeId === employee.employeeId)!.role
        };
        state.entities.audienceGroupMembers.initialIds.push(employee.employeeId);
      }
    },
    loadAudienceGroupMembersFailed: (state) => {},
    changeRoleForSelectedEmployees: (state, action: PayloadAction<AudienceRole>) => {
      state.currentAudienceRole = action.payload;
    },
    addSelectedEmployeeToAudienceGroupRequested: (state, action: PayloadAction<RetrieveEmployeesBySelectionRequest>) => {},
    addSelectedEmployeeToAudienceGroupSucceeded: (state, action: PayloadAction<RetrieveEmployeesBySelectionResponse>) => {
      const { employees } = action.payload;

      const employeesToBeAdded = employees.filter(
        (e) =>
          (!state.entities.audienceGroupMembers.initialIds.includes(e.employeeId) && !state.entities.audienceGroupMembers.addedIds.includes(e.employeeId)) ||
          state.entities.audienceGroupMembers.removedIds.includes(e.employeeId)
      );

      for (const employee of employeesToBeAdded) {
        state.entities.audienceGroupMembers.byInitialId[employee.employeeId] = {
          ...employee,
          role: state.currentAudienceRole
        };
        state.entities.audienceGroupMembers.addedIds.push(employee.employeeId);
        state.entities.audienceGroupMembers.removedIds = state.entities.audienceGroupMembers.removedIds.filter((id) => id !== employee.employeeId);
      }

      state.ui.employeesToBeAddedCount = employeesToBeAdded.length;
      state.currentAudienceRole = AudienceRole.COMMENTER;
    },
    addSelectedEmployeeToAudienceGroupFailed: (state) => {},
    createNewAudienceGroup: (state) => {
      state.entities.audienceGroupMembers.byInitialId = {};
      state.entities.audienceGroupMembers.byUpdatedId = {};
      state.entities.audienceGroupMembers.initialIds = [];
      state.entities.audienceGroupMembers.addedIds = [];
      state.entities.audienceGroupMembers.removedIds = [];
      state.entities.audienceGroupMembers.updatedIds = [];
    },
    noSelectedItems: (state) => {
      state.ui.autocompleteSelectedEmployeesTotalCount = 0;
    },
    retrieveAudienceGroupSelectedEmployeesTotalCountRequested: (state, action: PayloadAction<RetrieveEmployeesTotalCountBySelectedRequest>) => {},
    retrieveAudienceGroupSelectedEmployeesTotalCountSuceeded: (state, action: PayloadAction<RetrieveEmployeesTotalCountBySelectedResponse>) => {
      const { totalCount } = action.payload;
      state.ui.autocompleteSelectedEmployeesTotalCount = totalCount;
    },
    retrieveAudienceGroupSelectedEmployeesTotalCountFailed: (state) => {},
    retrieveAudienceByIdRequested: (state, action: PayloadAction<RetrieveAudienceByIdRequest>) => {
      state.ui.retrievedAudienceByIdStatus = ActionStatus.Pending;
    },
    retrieveAudienceByIdSucceeded: (state, action: PayloadAction<RetrieveAudienceByIdResponse>) => {
      state.currentAudienceGroup = action.payload;
      state.ui.retrievedAudienceByIdStatus = ActionStatus.Idle;
    },
    retrieveAudienceByIdFailed: (state) => {
      state.ui.retrievedAudienceByIdStatus = ActionStatus.Failed;
    },
    removeSelectedAudienceGroupMember: (state, action: PayloadAction<string>) => {
      state.entities.audienceGroupMembers.removedIds = Array.from(new Set(state.entities.audienceGroupMembers.removedIds).add(action.payload));
    },
    removeSelectedAudienceGroupMembers: (state, action: PayloadAction<string[]>) => {
      state.entities.audienceGroupMembers.removedIds = Array.from(new Set([...state.entities.audienceGroupMembers.removedIds, ...action.payload]));
    },
    changeRole: (state, action: PayloadAction<EmployeeIdWithRole>) => {
      state.entities.audienceGroupMembers.byUpdatedId[action.payload.employeeId] = {
        ...state.entities.audienceGroupMembers.byInitialId[action.payload.employeeId],
        role: action.payload.role
      };
      state.entities.audienceGroupMembers.updatedIds.push(action.payload.employeeId);
    },
    changeRoles: (state, action: PayloadAction<EmployeeIdWithRole[]>) => {
      const employees = action.payload;

      for (const employee of employees) {
        state.entities.audienceGroupMembers.byUpdatedId[employee.employeeId] = {
          ...state.entities.audienceGroupMembers.byInitialId[employee.employeeId],
          role: employee.role
        };
      }
      state.entities.audienceGroupMembers.updatedIds.push(...employees.map((e) => e.employeeId));
    },
    cancelAudienceGroupEdit: (state) => {
      state.entities.audienceGroupMembers.byUpdatedId = {};
      state.entities.audienceGroupMembers.addedIds = [];
      state.entities.audienceGroupMembers.removedIds = [];
      state.entities.audienceGroupMembers.updatedIds = [];
      state.ui.audienceMembersTablePageIndex = 0;
      state.currentAudienceRole = AudienceRole.COMMENTER;
    },
    setAudienceMembersTablePageIndex: (state, action: PayloadAction<number>) => {
      state.ui.audienceMembersTablePageIndex = action.payload;
    }
  }
});

const selectAudienceGroupsById = (state: RootState) => state.audienceGroup.entities.audienceGroups.byId;
const selectAudienceGroupsIds = (state: RootState) => state.audienceGroup.entities.audienceGroups.allIds;
export const selectAudienceGroups = createSelector([selectAudienceGroupsById, selectAudienceGroupsIds], (byId, ids) => ids.map(id => byId[id])); 
export const selectAudienceGroupsStatus = (state: RootState) => state.audienceGroup.ui.audienceGroupsStatus;
export const selectHasMoreAudienceGroups = (state: RootState) => state.audienceGroup.ui.hasMoreAudienceGroups;
export const selectCurrentAudiencePage = (state: RootState) => state.audienceGroup.ui.currentAudiencePage;
export const selectAudienceGroupsTotalCount = (state: RootState) => state.audienceGroup.ui.audienceGroupsTotalCount;

export const selectAudienceGroupEmployeesByInitialId = (state: RootState) => state.audienceGroup.entities.audienceGroupMembers.byInitialId;
export const selectAudienceGroupEmployeesByUpdatedId = (state: RootState) => state.audienceGroup.entities.audienceGroupMembers.byUpdatedId;
export const selectInitialAudienceGroupEmployeeIds = (state: RootState) => state.audienceGroup.entities.audienceGroupMembers.initialIds;
export const selectAddedAudienceGroupEmployeeIds = (state: RootState) => state.audienceGroup.entities.audienceGroupMembers.addedIds;
export const selectRemovedAudienceGroupEmployeeIds = (state: RootState) => state.audienceGroup.entities.audienceGroupMembers.removedIds;
export const selectUpdatedAudienceGroupEmployeeIds = (state: RootState) => state.audienceGroup.entities.audienceGroupMembers.updatedIds;
export const selectAudienceGroupAutocompleteSelectedEmployeesTotalCount = (state: RootState) => state.audienceGroup.ui.autocompleteSelectedEmployeesTotalCount;

export const selectHasAudienceGroupMemberChanged = (state: RootState) => state.audienceGroup;

export const selectChosenAudienceGroupMemberIds = createSelector(
  [selectInitialAudienceGroupEmployeeIds, selectAddedAudienceGroupEmployeeIds, selectRemovedAudienceGroupEmployeeIds],
  (initialIds, addedIds, removedIds) => Array.from(new Set([...initialIds, ...addedIds].filter((id) => !removedIds.includes(id))))
);

export const selectChosenAudienceGroupMembers = createSelector(
  [selectChosenAudienceGroupMemberIds, selectAudienceGroupEmployeesByInitialId, selectAudienceGroupEmployeesByUpdatedId, selectUpdatedAudienceGroupEmployeeIds],
  (chosenEmployeeIds, byInitialId, byUpdatedId, updatedIds) =>
    chosenEmployeeIds
      .filter((id) => !updatedIds.includes(id))
      .map((id) => byInitialId[id])
      .concat(updatedIds.map((id) => byUpdatedId[id]))
);

const selectAudienceGroupAutocompleteItems = (state: RootState) => state.audienceGroup.audienceGroupAutocompleteItems;

const selectAudienceGroupAutocompleteItemsGroupedByType = createSelector([selectAudienceGroupAutocompleteItems], (audienceGroupAutocompleteItems) =>
  groupOptionsByType(flattenData(audienceGroupAutocompleteItems))
);

const selectAudienceGroupAutocompleteEmployeeItems = (state: RootState) => state.audienceGroup.audienceGroupAutocompleteItems.employeeResults?.employees || [];

const selectAudienceGroupAutocompleteEmployeeItemsGroupedAlphabetically = createSelector(
  [selectAudienceGroupAutocompleteEmployeeItems],
  (audienceGroupAutocompleteItems) => groupEmployeesAlphabetically(audienceGroupAutocompleteItems)
);

export const selectAudienceGroupAutocompleteOptions = createSelector(
  [selectAudienceGroupAutocompleteItemsGroupedByType, selectAudienceGroupAutocompleteEmployeeItemsGroupedAlphabetically, (_, searchText) => searchText],
  (groupedOptionsByType, groupedEmployeesAlphabetically, searchText) =>
    searchText.length >= 2
      ? Object.keys(groupedOptionsByType).flatMap((key) => groupedOptionsByType[key])
      : Object.keys(groupedEmployeesAlphabetically).flatMap((key) => groupedEmployeesAlphabetically[key])
);

export const selectAudienceGroupAutocompleteItemsStatus = (state: RootState) => state.audienceGroup.ui.autocompleteItemsStatus;

export const selectCurrentAudienceRole = (state: RootState) => state.audienceGroup.currentAudienceRole;
export const selectCurrentAudienceGroup = (state: RootState) => state.audienceGroup.currentAudienceGroup;
export const selectCurrentAudienceGroupName = createSelector([selectCurrentAudienceGroup], (group) => group.name);

export const selectRetrievedAudienceByIdStatus = (state: RootState) => state.audienceGroup.ui.retrievedAudienceByIdStatus;
export const selectAudienceGroupCreateStatus = (state: RootState) => state.audienceGroup.ui.audienceGroupCreatedStatus;
export const selectAudienceItemById = (state: RootState, audienceById: string) => state.audienceGroup.entities.audienceGroups.byId[audienceById];

export const selectEmployeeRole = (state: RootState, employeeId: string) => state.audienceGroup.entities.audienceGroups.byId[employeeId];

export const selectAudienceMembersTablePageIndex = (state: RootState) => state.audienceGroup.ui.audienceMembersTablePageIndex;

export const selectEmployeesToBeAddedCount = (state: RootState) => state.audienceGroup.ui.employeesToBeAddedCount;

export const {
  retrieveAudienceGroupsRequested,
  retrieveAudienceGroupsSucceded,
  retrieveAudienceGroupsFailed,
  createAudienceRequested,
  createAudienceSucceeded,
  createAudienceFailed,
  updateAudienceRequested,
  updateAudienceSucceeded,
  updateAudienceFailed,
  deleteAudienceRequested,
  deleteAudienceSucceeded,
  deleteAudienceFailed,
  retrieveAudienceGroupAutocompleteItemsRequested,
  retrieveAudienceGroupAutocompleteItemsSucceeded,
  retrieveAudienceGroupAutocompleteItemsFailed,
  loadAudienceGroupMembersRequested,
  loadAudienceGroupMembersSucceeded,
  loadAudienceGroupMembersFailed,
  changeRoleForSelectedEmployees,
  addSelectedEmployeeToAudienceGroupRequested,
  addSelectedEmployeeToAudienceGroupSucceeded,
  addSelectedEmployeeToAudienceGroupFailed,
  noSelectedItems,
  retrieveAudienceGroupSelectedEmployeesTotalCountRequested,
  retrieveAudienceGroupSelectedEmployeesTotalCountSuceeded,
  retrieveAudienceGroupSelectedEmployeesTotalCountFailed,
  retrieveAudienceByIdRequested,
  retrieveAudienceByIdSucceeded,
  retrieveAudienceByIdFailed,
  changeRole,
  changeRoles,
  cancelAudienceGroupEdit,
  removeSelectedAudienceGroupMember,
  removeSelectedAudienceGroupMembers,
  createNewAudienceGroup,
  setAudienceMembersTablePageIndex
} = audienceGroupSlice.actions;

export default audienceGroupSlice.reducer;
