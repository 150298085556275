import { useTheme } from '@mui/material';
import React from 'react';

interface CloseIconAttributes {
  width?: number;
  height?: number;
  color?: string;
}

export const CloseIcon: React.FC<CloseIconAttributes> = ({ width = 18, height = 19, color = '#000000' }: CloseIconAttributes) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
      <path d="M13.2188 5.21802L4.63258 13.8042" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.63281 5.21802L13.219 13.8042" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
