import React from 'react';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import { RetrieveEmployeeBasicInfoRequest } from '@thrivea/organization-client';
import { alpha, Avatar, IconButton, Skeleton, Typography } from '@mui/material';
import { useAppSelector } from '@app/hooks';
import { CircleIcon, PictureSizeSuffix, RowCenterStack } from '@/shared';
import { pictureUrl, initials } from '@/utils';
import { selectEmployeeProfileAndCoverReadSasToken, selectLocale } from '@features/employee-profile';
import { retrieveEmployeeBasicInfoRequested } from '@features/admin-settings';
import { selectAuthorById } from '@features/homepage';
import { useTranslation } from 'react-i18next';

interface PostAuthorProps {
  authorId: string;
  date: DateTime;
  editedTime?: DateTime;
}

export const PostAuthor: React.FC<PostAuthorProps> = ({ authorId, date, editedTime }) => {
  const { t } = useTranslation(['common']);
  const locale = useAppSelector<string>(selectLocale);
  const dispatch = useDispatch();
  const author = useAppSelector((state) => selectAuthorById(state, authorId));
  const profileReadSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);

  const handleEmployeeDrawerOpen = (employeeId: string) => {
    dispatch(
      retrieveEmployeeBasicInfoRequested(
        new RetrieveEmployeeBasicInfoRequest({
          employeeId
        })
      )
    );
  };

  return (
    <RowCenterStack gap={2}>
      {author === undefined ? (
        <Skeleton animation="wave" variant="rectangular" width={46} height={46} />
      ) : (
        <IconButton
          onClick={() => handleEmployeeDrawerOpen(author.id)}
          sx={{
            padding: '8px 0'
          }}
        >
          <Avatar
            sx={{
              width: 36,
              height: 36,
              borderRadius: 2,
              border: '1px solid',
              borderColor: (theme) => theme.palette.common.black
            }}
            src={pictureUrl(author.profilePictureUrl, profileReadSasToken, PictureSizeSuffix.sm)}
            alt={author.name}
          >
            {initials(author.name)}
          </Avatar>
        </IconButton>
      )}
      <RowCenterStack gap={1}>
        {author === undefined ? (
          <Skeleton animation="wave" height={20} width={200} />
        ) : (
          <Typography
            sx={{
              fontWeight: '600',
              cursor: 'pointer'
            }}
          >
            {author.name}
          </Typography>
        )}
        {!editedTime && (
          <RowCenterStack gap={1}>
            <CircleIcon size={4} color={'#251D3873'} />
            <Typography component="span" variant="caption" sx={{ lineHeight: 'normal', color: (theme) => alpha(theme.palette.primary.main, 0.45) }}>
              {date.toRelative({ locale })}
            </Typography>
          </RowCenterStack>
        )}
        {editedTime && (
          <RowCenterStack gap={1}>
            <CircleIcon size={4} color={'#251D3873'} />
            <Typography component="span" variant="caption" sx={{ lineHeight: 'normal', color: (theme) => alpha(theme.palette.primary.main, 0.45) }}>
              {editedTime.toRelative({ locale })}
            </Typography>
            <CircleIcon size={4} color={'#251D3873'} />
            <Typography component="span" variant="caption" sx={{ lineHeight: 'normal', color: (theme) => alpha(theme.palette.primary.main, 0.45) }}>
              {t('edited', { ns: 'homepage' })}
            </Typography>
          </RowCenterStack>
        )}
      </RowCenterStack>
    </RowCenterStack>
  );
};
