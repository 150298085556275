import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { ConnectError } from '@connectrpc/connect';
import { RootState } from '@app/store';
import { AlertColor } from '@mui/material';
import { ActionStatus } from '@/shared/shared.model';
import { Deployment, RetrieveLatestDeploymentRequest } from '@thrivea/admin-onboarding-client';

export interface SnackbarState {
  infoMessage: string;
  errorMessage: ConnectError;
  isSnackbarOpen: boolean;
  snackbarColor: AlertColor;
  snackbarStatus: ActionStatus;
  isDeploymentSnackbarOpen: boolean;
  lastCommitHash: string;
  isPostDraftSnackbarOpen: boolean;
}

const initialState: SnackbarState = {
  infoMessage: '',
  errorMessage: {} as ConnectError,
  isSnackbarOpen: false,
  snackbarColor: 'info',
  snackbarStatus: ActionStatus.Idle,
  isDeploymentSnackbarOpen: false,
  lastCommitHash: '',
  isPostDraftSnackbarOpen: false
};

export const snackBarSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    showSuccess: (state, action: PayloadAction<any>) => {
      state.isSnackbarOpen = true;
      state.infoMessage = action.payload;
      state.snackbarColor = 'success';
      state.snackbarStatus = ActionStatus.Idle;
    },
    showWarning: (state, action: PayloadAction<any>) => {
      state.isSnackbarOpen = true;
      state.errorMessage = action.payload;
      state.snackbarColor = 'warning';
      state.snackbarStatus = ActionStatus.Failed;
    },
    closeSnackbar: (state) => {
      state.isSnackbarOpen = false;
      state.isDeploymentSnackbarOpen = false;
    },
    retrieveLatestDeploymentRequested: (state, action: PayloadAction<RetrieveLatestDeploymentRequest>) => {},
    retrieveLatestDeploymentSucceeded: (state, action: PayloadAction<Deployment>) => {
      state.lastCommitHash = action.payload.commitHash;
    },
    retrieveLatestDeploymentFailed: (state) => {},
    notifyNewDeployment: (state, action: PayloadAction<Deployment>) => {
      state.isDeploymentSnackbarOpen = state.lastCommitHash !== action.payload.commitHash;
      state.lastCommitHash = action.payload.commitHash;
    },
    showPostDraftSnackbar: (state) => {
      state.isPostDraftSnackbarOpen = true;
    },
    closePostDraftSnackbar: (state) => {
      state.isPostDraftSnackbarOpen = false;
    }
  }
});

export const selectSnackbarErrorMessage = (state: RootState) => state.snackbar.errorMessage;
export const selectSnackbarInfoMessage = (state: RootState) => state.snackbar.infoMessage;
export const selectSnackbarColor = (state: RootState) => state.snackbar.snackbarColor;
export const selectSnackbarStatus = (state: RootState) => state.snackbar.snackbarStatus;
export const selectLastCommitHash = (state: RootState) => state.snackbar.lastCommitHash;
export const selectIsSnackbarOpen = (state: RootState) => state.snackbar.isSnackbarOpen;
export const selectIsDeploymentSnackbarOpen = (state: RootState) => state.snackbar.isDeploymentSnackbarOpen;
export const selectIsPostDraftSnackbarOpen = (state: RootState) => state.snackbar.isPostDraftSnackbarOpen;

export const selectIsSnackbarVisible = createSelector(
  [selectIsSnackbarOpen, selectIsDeploymentSnackbarOpen, selectIsPostDraftSnackbarOpen],
  (isSnackbarOpen, isDeploymentSnackbarOpen, isPostDraftSnackbarOpen) => isSnackbarOpen || isDeploymentSnackbarOpen || isPostDraftSnackbarOpen
);

export const {
  showSuccess,
  showWarning,
  closeSnackbar,
  retrieveLatestDeploymentRequested,
  retrieveLatestDeploymentSucceeded,
  retrieveLatestDeploymentFailed,
  notifyNewDeployment,
  showPostDraftSnackbar,
  closePostDraftSnackbar
} = snackBarSlice.actions;
export default snackBarSlice.reducer;
