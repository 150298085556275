import React, { useRef, useState } from 'react';
import { Stack, Avatar, Typography, Box, Skeleton, IconButton, alpha, Button, ClickAwayListener } from '@mui/material';
import { CircleIcon, PictureSizeSuffix, QuillEditor, RowCenterStack, ActionStatus } from '@/shared';
import {
  CommentReactions,
  EmojiPicker,
  reactToCommentRequested,
  selectCommentReactionsGroupedByEmoji,
  selectDeleteCommentStatus,
  sanitizeConfig,
  selectDeletedCommentId,
  selectAuthorById,
  PostCommentMenu,
  editCommentRequested,
  selectAudienceIdByPostId
} from '@features/homepage';
import { DateTime } from 'luxon';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectEmployeeProfileAndCoverReadSasToken, selectLocale } from '@features/employee-profile';
import { EditCommentRequest, ReactToCommentRequest } from '@thrivea/networking-client';
import keys from 'lodash/keys';
import { RetrieveEmployeeBasicInfoRequest } from '@thrivea/organization-client';
import { selectCurrentEmployeeId } from '@app/user';
import DOMPurify from 'dompurify';
import { initials } from '@utils/initials';
import { retrieveEmployeeBasicInfoRequested } from '@features/admin-settings';
import { pictureUrl } from '@utils/pictureUrl';
import { useTranslation } from 'react-i18next';
import { removeBomCharacters } from '@utils/removeBomCharacters';
import Quill from 'quill';

export interface PostCommentProps {
  postId: string;
  commentId: string;
  authorId: string;
  createdTime: DateTime;
  editedTime?: DateTime;
  message: string;
  handleSetCommentEditable: (id: string) => void;
  isEditable: boolean;
}

interface Emoji {
  id: number;
  native: string;
}

export const PostComment: React.FC<PostCommentProps> = ({
  postId,
  commentId,
  authorId,
  createdTime,
  editedTime,
  message,
  isEditable,
  handleSetCommentEditable
}) => {
  const { t } = useTranslation(['homepage']);
  const dispatch = useAppDispatch();
  const deleteCommentStatus = useAppSelector<ActionStatus>(selectDeleteCommentStatus);
  const deletedCommentId = useAppSelector(selectDeletedCommentId);
  const commentReactionsGroupedByEmoji = useAppSelector((state) => selectCommentReactionsGroupedByEmoji(state, commentId));
  const locale = useAppSelector<string>(selectLocale);
  const currentUserId = useAppSelector(selectCurrentEmployeeId);
  const rteContent = { __html: DOMPurify.sanitize(message, sanitizeConfig) };
  const author = useAppSelector((state) => selectAuthorById(state, authorId));
  const profileAndCoverPictureSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);
  const audienceIdByPostId = useAppSelector((state) => selectAudienceIdByPostId(state, postId));
  const [text, setText] = useState(message);
  const quillRef = useRef<Quill | null>(null);

  const hasCurrentUserAlreadyReacted = (emoji: string) => {
    return commentReactionsGroupedByEmoji.hasOwnProperty(emoji) && commentReactionsGroupedByEmoji[emoji].some((r) => r.authorId === currentUserId);
  };

  const handleEmojiSelect = (emoji: Emoji) => {
    if (hasCurrentUserAlreadyReacted(emoji.native)) return;

    dispatch(
      reactToCommentRequested(
        new ReactToCommentRequest({
          postId,
          commentId,
          emoji: emoji.native,
          authorId: currentUserId
        })
      )
    );
  };

  const handleEmployeeDrawerOpen = (employeeId: string) => {
    dispatch(
      retrieveEmployeeBasicInfoRequested(
        new RetrieveEmployeeBasicInfoRequest({
          employeeId
        })
      )
    );
  };

  const handleCommentEdit = () => {
    dispatch(
      editCommentRequested(
        new EditCommentRequest({
          postId,
          commentId,
          commentText: removeBomCharacters(text)
        })
      )
    );
    handleSetCommentEditable(commentId);
  };

  return (
    <>
      {deleteCommentStatus === ActionStatus.Idle && deletedCommentId === commentId && (
        <Stack
          gap={2}
          sx={{
            padding: 0,
            alignItems: 'stretch',
            m: 0,
            borderRadius: '8px',
            flexDirection: 'row'
          }}
        >
          <Skeleton width={36} height={36} />
          <Stack gap={2}>
            <RowCenterStack gap={1}>
              <Skeleton width={200} height={15} />
              <Skeleton width={70} height={15} />
              <Skeleton width={64} height={15} />
            </RowCenterStack>
          </Stack>
        </Stack>
      )}
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'flex-start',
          padding: 0,
          m: 0,
          borderRadius: '8px',
          py: 1
        }}
        key={commentId}
      >
        {author === undefined ? (
          <Skeleton animation="wave" variant="rectangular" width={46} height={46} />
        ) : (
          <IconButton onClick={() => handleEmployeeDrawerOpen(authorId)} sx={{ padding: 0 }}>
            <Avatar
              sx={{
                width: 36,
                height: 36,
                borderRadius: 2,
                border: '1px solid',
                borderColor: (theme) => theme.palette.common.black
              }}
              src={pictureUrl(author.profilePictureUrl, profileAndCoverPictureSasToken, PictureSizeSuffix.sm)}
              alt={author.name}
            >
              {initials(author.name)}
            </Avatar>
          </IconButton>
        )}
        <RowCenterStack
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'flex-end'
          }}
        >
          <Stack
            sx={{
              padding: '0 8px',
              borerRadius: '16px',
              position: 'relative',
              alignItems: 'flex-start',
              width: '100%'
            }}
          >
            <RowCenterStack
              gap={1}
              sx={{
                alignItems: 'center'
              }}
            >
              {author === undefined ? (
                <Skeleton animation="wave" height={20} width={200} />
              ) : (
                <Typography sx={{ cursor: 'pointer', fontWeight: 700 }} onClick={() => handleEmployeeDrawerOpen(authorId)}>
                  {author.name}
                </Typography>
              )}
              {!editedTime && (
                <RowCenterStack gap={1}>
                  <CircleIcon size={4} color={'#251D3873'} />
                  <Typography component="span" variant="caption" sx={{ lineHeight: 'normal', color: (theme) => alpha(theme.palette.primary.main, 0.45) }}>
                    {createdTime.toRelative({ locale })}
                  </Typography>
                </RowCenterStack>
              )}
              {editedTime && (
                <RowCenterStack gap={1}>
                  <CircleIcon size={4} color={'#251D3873'} />
                  <Typography component="span" variant="caption" sx={{ lineHeight: 'normal', color: (theme) => alpha(theme.palette.primary.main, 0.45) }}>
                    {editedTime.toRelative({ locale })}
                  </Typography>
                  <CircleIcon size={4} color={'#251D3873'} />
                  <Typography component="span" variant="caption" sx={{ lineHeight: 'normal', color: (theme) => alpha(theme.palette.primary.main, 0.45) }}>
                    {t('edited', { ns: 'homepage' })}
                  </Typography>
                </RowCenterStack>
              )}
            </RowCenterStack>
            {isEditable && (
              <Stack
                onKeyDown={(event) => {
                  if (event.key === 'Escape') {
                    handleSetCommentEditable(commentId);
                  }
                  if (event.key === 'Enter' && event.shiftKey) {
                    handleCommentEdit();
                  }
                }}
                sx={{
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                  position: 'relative',
                  borderRadius: '7px',
                  padding: '12px',
                  bgcolor: (theme) => theme.palette.grey[300],
                  width: '100%',
                  '& .quill': {
                    width: '100%',
                    padding: 0,
                    backgroundColor: 'inherit',
                    flexDirection: 'row-reverse',
                    alignItems: 'flex-end',
                    '& .ql-container': {
                      width: '100%'
                    }
                  },
                  '& .ql-customControl': {
                    opacity: 0.5,
                    mb: '4px'
                  }
                }}
              >
                <QuillEditor emojiOnly text={text} setText={setText} ref={quillRef} audienceId={audienceIdByPostId} />
                <Button variant="contained" color="primary" disabled={text.length === 0} onClick={handleCommentEdit}>
                  <Typography sx={{ color: (theme) => theme.palette.common.white }}>{t('update')}</Typography>
                </Button>
              </Stack>
            )}
            {!isEditable && (
              <Box
                component="div"
                dangerouslySetInnerHTML={rteContent}
                sx={{
                  '& p': {
                    maxWidth: 600,
                    wordWrap: 'break-word',
                    hyphens: 'auto'
                  }
                }}
              />
            )}
          </Stack>
          {!isEditable && (
            <RowCenterStack gap={1}>
              {keys(commentReactionsGroupedByEmoji).length > 0 && <CommentReactions postId={postId} commentId={commentId} />}
              <Box
                sx={{
                  width: 60
                }}
              >
                <EmojiPicker onEmojiSelect={handleEmojiSelect} />
              </Box>
              <PostCommentMenu postId={postId} commentId={commentId} authorId={authorId} handleSetCommentEditable={handleSetCommentEditable} />
            </RowCenterStack>
          )}
        </RowCenterStack>
      </Stack>
    </>
  );
};
