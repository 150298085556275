import { PostComment, selectComments, selectPostById } from '@features/homepage';
import { Box, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { retrieveEmployeeBasicInfoRequested } from '@features/admin-settings';
import { RetrieveEmployeeBasicInfoRequest } from '@thrivea/organization-client';
import { StyledTransparentButton } from '@/shared';
import { useTranslation } from 'react-i18next';

interface CommentListProps {
  postId: string;
}

export const CommentList: React.FC<CommentListProps> = ({ postId }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'homepage']);
  const comments = useAppSelector(selectComments);
  const [editableComment, setEditableComment] = useState<string | null>(null);
  const [visibleCommentsCount, setVisibleCommentsCount] = useState(1);
  const [areCommentsExpanded, setAreCommentsExpanded] = useState(false);
  const post = useAppSelector((state) => selectPostById(state, postId));

  useEffect(() => {
    if (visibleCommentsCount == 1) return;

    setVisibleCommentsCount(post.commentIds.length);
  }, [post.commentIds.length]);

  const handleSetCommentEditable = (id: string) => {
    setEditableComment(editableComment === id ? null : id);
  };

  const handleViewMoreComments = () => {
    setVisibleCommentsCount(post.commentIds.length);
    setAreCommentsExpanded(true);
  };

  const handleViewLessComments = () => {
    setVisibleCommentsCount(1);
    setAreCommentsExpanded(false);
  };

  useEffect(() => {
    const mentions = document.querySelectorAll('.mention');

    mentions.forEach((mention) => {
      mention.addEventListener('click', (e) => {
        e.stopPropagation();
        e.stopImmediatePropagation();

        if (e.currentTarget instanceof Element) {
          const employeeId = e.currentTarget.getAttribute('data-id')!;

          dispatch(
            retrieveEmployeeBasicInfoRequested(
              new RetrieveEmployeeBasicInfoRequest({
                employeeId
              })
            )
          );
        }
      });
    });
  }, [comments]);

  return (
    <Stack
      sx={{
        padding: post.commentIds.length > 0 ? '8px 16px' : '0'
      }}
    >
      {post.commentIds
        .slice(0, visibleCommentsCount)
        .map((commentId) => comments.byId[commentId])
        .map((comment) => (
          <PostComment
            key={comment.id}
            postId={post.id}
            authorId={comment.authorId}
            commentId={comment.id}
            message={comment.text}
            createdTime={comment.commentTime}
            editedTime={comment.editedTime}
            handleSetCommentEditable={handleSetCommentEditable}
            isEditable={editableComment === comment.id}
          />
        ))}
      {post.commentIds.length > 1 && (
        <Box>
          {!areCommentsExpanded ? (
            <StyledTransparentButton
              variant="text"
              sx={{
                color: (theme) => theme.palette.action.focus,
                fontWeight: 700
              }}
              onClick={handleViewMoreComments}
            >
              {t('see_comments', { ns: 'homepage' })} {t('more_comment_with_count', { ns: 'homepage', count: post.commentIds.length - visibleCommentsCount })}
            </StyledTransparentButton>
          ) : (
            <StyledTransparentButton
              variant="text"
              sx={{
                color: (theme) => theme.palette.action.focus,
                fontWeight: 700
              }}
              onClick={handleViewLessComments}
            >
              {t('see_less_comments', { ns: 'homepage' })}
            </StyledTransparentButton>
          )}
        </Box>
      )}
    </Stack>
  );
};
