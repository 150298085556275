import { ForwardedRef, forwardRef, useEffect, useId, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  ProfileGenericCategoryProps,
  createPersonalContactSchema,
  selectPersonalContactDetailsCategory,
  selectProfileNotification,
  updatePersonalContactDetailsRequested
} from '@features/employee-profile';
import { Controller, useForm } from 'react-hook-form';
import { PersonalContactDetails, UpdatePersonalContactDetailsRequest } from '@thrivea/organization-client';
import { Box, CircularProgress, Grid, IconButton, Stack, Typography } from '@mui/material';
import {
  RowCenterStack,
  VisuallyHidden,
  EditIcon,
  CancelEditIcon,
  StyledFormWrapper,
  StyledFormHeader,
  StyledFilledInput,
  StyledCancelButton,
  StyledFormSubmitButton,
  ActionStatus
} from '@/shared';
import { selectCurrentEmployeeId } from '@app/user';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { snakeCase } from 'lodash';
import { AllowedTo } from 'react-abac';
import { GroupPermissions, ProfileCategoryInfo } from '@features/abac';

interface PersonalContactDetailsCategoryProps {
  ref: ForwardedRef<HTMLDivElement>;
  employeeId: string;
}

export const PersonalContactDetailsCategory = forwardRef<HTMLDivElement, PersonalContactDetailsCategoryProps>(({ employeeId }, ref) => {
  const { t } = useTranslation(['common', 'employee_profile']);
  const name = t('personal_contact_details', { ns: 'employee_profile' });
  const dispatch = useAppDispatch();
  const id = useId();
  const personalContactDetails = useAppSelector(selectPersonalContactDetailsCategory);
  const { status, category } = useAppSelector(selectProfileNotification);
  const isPendingAndMatchingName = status === ActionStatus.Pending && category === 'personalContactDetails';
  const [isEditable, setIsEditable] = useState(false);
  const personalContactDetailsSchema = useMemo(() => createPersonalContactSchema(t), [t]);

  const {
    formState: { dirtyFields, errors },
    control,
    reset,
    handleSubmit
  } = useForm<PersonalContactDetails>({
    mode: 'all',
    resolver: zodResolver(personalContactDetailsSchema),
    defaultValues: {
      personalEmailAddress: personalContactDetails.personalEmailAddress,
      personalLandlinePhoneNumber: personalContactDetails.personalLandlinePhoneNumber,
      personalMobilePhoneNumber: personalContactDetails.personalMobilePhoneNumber
    }
  });

  const handleCloseEditable = () => {
    setIsEditable(false);
    reset({
      personalEmailAddress: personalContactDetails.personalEmailAddress,
      personalLandlinePhoneNumber: personalContactDetails.personalLandlinePhoneNumber,
      personalMobilePhoneNumber: personalContactDetails.personalMobilePhoneNumber
    });
  };

  const handleToggleEditable = () => {
    setIsEditable(!isEditable);
  };

  const onSubmit = (data: PersonalContactDetails) => {
    dispatch(
      updatePersonalContactDetailsRequested({
        employeeId: employeeId,
        personalEmailAddress: data.personalEmailAddress,
        personalMobilePhoneNumber: data.personalMobilePhoneNumber,
        personalLandlinePhoneNumber: data.personalLandlinePhoneNumber
      } as UpdatePersonalContactDetailsRequest)
    );
    setIsEditable(false);
  };

  useEffect(() => {
    reset({
      personalEmailAddress: personalContactDetails.personalEmailAddress,
      personalLandlinePhoneNumber: personalContactDetails.personalLandlinePhoneNumber,
      personalMobilePhoneNumber: personalContactDetails.personalMobilePhoneNumber
    });
  }, [personalContactDetails]);

  return (
    <StyledFormWrapper isEditable={isEditable} id={snakeCase(name)} ref={ref}>
      <Box
        component="form"
        name={name}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          backgroundColor: 'transparent'
        }}
      >
        <Stack>
          <StyledFormHeader
            className="Mui-ProfileFiledHeader"
            isEditable={isEditable}
            sx={{
              backgroundColor: (theme) => (isPendingAndMatchingName ? theme.palette.secondary.main : undefined)
            }}
          >
            <RowCenterStack gap={1}>
              {isPendingAndMatchingName && <CircularProgress size={24} thickness={4} />}
              <Typography component="h3" variant="h5" fontWeight="bold">
                {name}
              </Typography>
            </RowCenterStack>
            <AllowedTo perform={GroupPermissions.EDIT_PROFILE} data={{ employeeId, categoryName: 'personal_contact_details' } as ProfileCategoryInfo}>
              <IconButton
                disableRipple
                onClick={handleToggleEditable}
                sx={{
                  opacity: '0',
                  display: isEditable ? 'none' : 'inline-flex'
                }}
              >
                <VisuallyHidden component="span">Edit {name}</VisuallyHidden>
                <EditIcon />
              </IconButton>
            </AllowedTo>
            <RowCenterStack
              gap={2}
              sx={{
                display: isEditable ? 'flex' : 'none'
              }}
            >
              <StyledCancelButton variant="contained" onClick={handleCloseEditable} startIcon={<CancelEditIcon />}>
                {t('cancel', { ns: 'common' })}
              </StyledCancelButton>
              <StyledFormSubmitButton
                type="submit"
                disabled={Object.values(dirtyFields).length === 0 || Object.values(errors).length !== 0}
                variant="contained"
              >
                {t('done', { ns: 'common' })}
              </StyledFormSubmitButton>
            </RowCenterStack>
          </StyledFormHeader>
          <Grid
            container
            spacing={2}
            sx={{
              padding: 2
            }}
          >
            <Grid item xs={12} sm={6} lg={4}>
              <Controller
                name="personalEmailAddress"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    type="email"
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    id={id + field.name}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => field.onChange(e)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Controller
                name="personalLandlinePhoneNumber"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    id={id + field.name}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => {
                      const value = e.target.value;
                      const regex = /^[0-9+-]*$/;
                      if (regex.test(value) || value === '') {
                        field.onChange(value);
                      }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Controller
                name="personalMobilePhoneNumber"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    id={id + field.name}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => {
                      const value = e.target.value;
                      const regex = /^[0-9+-]*$/;
                      if (regex.test(value) || value === '') {
                        field.onChange(value);
                      }
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </StyledFormWrapper>
  );
});
