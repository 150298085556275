import { Box } from '@mui/system';

export const LoaderComponent = () => {
  return (
    <Box
      sx={{
        opacity: 1,
        transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        right: 0,
        left: 0,
        bottom: 0,
        top: 0,
        backgroundColor: 4095,
        zIndex: 1201
      }}
    >
      <img src="/images/loader.gif" alt="loader" width="210" height="210" />
    </Box>
  );
};
