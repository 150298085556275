import { Typography, Grid, IconButton, CircularProgress } from '@mui/material';
import { Stack, Box } from '@mui/system';
import {
  RowCenterStack,
  VisuallyHidden,
  EditIcon,
  CancelEditIcon,
  StyledFormWrapper,
  StyledFormHeader,
  StyledCancelButton,
  StyledFormSubmitButton,
  StyledFilledInput,
  ActionStatus
} from '@/shared';
import { ForwardedRef, forwardRef, useEffect, useId, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppSelector } from '@app/hooks';
import { Payroll, UpdatePayrollRequest } from '@thrivea/organization-client';
import {
  removeNewPayrollEvents,
  selectPayrollCategory,
  selectPayrollCategoryTableData,
  selectProfileNotification,
  updatePayrollRequested,
  PayrollCategoryTable,
  createPayrollCategorySchema,
  selectNewPayrollEvents
} from '@features/employee-profile';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { camelCase, snakeCase } from 'lodash';
import { AllowedTo } from 'react-abac';
import { GroupPermissions, ProfileCategoryInfo } from '@features/abac';

interface PayrollCategoryProps {
  ref: ForwardedRef<HTMLDivElement>;
  employeeId: string;
}

export const PayrollCategory = forwardRef<HTMLDivElement, PayrollCategoryProps>(({ employeeId }, ref) => {
  const id = useId();
  const { t } = useTranslation(['common', 'employee_profile']);
  const name = t('payroll_category_title', { ns: 'employee_profile' });
  const dispatch = useDispatch();
  const payrollCategory = useAppSelector(selectPayrollCategory);
  const payrollCategoryTableData = useAppSelector(selectPayrollCategoryTableData);
  const { status, category } = useAppSelector(selectProfileNotification);
  const isPendingAndMatchingName = status === ActionStatus.Pending && category === camelCase(name);
  const [isEditable, setIsEditable] = useState(false);
  const payrollCategorySchema = useMemo(() => createPayrollCategorySchema(t), [t]);
  const newPayrollEvents = useAppSelector(selectNewPayrollEvents);

  const {
    formState: { dirtyFields, errors },
    control,
    reset,
    setValue,
    handleSubmit
  } = useForm<Payroll>({
    mode: 'all',
    resolver: zodResolver(payrollCategorySchema),
    defaultValues: {
      additionalPensionContribution: payrollCategory.additionalPensionContribution,
      taxCode: payrollCategory.taxCode,
      payrollEvents: payrollCategory.payrollEvents
    }
  });

  const onSubmit = (data: Payroll) => {
    setIsEditable(false);
    dispatch(
      updatePayrollRequested({
        ...data,
        employeeId,
        payrollEvents: payrollCategoryTableData
      } as UpdatePayrollRequest)
    );
  };

  const handleToggleEditable = () => {
    setIsEditable(!isEditable);
  };

  const handleSetEditable = () => {
    setIsEditable(true);
  };

  const handleCloseEditable = () => {
    setIsEditable(false);
    dispatch(removeNewPayrollEvents());
    reset({
      additionalPensionContribution: payrollCategory.additionalPensionContribution,
      taxCode: payrollCategory.taxCode,
      payrollEvents: payrollCategory.payrollEvents
    });
  };

  useEffect(() => {
    reset({
      additionalPensionContribution: payrollCategory.additionalPensionContribution,
      taxCode: payrollCategory.taxCode,
      payrollEvents: payrollCategory.payrollEvents
    });
  }, [payrollCategory]);

  useEffect(() => {
    if (newPayrollEvents.length > 0) {
      setValue('payrollEvents', payrollCategory.payrollEvents, { shouldDirty: true });
    }
  }, [newPayrollEvents]);

  return (
    <StyledFormWrapper isEditable={isEditable} id={snakeCase(name)} ref={ref}>
      <Box
        component="form"
        name={name}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          backgroundColor: 'transparent'
        }}
      >
        <Stack>
          <StyledFormHeader isEditable={isEditable} className="Mui-ProfileFiledHeader">
            <RowCenterStack gap={1}>
              {isPendingAndMatchingName && <CircularProgress size={24} thickness={4} />}
              <Typography component="h3" variant="h5" fontWeight="bold">
                {name}
              </Typography>
            </RowCenterStack>
            <AllowedTo perform={GroupPermissions.EDIT_PROFILE} data={{ employeeId, categoryName: 'payroll' } as ProfileCategoryInfo}>
              <IconButton
                disableRipple
                onClick={handleToggleEditable}
                sx={{
                  opacity: '0',
                  display: isEditable ? 'none' : 'inline-flex'
                }}
              >
                <VisuallyHidden component="span">Edit {name}</VisuallyHidden>
                <EditIcon />
              </IconButton>
            </AllowedTo>
            <RowCenterStack
              gap={2}
              sx={{
                display: isEditable ? 'flex' : 'none'
              }}
            >
              <StyledCancelButton variant="contained" onClick={handleCloseEditable} startIcon={<CancelEditIcon />}>
                {t('cancel', { ns: 'common' })}
              </StyledCancelButton>
              <StyledFormSubmitButton
                type="submit"
                disabled={Object.values(dirtyFields).length === 0 || Object.values(errors).length !== 0}
                variant="contained"
              >
                {t('done', { ns: 'common' })}
              </StyledFormSubmitButton>
            </RowCenterStack>
          </StyledFormHeader>

          <Grid
            container
            spacing={2}
            sx={{
              padding: 2
            }}
          >
            <Grid item xs={12} sm={6} lg={4}>
              <Controller
                name="additionalPensionContribution"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Controller
                name="taxCode"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>
      <PayrollCategoryTable employeeId={employeeId} handleSetEditable={handleSetEditable} />
    </StyledFormWrapper>
  );
});
