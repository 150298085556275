import { Alert, Grid, Snackbar, Typography } from '@mui/material';
import {
  PostView,
  closeNewPostNotification,
  retrievePostsRequested,
  selectAudiencesById,
  selectCurrentPostsPage,
  selectHasMorePosts,
  selectPostIds,
  selectPostReadSasToken,
  selectPosts,
  selectIsNewPostPublished
} from '@features/homepage';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { init } from 'emoji-mart';
import data from '@emoji-mart/data/sets/14/apple.json';
import { useCallback, useEffect, useRef, useState } from 'react';
import { RetrievePostsRequest } from '@thrivea/networking-client';
import { AlertIcon, RowCenterStack } from '@/shared';
import { retrieveEmployeeBasicInfoRequested } from '@features/admin-settings';
import { RetrieveEmployeeBasicInfoRequest } from '@thrivea/organization-client';

// init emoji mart data
init({ data });

export const PostFeed = () => {
  const { t } = useTranslation(['homepage']);
  const dispatch = useAppDispatch();
  const postIds = useAppSelector(selectPostIds);
  const posts = useAppSelector(selectPosts);
  const isNewPostPublished = useAppSelector<boolean>(selectIsNewPostPublished);
  const postAudiencesById = useAppSelector(selectAudiencesById);
  const postReadSasToken = useAppSelector(selectPostReadSasToken);
  const observer = useRef<IntersectionObserver>();
  const bottomElementRef = useRef<HTMLDivElement>(null);

  const hasMore = useAppSelector<boolean>(selectHasMorePosts);
  const pageNumber = useAppSelector<number>(selectCurrentPostsPage);
  const [hasRetrievedAllPosts, setHasRetrievedAllPosts] = useState<boolean>(false);

  const handleScroll = useCallback(() => {
    if (hasMore) {
      dispatch(retrievePostsRequested(new RetrievePostsRequest({ pageNumber: pageNumber + 1, pageSize: 10 })));
    } else {
      setHasRetrievedAllPosts(true);
    }
  }, [hasMore, pageNumber]);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          handleScroll();
        }
      },
      { threshold: 1 } // Trigger the observer when the target is 100% visible
    );

    // Start observing the target element
    if (bottomElementRef.current && observer.current) {
      observer.current.observe(bottomElementRef.current);
    }

    return () => {
      // Clean up the observer and scroll event listener when the component unmounts
      if (observer.current && bottomElementRef.current) {
        observer.current.disconnect();
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    const mentions = document.querySelectorAll('.mention');

    mentions.forEach((mention) => {
      mention.addEventListener('click', (e) => {
        e.stopPropagation();
        e.stopImmediatePropagation();
        if (e.currentTarget instanceof Element) {
          const employeeId = e.currentTarget.getAttribute('data-id')!;
          dispatch(
            retrieveEmployeeBasicInfoRequested(
              new RetrieveEmployeeBasicInfoRequest({
                employeeId
              })
            )
          );
        }
      });
    });
  }, [postIds]);

  const handleNewPostNotificationClose = () => {
    dispatch(closeNewPostNotification());
  };

  return (
    <>
      <Snackbar
        open={isNewPostPublished}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message="New post arrived"
        autoHideDuration={3000}
        sx={{
          width: 'calc(100% - 32px)',
          borderRadius: (theme) => theme.spacing(2),
          backgroundColor: (theme) => theme.palette.secondary.main,
          color: (theme) => theme.palette.common.white,
          '& svg': {
            color: (theme) => theme.palette.common.white
          }
        }}
      >
        <Alert
          onClose={handleNewPostNotificationClose}
          severity="success"
          sx={{ width: '100%', display: 'flex', alignItems: 'center', color: (theme) => theme.palette.common.white }}
        >
          <Typography fontWeight="700">{t('post_created', { ns: 'homepage' })}</Typography>
          <Typography>{t('new_post_created', { ns: 'homepage' })}</Typography>
        </Alert>
      </Snackbar>

      <Grid
        container
        gap={'1rem 0'}
        sx={{
          position: 'relative'
        }}
      >
        {postIds.length === 0 && (
          <RowCenterStack gap={1} sx={{ padding: 2, width: '100%' }}>
            <AlertIcon />
            <Typography fontWeight={600}>{t('blank_posts', { ns: 'homepage' })}</Typography>
          </RowCenterStack>
        )}
        {postIds.map((id) => {
          const post = posts[id];
          return (
            <Grid item xs={12} key={post.id}>
              {post.case === 'shoutout' && (
                <PostView
                  postId={post.id}
                  date={post.publishTime}
                  authorId={post.authorId}
                  message={post.text}
                  files={post.mediaUrls.map((mu) => ({
                    alt: 'Media',
                    src: new URL(postReadSasToken, mu).toString(),
                    name: mu
                  }))}
                  docs={post.docUrls.map((du) => ({
                    alt: 'Document',
                    src: new URL(postReadSasToken, du).toString(),
                    name: du
                  }))}
                  audience={postAudiencesById[posts[id].audienceIds[0]]}
                  editedTime={post.editedTime}
                />
              )}
            </Grid>
          );
        })}
        {hasRetrievedAllPosts && postIds.length > 0 && (
          <RowCenterStack gap={1} sx={{ padding: 2, width: '100%' }}>
            <AlertIcon />
            <Typography fontWeight={600}>{t('all_posts_retrieved', { ns: 'homepage' })}</Typography>
          </RowCenterStack>
        )}
      </Grid>
      <div ref={bottomElementRef} style={{ width: '100%', height: '50px' }}></div>
    </>
  );
};
