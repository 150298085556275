import { ListItem, ListItemButton, ListItemIcon, Typography, ListItemText, useMediaQuery } from '@mui/material';
import { Stack } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { SettingsIcon } from '@/shared';

interface NavSettingsListItemProps {
  isDrawerOpened: boolean;
  isDisabled: boolean;
}

export const NavSettingsListItem: React.FC<NavSettingsListItemProps> = ({ isDrawerOpened, isDisabled }) => {
  const isBelowLgHeight = useMediaQuery('(max-height: 850px)');
  const isBelowMdHeight = useMediaQuery('(max-height: 700px)');

  return (
    <ListItem
      sx={{
        padding: 0
      }}
    >
      <ListItemButton
        disableGutters
        disabled={isDisabled}
        component={NavLink}
        to="/settings"
        sx={{
          padding: 0,
          justifyContent: 'center',
          '&.active': {
            color: (theme) => theme.palette.customTheme.focusItem,
            '& path': {
              fill: (theme) => theme.palette.customTheme.focusItem
            }
          },
          '&:hover': {
            '& path': {
              fill: (theme) => theme.palette.secondary.main
            },
            '& .MuiLinkText': {
              transform: 'translateY(3px)',
              opacity: 1,
              color: (theme) => theme.palette.secondary.main
            },
            '& svg': {
              width: isBelowMdHeight ? 20 : 'auto',
              height: isBelowMdHeight ? 20 : 'auto'
            }
          }
        }}
      >
        <Stack
          gap={1}
          sx={{
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <ListItemIcon
            className="MuiLinkIcon"
            sx={{
              minWidth: 'auto',
              width: 24,
              height: 29,
              marginRight: isDrawerOpened ? '12px' : 0,
              '& path': {
                fill: (theme) => theme.palette.common.black
              }
            }}
          >
            <SettingsIcon />
          </ListItemIcon>
          <Typography
            className="MuiLinkText"
            variant="body2"
            sx={{
              opacity: isBelowLgHeight ? 0 : 1,
              transform: 'translateY(0)',
              transition: (theme) =>
                theme.transitions.create(['color', 'transform', 'width', 'height'], {
                  easing: theme.transitions.easing.easeInOut,
                  duration: theme.transitions.duration.standard
                })
            }}
          >
            Settings
          </Typography>
        </Stack>
        {isDrawerOpened && (
          <ListItemText
            primary={'Settings'}
            sx={{
              opacity: isDrawerOpened ? '1' : '0',
              visibility: isDrawerOpened ? 'visible' : 'hidden',
              transition: (theme) =>
                theme.transitions.create(['opacity', 'visibility'], {
                  easing: theme.transitions.easing.easeInOut,
                  duration: theme.transitions.duration.standard
                })
            }}
          />
        )}
      </ListItemButton>
    </ListItem>
  );
};
