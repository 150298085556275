import { ForwardedRef, forwardRef, useEffect, useId, useMemo, useState } from 'react';
import { useAppSelector } from '@app/hooks';
import {
  removeChildItems,
  selectGenders,
  selectHomeCategory,
  selectHomeCategoryTableData,
  selectNewChildItems,
  updateHomeRequested,
  HomeCategoryTable,
  createHomeSchema,
  selectProfileNotification
} from '@features/employee-profile';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Box, CircularProgress, FormControl, Grid, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import {
  RowCenterStack,
  VisuallyHidden,
  EditIcon,
  CancelEditIcon,
  ChevronDownIcon,
  BootstrapSelect,
  StyledFormWrapper,
  StyledFormHeader,
  StyledCancelButton,
  StyledFormSubmitButton,
  StyledFormLabel,
  StyledFilledInput,
  StyledDatePickerFilledInput,
  ActionStatus
} from '@/shared';
import { Home, MaritalStatus, UpdateHomeRequest } from '@thrivea/organization-client';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { zodResolver } from '@hookform/resolvers/zod';
import { snakeCase } from 'lodash';

interface HomeCategoryProps {
  ref: ForwardedRef<HTMLDivElement>;
  employeeId: string;
}

export const HomeCategory = forwardRef<HTMLDivElement, HomeCategoryProps>(({ employeeId }, ref) => {
  const id = useId();
  const { t } = useTranslation(['employee_profile', 'marital_status']);
  const name = t('home', { ns: 'employee_profile' });
  const dispatch = useDispatch();
  const genders = useAppSelector(selectGenders);
  const homeCategory = useAppSelector(selectHomeCategory);
  const homeCategoryTableData = useAppSelector(selectHomeCategoryTableData);
  const newChildItems = useAppSelector(selectNewChildItems);
  const { status, category } = useAppSelector(selectProfileNotification);
  const isPendingAndMatchingName = status === ActionStatus.Pending && category === 'home';
  const [isEditable, setIsEditable] = useState(false);
  const homeSchema = useMemo(() => createHomeSchema(t), [t]);
  const {
    formState: { dirtyFields, errors },
    control,
    getValues,
    handleSubmit,
    watch,
    reset,
    setValue
  } = useForm<Home>({
    mode: 'all',
    resolver: zodResolver(homeSchema),
    defaultValues: {
      maritalStatus: homeCategory.maritalStatus,
      genderId: homeCategory.genderId,
      spouseFirstName: homeCategory.spouseFirstName,
      spouseLastName: homeCategory.spouseLastName,
      spouseDateOfBirth: homeCategory.spouseDateOfBirth,
      spouseGenderId: homeCategory.spouseGenderId,
      childItems: homeCategory.childItems
    }
  });

  const dateOfBirth = watch('spouseDateOfBirth');

  const martialStatus = Object.keys(MaritalStatus)
    .filter((key) => !isNaN(Number(key)))
    .slice(1) // remove first option
    .map((key) => ({
      id: parseInt(key, 10) - 1, //deduct by 1 as we are removing first option
      name: MaritalStatus[Number(key)].toLowerCase()
    }));

  const handleCloseEditable = () => {
    setIsEditable(false);
    dispatch(removeChildItems());
    reset({
      maritalStatus: homeCategory.maritalStatus,
      genderId: homeCategory.genderId,
      spouseFirstName: homeCategory.spouseFirstName,
      spouseLastName: homeCategory.spouseLastName,
      spouseDateOfBirth: homeCategory.spouseDateOfBirth,
      spouseGenderId: homeCategory.spouseGenderId,
      childItems: homeCategory.childItems
    });
  };

  const handleToggleEditable = () => {
    setIsEditable(!isEditable);
  };

  const handleSetEditable = () => {
    setIsEditable(true);
  };

  const handleFormSubmit = (data: Home) => {
    dispatch(
      updateHomeRequested({
        employeeId: employeeId,
        maritalStatus: data.maritalStatus,
        genderId: data.genderId,
        spouseFirstName: data.spouseFirstName,
        spouseLastName: data.spouseLastName,
        spouseDateOfBirth: data.spouseDateOfBirth,
        spouseGenderId: data.spouseGenderId,
        childItems: homeCategory?.childItems
      } as UpdateHomeRequest)
    );
    setIsEditable(false);
  };

  useEffect(() => {
    reset({
      maritalStatus: homeCategory.maritalStatus,
      genderId: homeCategory.genderId,
      spouseFirstName: homeCategory.spouseFirstName,
      spouseLastName: homeCategory.spouseLastName,
      spouseDateOfBirth: homeCategory.spouseDateOfBirth,
      spouseGenderId: homeCategory.spouseGenderId
    });
  }, [homeCategory]);

  useEffect(() => {
    // Check if newEvents exists which means new event is added and set isDirty to true
    if (newChildItems.length > 0) {
      setValue('childItems', homeCategory.childItems, { shouldDirty: true });
    }
  }, [newChildItems]);

  return (
    <StyledFormWrapper isEditable={isEditable} id={snakeCase(name)} ref={ref}>
      <Box
        component="form"
        name={name}
        onSubmit={handleSubmit(handleFormSubmit)}
        sx={{
          backgroundColor: 'transparent'
        }}
      >
        <Stack>
          <StyledFormHeader isEditable={isEditable} className="Mui-ProfileFiledHeader">
            <RowCenterStack gap={1}>
              {isPendingAndMatchingName && <CircularProgress size={24} thickness={4} />}
              <Typography component="h3" variant="h5" fontWeight="bold">
                {name}
              </Typography>
            </RowCenterStack>
            <IconButton
              className="Mui-Edit"
              disableRipple
              onClick={handleToggleEditable}
              sx={{
                opacity: '0',
                display: isEditable ? 'none' : 'inline-flex'
              }}
            >
              <VisuallyHidden component="span">Edit {name}</VisuallyHidden>
              <EditIcon />
            </IconButton>
            <RowCenterStack
              gap={2}
              sx={{
                display: isEditable ? 'flex' : 'none'
              }}
            >
              <StyledCancelButton variant="contained" onClick={handleCloseEditable} startIcon={<CancelEditIcon />}>
                {t('cancel', { ns: 'common' })}
              </StyledCancelButton>
              <StyledFormSubmitButton
                type="submit"
                disabled={Object.values(dirtyFields).length === 0 || Object.values(errors).length !== 0}
                variant="contained"
              >
                {t('done', { ns: 'common' })}
              </StyledFormSubmitButton>
            </RowCenterStack>
          </StyledFormHeader>
          <Grid
            container
            spacing={2}
            sx={{
              padding: 2
            }}
          >
            <Grid item xs={12} sm={6} lg={4}>
              <Controller
                name="maritalStatus"
                control={control}
                render={({ field }) => (
                  <FormControl
                    fullWidth
                    sx={{
                      position: 'relative'
                    }}
                  >
                    <StyledFormLabel shrink disabled={!isEditable} htmlFor="martial_status">
                      {t('marital_status', { ns: 'employee_profile' })}
                    </StyledFormLabel>
                    <BootstrapSelect
                      {...field}
                      value={getValues('maritalStatus') ?? 'none'}
                      disabled={!isEditable}
                      inputProps={{ id: 'martial_status' }}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      IconComponent={() => {
                        return (
                          <Box className="MuiSvg-icon">
                            <ChevronDownIcon />
                          </Box>
                        );
                      }}
                    >
                      <MenuItem value="none" disabled>
                        Select
                      </MenuItem>
                      {martialStatus &&
                        martialStatus.map((ms) => (
                          <MenuItem key={ms.id} value={ms.id}>
                            {t(ms.name, { ns: 'marital_status' })}
                          </MenuItem>
                        ))}
                    </BootstrapSelect>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Controller
                name="genderId"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <StyledFormLabel shrink disabled={!isEditable} htmlFor="gender">
                      {t('gender', { ns: 'employee_profile' })}
                    </StyledFormLabel>
                    <BootstrapSelect
                      {...field}
                      value={getValues('genderId') ?? 'none'}
                      disabled={!isEditable}
                      inputProps={{ id: 'gender' }}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      IconComponent={() => {
                        return (
                          <Box className="MuiSvg-icon">
                            <ChevronDownIcon />
                          </Box>
                        );
                      }}
                    >
                      <MenuItem value="none" disabled>
                        Select
                      </MenuItem>
                      {genders &&
                        genders.map((g) => (
                          <MenuItem key={g.id} value={g.id}>
                            {g.name}
                          </MenuItem>
                        ))}
                    </BootstrapSelect>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Controller
                name="spouseFirstName"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => field.onChange(e)}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <Controller
                name="spouseLastName"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => field.onChange(e)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <StyledFilledInput
                id="number_of_dependents"
                label={t('number_of_dependents', { ns: 'employee_profile' })}
                value={homeCategoryTableData?.length}
                disabled
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Controller
                name="spouseDateOfBirth"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                      <DatePicker
                        {...field}
                        className="MuiDate-root"
                        format="yyyy-MM-dd"
                        value={field.value ? DateTime.fromISO(field.value) : null}
                        disableFuture
                        disabled={!isEditable}
                        slots={{
                          textField: StyledDatePickerFilledInput
                        }}
                        slotProps={{
                          textField: {
                            id: id + field.name,
                            variant: 'filled',
                            fullWidth: true,
                            placeholder: 'YYYY-MM-DD',
                            label: t(snakeCase(field.name), { ns: 'employee_profile' }),
                            error: !!fieldState.error,
                            helperText: fieldState.error?.message,
                            InputProps: {
                              disableUnderline: true,
                              readOnly: true
                            },
                            InputLabelProps: {
                              shrink: true
                            }
                          }
                        }}
                        onChange={(date: DateTime | null) => {
                          if (date) {
                            const jsDate = date.toJSDate();
                            const stringDate = DateTime.fromJSDate(jsDate).toFormat('yyyy-MM-dd');
                            field.onChange(stringDate);
                          }
                        }}
                        sx={{
                          width: '100%'
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <StyledFilledInput
                id="birthday"
                label={t('spouse_birthday', { ns: 'employee_profile' })}
                value={dateOfBirth ? DateTime.fromISO(dateOfBirth).toLocaleString({ month: 'long', day: 'numeric' }) : ''}
                disabled
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Controller
                name="spouseGenderId"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <StyledFormLabel shrink disabled={!isEditable} htmlFor={id + field.name}>
                      {t(snakeCase(field.name), { ns: 'employee_profile' })}
                    </StyledFormLabel>
                    <BootstrapSelect
                      {...field}
                      value={getValues('spouseGenderId') ?? 'none'}
                      disabled={!isEditable}
                      inputProps={{ id: id + field.name }}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      IconComponent={() => {
                        return (
                          <Box className="MuiSvg-icon">
                            <ChevronDownIcon />
                          </Box>
                        );
                      }}
                      variant="outlined"
                      sx={{
                        '.MuiSelect-select.MuiSelect-outlined.Mui-disabled MuiInputBase-input.MuiOutlinedInput-input': {
                          padding: '10px 0'
                        }
                      }}
                    >
                      <MenuItem value="none" disabled>
                        Select
                      </MenuItem>
                      {genders &&
                        genders.map((g) => (
                          <MenuItem key={g.id} value={g.id}>
                            {g.name}
                          </MenuItem>
                        ))}
                    </BootstrapSelect>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>
      <HomeCategoryTable handleSetEditable={handleSetEditable} />
    </StyledFormWrapper>
  );
});
