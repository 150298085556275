import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ActionStatus,
  AddIcon,
  AutoCompleteInput,
  ChevronDownIcon,
  EditPenIcon,
  RowCenterStack,
  StyledEditButton,
  PreviewSiteAdminOnboardingImageModal,
  StyledTransparentInput
} from '@/shared';
import { FormControl, Autocomplete, InputAdornment, Stack, Grid, Typography, Box, Button, Skeleton, CircularProgress, ListItem } from '@mui/material';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash/debounce';
import { FileUploader } from 'react-drag-drop-files';
import { useAppSelector, useDebounceValidate } from '@app/hooks';
import { UpsertSiteDetailsStepRequest } from '@thrivea/organization-client';
import {
  selectSiteDetailsStep,
  upsertSiteDetailsStepRequested,
  selectAdminOnboardingFlowStatus,
  selectIssSitesSiteDetailsStepFinished,
  selectIsSitesLocalizationStepFinished,
  selectSiteDetailsStepStatus,
  selectSiteMediaFileWithStatus,
  retrieveSiteWriteSasUriRequested,
  uploadSiteMediaRequested,
  retrieveSiteReadSasTokenRequested
} from '@features/admin-onboarding';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import * as Sentry from '@sentry/react';
import { IMAGE_FILE_TYPES } from 'src/utils';

countries.registerLocale(enLocale);

const searchAddress = async (
  query: string,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setLocations: React.Dispatch<React.SetStateAction<any[]>>
) => {
  setIsLoading(true);

  if (query === '') {
    setLocations([]);
  }

  if (query.length < 3) {
    setIsLoading(false);
    return;
  }

  try {
    const response = await axios.get(`https://atlas.microsoft.com/search/address/json`, {
      params: {
        'api-version': '1.0',
        'subscription-key': import.meta.env.VITE_AZURE_MAPS_KEY,
        query: query,
        limit: 10,
        language: 'en-US'
      }
    });
    setIsLoading(false);
    setLocations(response.data.results);
  } catch (error) {
    Sentry.captureException(error);
    setIsLoading(false);
    setLocations([]);
  }
};

interface AdminOnboardingSiteDetailsProps {
  isEditable: boolean;
  handleSetActiveSubStep: (stepName: string) => (isExpanded: boolean) => void;
}

export const AdminOnboardingSiteDetails: React.FC<AdminOnboardingSiteDetailsProps> = ({ isEditable, handleSetActiveSubStep }) => {
  const { t } = useTranslation(['onboarding', 'common']);
  const dispatch = useDispatch();
  const siteDetails = useAppSelector(selectSiteDetailsStep);
  const siteDetailsStepStatus = useAppSelector(selectSiteDetailsStepStatus);
  const isStepCompleted = useAppSelector(selectIssSitesSiteDetailsStepFinished);
  const isNextStepCompleted = useAppSelector(selectIsSitesLocalizationStepFinished);
  const adminOnboardingFlowStatus = useAppSelector(selectAdminOnboardingFlowStatus);
  const siteMedia = useAppSelector(selectSiteMediaFileWithStatus);

  const [isLoading, setIsLoading] = useState(false);
  const [displayAddress, setDisplayAddress] = useState('');
  const [fetchAddress, setFetchAddress] = useState('');
  const [locations, setLocations] = useState<any[]>([]);
  const [allCountries] = useState(Object.entries(countries.getNames('en')).map(([code, name]) => ({ id: code, label: name })));
  const { control, trigger, handleSubmit, setValue, getValues, reset, formState } = useForm<UpsertSiteDetailsStepRequest>({
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      location: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        province: '',
        region: '',
        postalCode: '',
        country: ''
      },
      coverImageUrl: ''
    }
  });

  const debounceValidate = useDebounceValidate<UpsertSiteDetailsStepRequest>(trigger, 500);

  const fetchLocations = debounce(searchAddress, 500);

  const handleSiteImageUpload = async (file: File) => {
    // put retrieve sasUri just in saga?
    dispatch(retrieveSiteWriteSasUriRequested());
    dispatch(uploadSiteMediaRequested(file));
  };

  const handleSetEditable = () => {
    handleSetActiveSubStep('Step2_1')(!isEditable);
  };

  useEffect(() => {
    dispatch(retrieveSiteReadSasTokenRequested());
  }, []);

  useEffect(() => {
    fetchLocations(fetchAddress, setIsLoading, setLocations);

    return () => {
      fetchLocations.cancel();
    };
  }, [fetchAddress]);

  useEffect(() => {
    if (siteDetails) {
      reset({
        name: siteDetails?.name,
        location: {
          addressLine1: siteDetails?.location?.addressLine1,
          addressLine2: siteDetails?.location?.addressLine2,
          city: siteDetails?.location?.city,
          state: siteDetails?.location?.state,
          province: siteDetails?.location?.province,
          region: siteDetails?.location?.region,
          postalCode: siteDetails?.location?.postalCode,
          country: siteDetails?.location?.country
        },
        coverImageUrl: siteDetails.coverImageUrl
      });
    }
  }, [siteDetails]);

  if (!isEditable) {
    if (adminOnboardingFlowStatus === ActionStatus.Pending) {
      return (
        <Grid container>
          <Grid item xs={3}>
            <Stack>
              <Skeleton width={180} height={20} />
              <Skeleton width={90} height={10} />
              <Skeleton width={90} height={10} />
              <Skeleton width={90} height={10} />
              <Skeleton width={90} height={10} />
              <Skeleton width={90} height={10} />
              <Skeleton width={90} height={10} />
              <Skeleton width={90} height={10} />
              <Skeleton width={90} height={10} />
              <Skeleton width={90} height={10} />
            </Stack>
          </Grid>
          <Grid item xs={9}>
            <Stack
              sx={{
                alignItems: 'flex-end',
                justifyContent: 'center',
                height: '100%'
              }}
            >
              <Skeleton width={90} height={40} />
            </Stack>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container>
        <Grid item xs={6}>
          <Stack gap={1}>
            <Typography
              variant="subtitle1"
              sx={{
                color: (theme) => theme.palette.primary.dark
              }}
            >
              Site details
            </Typography>
            <Stack>
              <Typography
                sx={{
                  fontWeight: 600
                }}
              >
                {getValues('name')}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600
                }}
              >
                {getValues('location.addressLine1')}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600
                }}
              >
                {getValues('location.addressLine2')}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600
                }}
              >
                {getValues('location.city')}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600
                }}
              >
                {getValues('location.state')}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600
                }}
              >
                {getValues('location.province')}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600
                }}
              >
                {getValues('location.region')}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600
                }}
              >
                {getValues('location.postalCode')}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600
                }}
              >
                {getValues('location.country')}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack
            sx={{
              alignItems: 'flex-end',
              justifyContent: 'center',
              height: '100%'
            }}
          >
            <StyledEditButton variant="contained" startIcon={<EditPenIcon />} isStepCompleted={isStepCompleted} onClick={handleSetEditable}>
              Edit
            </StyledEditButton>
          </Stack>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      rowSpacing={6}
      sx={{
        justifyContent: 'space-between'
      }}
    >
      <Grid item xs={12} lg={3}>
        <Typography variant="subtitle1">Site details</Typography>
        <Typography>Define the company site details</Typography>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Box
          component="form"
          onSubmit={handleSubmit((data) => {
            handleSetEditable();
            handleSetActiveSubStep('Step2_2')(!isNextStepCompleted);

            dispatch(
              upsertSiteDetailsStepRequested(
                new UpsertSiteDetailsStepRequest({
                  name: data.name,
                  coverImageUrl: siteMedia ? siteMedia.url : siteDetails!.coverImageUrl,
                  location: data.location
                })
              )
            );
          })}
        >
          <Stack gap={2}>
            <Controller
              name="name"
              control={control}
              rules={{ required: 'Please enter site name.' }}
              render={({ field }) => (
                <StyledTransparentInput
                  {...field}
                  required
                  label={t('site_details_label.name', { ns: 'onboarding' })}
                  onChange={(e) => {
                    setValue('name', e.target.value);
                    debounceValidate('name');
                  }}
                />
              )}
            />
            <Autocomplete
              freeSolo
              disableClearable
              clearOnBlur
              loading={isLoading}
              inputValue={displayAddress}
              popupIcon={false}
              onInputChange={(_, newInputValue, reason) => {
                setDisplayAddress(newInputValue);
                if (reason === 'input') {
                  setFetchAddress(newInputValue);
                }
                if (reason === 'reset') {
                  setDisplayAddress('');
                }
              }}
              options={locations.map((location) => {
                return location.address.freeformAddress as string;
              })}
              filterOptions={(location) => location}
              onChange={(_, value) => {
                const selectedLocation = locations.find((location) => location.address.freeformAddress === value);
                setFetchAddress('');
                setDisplayAddress(selectedLocation.address.freeformAddress ? selectedLocation.address.freeformAddress : '');
                setValue('location.addressLine1', selectedLocation.address.streetName);
                setValue('location.addressLine2', selectedLocation.address.streetNumber);
                setValue('location.city', selectedLocation.address.localName);
                setValue('location.region', selectedLocation.address.countrySubdivision);
                setValue('location.postalCode', selectedLocation.address.postalCode);
                setValue('location.country', selectedLocation.address.country);
                setValue('location.longitude', selectedLocation.position.lon);
                setValue('location.latitude', selectedLocation.position.lat);
                trigger();
              }}
              renderInput={(params) => (
                <AutoCompleteInput
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                  placeholder="Start typing an address to complete it automatically"
                />
              )}
              sx={{
                '& .MuiInputBase-root': {
                  border: (theme) => `1px solid ${theme.palette.primary.main}`
                },
                '&.MuiAutocomplete-hasPopupIcon .MuiInputBase-root': {
                  padding: '5px 16px 5px 0'
                },
                '&.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root .MuiAutocomplete-input': {
                  paddingLeft: '12px'
                },
                '& .MuiInputBase-root .MuiAutocomplete-endAdornment': {
                  top: 14,
                  right: 16
                },
                '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                  height: '100%',
                  padding: 0
                },
                '& fieldset': {
                  borderWidth: 0,
                  borderColor: 'transparent'
                },
                '& .MuiInputBase-root.Mui-focused': {
                  borderColor: (theme) => `${theme.palette.secondary.main}`,
                  '& fieldset': {
                    borderWidth: 0,
                    borderColor: 'transparent'
                  }
                }
              }}
            />
            {getValues('location.addressLine1') && (
              <Grid
                container
                columnSpacing={2}
                rowSpacing={4}
                sx={{
                  paddingTop: 1
                }}
              >
                <Grid item xs={12} md={6}>
                  <Controller
                    name="location.addressLine1"
                    control={control}
                    rules={{ required: 'Please enter address line 1.' }}
                    render={({ field }) => (
                      <StyledTransparentInput
                        {...field}
                        label={t('site_details_label.address_line_one', { ns: 'onboarding' })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="location.addressLine2"
                    control={control}
                    render={({ field }) => (
                      <StyledTransparentInput
                        {...field}
                        label={t('site_details_label.address_line_two', { ns: 'onboarding' })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="location.city"
                    control={control}
                    rules={{ required: 'Please enter a city.' }}
                    render={({ field }) => (
                      <StyledTransparentInput
                        {...field}
                        label={t('site_details_label.city', { ns: 'onboarding' })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="location.state"
                    control={control}
                    render={({ field }) => (
                      <StyledTransparentInput
                        {...field}
                        label={t('site_details_label.state', { ns: 'onboarding' })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="location.province"
                    control={control}
                    render={({ field }) => (
                      <StyledTransparentInput
                        {...field}
                        label={t('site_details_label.province', { ns: 'onboarding' })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="location.region"
                    control={control}
                    render={({ field }) => (
                      <StyledTransparentInput
                        {...field}
                        label={t('site_details_label.region', { ns: 'onboarding' })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="location.postalCode"
                    control={control}
                    rules={{ required: 'Please enter a zip/post/postal code.' }}
                    render={({ field }) => (
                      <StyledTransparentInput
                        {...field}
                        label={t('site_details_label.zip_post_postal', { ns: 'onboarding' })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <Controller
                      name="location.country"
                      control={control}
                      render={({ field }) => {
                        const selectedCountry = field.value && allCountries.find((ac) => ac.label === field.value);

                        return (
                          <Autocomplete
                            {...field}
                            options={allCountries}
                            popupIcon={<ChevronDownIcon />}
                            clearIcon={null}
                            getOptionLabel={(option) => option.label}
                            value={selectedCountry || null}
                            onChange={(_, newValue) => {
                              field.onChange(newValue?.label);
                            }}
                            renderInput={(params) => {
                              return <StyledTransparentInput {...params} required label={t('site_details_label.country', { ns: 'onboarding' })} />;
                            }}
                            renderOption={(props, option) => (
                              <ListItem {...props} key={crypto.randomUUID()}>
                                {option.label}
                              </ListItem>
                            )}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Grid container columnSpacing={2}>
                    <Grid item xs={6}>
                      <Stack gap={2}>
                        <Typography>{t('logo', { ns: 'common' })}</Typography>
                        {!siteMedia && <Typography>{t('site_cover_image', { ns: 'common' })}</Typography>}
                        {siteMedia && (
                          <Box
                            key={crypto.randomUUID()}
                            sx={{
                              width: '100%',
                              height: 'auto',
                              '& .Mui-Media': {
                                maxWidth: '100%'
                              }
                            }}
                          >
                            <PreviewSiteAdminOnboardingImageModal fileWithStatus={siteMedia} />
                          </Box>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <FileUploader
                        label={t('site_details_upload_copy', { ns: 'onboarding' })}
                        multiple={false}
                        handleChange={handleSiteImageUpload}
                        name="file"
                        types={IMAGE_FILE_TYPES}
                      >
                        <Stack
                          gap={2}
                          sx={{
                            height: '100%'
                          }}
                        >
                          <Typography>{t('file_upload', { ns: 'common' })}</Typography>
                          <Stack
                            sx={{
                              border: '2px dashed black',
                              alignItems: 'center',
                              justifyContent: 'center',
                              gap: '1rem',
                              padding: '1rem',
                              width: '100%',
                              height: 192
                            }}
                          >
                            <PhotoLibraryIcon
                              sx={{
                                fontSize: '2rem'
                              }}
                            />
                            <Typography
                              sx={{
                                textAlign: 'center'
                              }}
                            >
                              {t('site_details_upload_copy', { ns: 'onboarding' })}
                            </Typography>
                          </Stack>
                        </Stack>
                      </FileUploader>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {isLoading && (
              <Grid container>
                <Grid item xs={12} lg={6}>
                  <Skeleton sx={{ width: '100%', height: 50 }} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Skeleton sx={{ width: '100%', height: 50 }} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Skeleton sx={{ width: '100%', height: 50 }} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Skeleton sx={{ width: '100%', height: 50 }} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Skeleton sx={{ width: '100%', height: 50 }} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Skeleton sx={{ width: '100%', height: 50 }} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Skeleton sx={{ width: '100%', height: 50 }} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Skeleton sx={{ width: '100%', height: 50 }} />
                </Grid>
              </Grid>
            )}
          </Stack>
          <RowCenterStack
            gap={2}
            sx={{
              justifyContent: 'flex-end',
              height: 127,
              alignItems: 'center'
            }}
          >
            <Button
              variant="contained"
              sx={{
                minWidth: 100
              }}
              type="submit"
              disabled={!formState.isValid}
              endIcon={<AddIcon color="#FFFFFF" />}
            >
              {!isStepCompleted && t('submit', { ns: 'common' })}
              {isStepCompleted && t('update', { ns: 'common' })}
              {siteDetailsStepStatus === ActionStatus.Pending && <CircularProgress />}
            </Button>
          </RowCenterStack>
        </Box>
      </Grid>
    </Grid>
  );
};
