import React from 'react';
import {
  Box,
  Stack,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { BenefitsIcon, DocsIcon, HomeIcon, OrganizationIcon, PictureSizeSuffix, StyledNavAppBar, TalentIcon, TalkIcon, TimeIcon } from '@/shared';
import { Link, NavLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectOrganizationLogoFile, selectOrganizationLogoImageUrl, selectOrganizationLogoReadSasToken } from '@features/admin-settings';
import { pictureUrl } from '@utils/pictureUrl';
import { NavSettingsListItem, openNavigationDrawer } from '@features/navigation';
import { AllowedTo } from 'react-abac';
import { GroupPermissions } from '@features/abac';

interface NavigationProps {
  width: number;
  isOpen: boolean;
}

export const Navigation: React.FC<NavigationProps> = ({ width, isOpen }) => {
  const dispatch = useAppDispatch();
  const logo = useAppSelector(selectOrganizationLogoImageUrl);
  const logoFile = useAppSelector(selectOrganizationLogoFile);
  const organizationSasToken = useAppSelector(selectOrganizationLogoReadSasToken);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));

  const navItems = [
    {
      name: 'Home',
      url: '/',
      icon: <HomeIcon />,
      disabled: false
    },
    {
      name: 'Org',
      url: '/organization',
      icon: <OrganizationIcon />,
      disabled: true
    },
    {
      name: 'Time',
      url: '/time',
      icon: <TimeIcon />,
      disabled: true
    },
    {
      name: 'Talent',
      url: '/talent',
      icon: <TalentIcon />,
      disabled: true
    },
    {
      name: 'Docs',
      url: '/docs',
      icon: <DocsIcon />,
      disabled: true
    },
    {
      name: 'Task',
      url: '/task',
      icon: <TalkIcon />,
      disabled: true
    },
    {
      name: 'Benefits',
      url: '/benefits',
      icon: <BenefitsIcon />,
      disabled: true
    }
  ];

  const drawer = (
    <Stack
      component={'nav'}
      sx={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: (theme) => theme.palette.grey[600],
        borderRadius: {
          xs: 0,
          md: 4
        }
      }}
    >
      <Toolbar
        sx={{
          minHeight: {
            xs: '90px'
          },
          padding: {
            xs: '1rem',
            md: '1rem'
          }
        }}
      >
        <Stack
          gap={2}
          sx={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'flex-start'
          }}
        >
          {isOpen && (
            <Box
              component="img"
              src="/images/nav-logo-expanded.png"
              alt="Thrivea logo"
              sx={{
                maxWidth: '100%'
              }}
            />
          )}
          {!isOpen && (
            <Box
              component="img"
              src="/images/nav-logo-default.png"
              alt="Thrivea logo"
              sx={{
                maxWidth: '100%'
              }}
            />
          )}
        </Stack>
      </Toolbar>
      <List
        sx={{
          color: 'inherit',
          margin: 'auto',
          width: '100%',
          padding: 0
        }}
      >
        {navItems.map((item, index) => (
          <ListItem
            key={index}
            sx={{
              padding: !isOpen ? 0 : '6px 16px',
              mb: '16px',
              position: 'relative'
            }}
          >
            <ListItemButton
              disableRipple
              disableTouchRipple
              disableGutters
              component={NavLink}
              to={item.url}
              sx={{
                padding: 0,
                justifyContent: 'center',
                pointerEvents: item.disabled ? 'none' : 'all',
                '&.active': {
                  color: (theme) => theme.palette.action.focus,
                  '& path': {
                    fill: (theme) => theme.palette.action.focus
                  }
                },
                opacity: item.disabled ? '0.25' : 1
              }}
            >
              <Stack
                gap={1}
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: isOpen ? '4px' : ''
                }}
              >
                <ListItemIcon
                  className={!isOpen ? 'MuiLinkIcon' : ''}
                  sx={{
                    minWidth: 'auto',
                    width: 24,
                    height: 29,
                    marginRight: isOpen ? '12px' : 0,
                    '& path': {
                      transform: !isOpen ? 'translateY(5px)' : 'translateY(0)',
                      transition: (theme) =>
                        theme.transitions.create(['color', 'transform'], {
                          easing: theme.transitions.easing.easeInOut,
                          duration: theme.transitions.duration.standard
                        }),
                      fill: (theme) => theme.palette.common.black
                    }
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                {!isOpen && (
                  <Typography className="MuiLinkText" variant="body2">
                    {item.name}
                  </Typography>
                )}
              </Stack>
              {isOpen && (
                <ListItemText
                  primary={item.name}
                  sx={{
                    opacity: isOpen ? '1' : '0',
                    visibility: isOpen ? 'visible' : 'hidden',
                    transition: (theme) =>
                      theme.transitions.create(['opacity', 'visibility'], {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.standard
                      })
                  }}
                />
              )}
            </ListItemButton>
            {/* {item.disabled && <AvailableBetaTag right={-20} top={17} />} */}
          </ListItem>
        ))}
      </List>
      <List
        sx={{
          width: '100%',
          padding: 0
        }}
      >
        <AllowedTo perform={GroupPermissions.ACCESS_SETTINGS} no={() => <NavSettingsListItem isDrawerOpened={isOpen} isDisabled={true} />}>
          <NavSettingsListItem isDrawerOpened={isOpen} isDisabled={false} />
        </AllowedTo>
        <ListItem
          sx={{
            padding: '16px 0'
          }}
        >
          <ListItemButton
            disableGutters
            component={Link}
            to="#"
            sx={{
              justifyContent: 'center',
              '&:hover': {
                ' .hoverText': {
                  opacity: '1'
                }
              }
            }}
          >
            <Stack
              gap={1}
              sx={{
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Box
                component="img"
                src={
                  logoFile
                    ? URL.createObjectURL(logoFile.sm)
                    : logo
                      ? pictureUrl(logo, organizationSasToken, PictureSizeSuffix.sm)
                      : '/images/logo-placeholder.png'
                }
                sx={{
                  width: 46,
                  height: 46,
                  objectFit: 'contain',
                  borderRadius: 2,
                  backgroundColor: logo ? 'transparent' : '#000'
                }}
              />
            </Stack>
          </ListItemButton>
        </ListItem>
      </List>
    </Stack>
  );

  const navigationDrawerOpenButtonClick = () => {
    dispatch(openNavigationDrawer());
  };

  return (
    <>
      {!isTablet && (
        <>
          <StyledNavAppBar
            position="fixed"
            sx={{
              width: '100%',
              padding: 0,
              display: { xs: 'block', lg: 'none' },
              color: (theme) => theme.palette.common.white,
              backgroundColor: 'transparent'
            }}
            elevation={0}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={navigationDrawerOpenButtonClick}
              sx={{
                display: { lg: 'none' },
                left: 25
              }}
            >
              <MenuIcon />
            </IconButton>
          </StyledNavAppBar>
          <Drawer
            variant="temporary"
            open={isOpen}
            onClose={navigationDrawerOpenButtonClick}
            sx={{
              display: {
                xs: 'block',
                lg: 'none'
              },
              '& .MuiDrawer-paper': {
                width
              },
              '& .MuiBackdrop-root': {
                background: 'rgba(217, 217, 217, 0.60)',
                backdropFilter: 'blur(10px)'
              }
            }}
          >
            {drawer}
          </Drawer>
        </>
      )}
      {isTablet && <>{drawer}</>}
    </>
  );
};
