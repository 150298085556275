import { useTheme } from '@mui/material';
import React from 'react';

interface ChevronDownIconAttributes {
  className?: string;
}

export const ChevronDownIcon: React.FC<ChevronDownIconAttributes> = ({ className = '' }: ChevronDownIconAttributes) => {
  const theme = useTheme();

  return (
    <svg width="12" height="7" viewBox="0 0 12 7" fill={theme.palette.primary.main} xmlns="http://www.w3.org/2000/svg">
      <path d="M1.41 0.0257568L6 4.02046L10.59 0.0257568L12 1.25557L6 6.4888L0 1.25557L1.41 0.0257568Z" fill="black" />
    </svg>
  );
};
