import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Modal, Typography, Divider, Stack, Box } from '@mui/material';
import { CancelButton, UpdateButton, RowCenterStack, StyledModalContent, StyledListItemButton } from '@/shared';
import { deletePostRequested, selectPostById } from '@features/homepage';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { DeletePostRequest } from '@thrivea/networking-client';
import { AllowedTo } from 'react-abac';
import { useTranslation } from 'react-i18next';
import { PostInfo, AudiencePermissions } from '@features/abac';
import { MoreHoriz } from '@mui/icons-material';

interface PostMenuProps {
  postId: string;
  handleSetPostEditable: (id: string) => void;
}

export const PostMenu: React.FC<PostMenuProps> = ({ postId, handleSetPostEditable }) => {
  const { t } = useTranslation(['common', 'homepage']);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const open = Boolean(anchorEl);
  const post = useAppSelector((state) => selectPostById(state, postId));

  const handleThreeDotsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setAnchorEl(null);
    handleSetPostEditable(postId);
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeletePost = () => {
    dispatch(
      deletePostRequested({
        postId
      } as DeletePostRequest)
    );
    setAnchorEl(null);
    handleDeleteModalClose();
  };

  return (
    <Box>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleThreeDotsMenuClick}
        sx={{
          padding: '0'
        }}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 2,
            style: {
              minWidth: 150,
              maxHeight: 216,
              borderRadius: 7,
              marginTop: 10,
              marginLeft: 10
            }
          }
        }}
        sx={{
          '& .MuiList-root.MuiList-padding.MuiMenu-list': {
            padding: '4px'
          }
        }}
      >
        <AllowedTo
          perform={AudiencePermissions.EDIT_POST}
          data={{ audienceId: post.audienceIds.at(0), authorId: post.authorId } as PostInfo}
          no={() => <MenuItem disabled>{t('edit', { ns: 'common' })}</MenuItem>}
        >
          <StyledListItemButton disableRipple onClick={handleEditClick}>
            {t('edit', { ns: 'common' })}
          </StyledListItemButton>
        </AllowedTo>
        <AllowedTo
          perform={AudiencePermissions.DELETE_POST}
          data={{ audienceId: post.audienceIds.at(0), authorId: post.authorId } as PostInfo}
          no={() => <MenuItem disabled>{t('delete', { ns: 'common' })}</MenuItem>}
        >
          <StyledListItemButton disableRipple onClick={handleDeleteModalOpen}>
            {t('delete', { ns: 'common' })}
          </StyledListItemButton>
        </AllowedTo>
      </Menu>
      <Modal
        open={isDeleteModalOpen}
        onClose={handleDeleteModalClose}
        aria-labelledby="delete-post-modal-title"
        aria-description={t('delete_post', { ns: 'homepage' })}
        sx={{
          background: 'rgba(217, 217, 217, 0.60)',
          backdropFilter: 'blur(10px)'
        }}
      >
        <StyledModalContent
          sx={{
            padding: '1rem',
            minWidth: '30rem',
            borderRadius: '20px'
          }}
        >
          <Stack gap={2}>
            <Typography id="delete-post-modal-title" variant="h3" component="h5">
              {t('delete_post', { ns: 'homepage' })}
            </Typography>
            <Typography variant="body1">{t('delete_post_question', { ns: 'homepage' })}</Typography>
            <Divider />
            <RowCenterStack
              gap={2}
              sx={{
                width: '100%',
                justifyContent: 'flex-end'
              }}
            >
              <CancelButton onClick={handleDeleteModalClose} variant="outlined">
                {t('cancel', { ns: 'common' })}
              </CancelButton>
              <UpdateButton variant="contained" onClick={handleDeletePost}>
                {t('done', { ns: 'common' })}
              </UpdateButton>
            </RowCenterStack>
          </Stack>
        </StyledModalContent>
      </Modal>
    </Box>
  );
};
