import { Controller, useForm } from 'react-hook-form';
import { Box, Button, CircularProgress, Grid, ListItem, Skeleton, Stack, Typography } from '@mui/material';
import { countries, languages } from 'countries-list';
import timezones from 'timezones.json';
import { UpsertLocalizationStepRequest } from '@thrivea/organization-client';
import {
  AddIcon,
  ChevronDownIcon,
  EditPenIcon,
  RowCenterStack,
  StyledAutocomplete,
  StyledEditButton,
  StyledTransparentInput,
  ActionStatus
} from '@/shared';
import { useDispatch } from 'react-redux';
import {
  selectSiteId,
  upsertLocalizationStepRequested,
  selectSiteLocalizationStep,
  selectSiteDetailsStep,
  selectAdminOnboardingFlowStatus,
  selectIsSitesLocalizationStepFinished,
  selectIsSitesCalendarStepFinished,
  selectLocalizationStepStatus
} from '@features/admin-onboarding';
import { useAppSelector, useDebounceValidate } from '@app/hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

const languageData = Object.values(languages);
const allCurrencies = Array.from(new Set(Object.values(countries).flatMap((country) => country.currency))).sort();

const timeFormats = [
  { id: '24hr', label: '24-Hour Format (e.g., 14:00 for 2 PM)' },
  { id: '12hr', label: '12-Hour Format (e.g., 2:00 PM)' },
  { id: 'iso8601', label: 'ISO 8601 Format (e.g., 2024-01-05T14:00:00)' }
];
const dateFormats = [
  { id: 'YYYY-MM-DD', label: 'ISO 8601 Format (YYYY-MM-DD)' },
  { id: 'MM/DD/YY', label: 'US Format (MM/DD/YY)' },
  { id: 'DD/MM/YY', label: 'European Format (DD/MM/YY)' },
  { id: 'YY/MM/DD', label: 'Japanese Format (YY/MM/DD)' },
  { id: 'DD-MM-YYYY', label: 'Dashes Separator (DD-MM-YYYY)' },
  { id: 'DD.MM.YYYY', label: 'Dots Separator (DD.MM.YYYY)' },
  { id: 'MMMM D, YYYY', label: 'Full Month Name (MMMM D, YYYY)' },
  { id: 'D MMMM YYYY', label: 'Day First, Full Month Name (D MMMM YYYY)' },
  { id: 'YYYY年MM月DD日', label: 'Japanese Year First Format (YYYY年MM月DD日)' },
  { id: 'DD/MM/YYYY', label: 'European Day First Format (DD/MM/YYYY)' }
];
const firstDayOfWeek = [
  { id: 1, label: 'Monday' },
  { id: 2, label: 'Tuesday' },
  { id: 3, label: 'Wednesday' },
  { id: 4, label: 'Thursday' },
  { id: 5, label: 'Friday' },
  { id: 6, label: 'Saturday' },
  { id: 7, label: 'Sunday' }
];

const localizationFormSchema = z.object({
  language: z.string().min(1, { message: 'Language is required and cannot be empty.' }),
  currency: z.string().min(1, { message: 'Currency is required and cannot be empty.' }),
  timeZone: z.string().min(1, { message: 'Time zone is required and cannot be empty.' }),
  timeFormat: z.string().min(1, { message: 'Time format is required and cannot be empty.' }),
  dateFormat: z.string().min(1, { message: 'Date format is required and cannot be empty.' }),
  firstDayOfWeek: z.number().min(1, { message: 'First day of the week is required and cannot be empty.' })
});

interface AdminOnboardingLocalizationProps {
  isEditable: boolean;
  handleSetActiveSubStep: (stepName: string) => (isExpanded: boolean) => void;
}

export const AdminOnboardingLocalization: React.FC<AdminOnboardingLocalizationProps> = ({ isEditable = true, handleSetActiveSubStep }) => {
  const { t } = useTranslation(['onboarding', 'common']);
  const dispatch = useDispatch();
  const siteDetails = useAppSelector(selectSiteDetailsStep);
  const siteId = siteDetails?.siteId;
  const currentSiteId = useAppSelector(selectSiteId);
  const siteLocalization = useAppSelector(selectSiteLocalizationStep);
  const siteLocalizationStepStatus = useAppSelector(selectLocalizationStepStatus);
  const isStepCompleted = useAppSelector(selectIsSitesLocalizationStepFinished);
  const isNextStepCompleted = useAppSelector(selectIsSitesCalendarStepFinished);
  const adminOnboardingFlowStatus = useAppSelector(selectAdminOnboardingFlowStatus);
  const { handleSubmit, control, setValue, getValues, trigger, reset, formState } = useForm<UpsertLocalizationStepRequest>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      siteId: '',
      language: '',
      currency: '',
      timeZone: '',
      timeFormat: '',
      dateFormat: '',
      firstDayOfWeek: 0
    },
    resolver: zodResolver(localizationFormSchema)
  });
  const debounceValidate = useDebounceValidate<UpsertLocalizationStepRequest>(trigger, 500);

  const languages = languageData.map((language) => ({
    id: language.name,
    label: language.native
  }));

  const handleSetEditable = () => {
    handleSetActiveSubStep('Step2_2')(!isEditable);
  };

  useEffect(() => {
    if (siteDetails) {
      reset({
        siteId: siteId,
        language: siteLocalization?.language,
        currency: siteLocalization?.currency,
        timeZone: siteLocalization?.timeZone,
        timeFormat: siteLocalization?.timeFormat,
        dateFormat: siteLocalization?.dateFormat,
        firstDayOfWeek: siteLocalization?.firstDayOfWeek
      });
    }
  }, [siteDetails]);

  if (!isEditable) {
    if (adminOnboardingFlowStatus === ActionStatus.Pending) {
      return (
        <Grid container>
          <Grid item xs={3}>
            <Stack>
              <Skeleton width={180} height={20} />
              <Skeleton width={90} height={10} />
              <Skeleton width={90} height={10} />
              <Skeleton width={90} height={10} />
              <Skeleton width={90} height={10} />
              <Skeleton width={90} height={10} />
              <Skeleton width={90} height={10} />
              <Skeleton width={90} height={10} />
              <Skeleton width={90} height={10} />
              <Skeleton width={90} height={10} />
            </Stack>
          </Grid>
          <Grid item xs={9}>
            <Stack
              sx={{
                alignItems: 'flex-end',
                justifyContent: 'center',
                height: '100%'
              }}
            >
              <Skeleton width={90} height={40} />
            </Stack>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container>
        <Grid item xs={6}>
          <Stack gap={1}>
            <Typography
              variant="subtitle1"
              sx={{
                color: (theme) => theme.palette.primary.dark
              }}
            >
              Localization
            </Typography>
            <Stack>
              <Typography
                sx={{
                  fontWeight: 600
                }}
              >
                {getValues('language')}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600
                }}
              >
                {getValues('currency')}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600
                }}
              >
                {getValues('timeZone')}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600
                }}
              >
                {getValues('timeFormat')}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600
                }}
              >
                {getValues('dateFormat')}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600
                }}
              >
                {firstDayOfWeek.find((sfdotw) => sfdotw.id === getValues('firstDayOfWeek'))?.label}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack
            sx={{
              alignItems: 'flex-end',
              justifyContent: 'center',
              height: '100%'
            }}
          >
            <StyledEditButton variant="contained" startIcon={<EditPenIcon />} isStepCompleted={isStepCompleted} onClick={handleSetEditable}>
              {t('edit', { ns: 'common' })}
            </StyledEditButton>
          </Stack>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      rowSpacing={6}
      sx={{
        justifyContent: 'space-between'
      }}
    >
      <Grid item xs={12} lg={3}>
        <Typography variant="subtitle1">{t('site_localization_title', { ns: 'onboarding' })}</Typography>
        <Typography>{t('site_localization_copy', { ns: 'onboarding' })}</Typography>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Box
          component="form"
          onSubmit={handleSubmit((data) => {
            handleSetEditable();
            handleSetActiveSubStep('Step2_3')(!isNextStepCompleted);
            setValue('siteId', currentSiteId ?? siteId);

            dispatch(
              upsertLocalizationStepRequested(
                new UpsertLocalizationStepRequest({
                  currency: data.currency,
                  dateFormat: data.dateFormat,
                  firstDayOfWeek: data.firstDayOfWeek,
                  language: data.language,
                  siteId: currentSiteId ? currentSiteId : siteId,
                  timeFormat: data.timeFormat,
                  timeZone: data.timeZone
                })
              )
            );
          })}
        >
          <Stack gap={2}>
            <Stack gap={4}>
              <Controller
                name="language"
                control={control}
                render={({ field }) => {
                  const selectedLanguage = field.value && languages.find((l) => l.id === field.value);

                  return (
                    <StyledAutocomplete
                      {...field}
                      disableClearable={selectedLanguage !== null}
                      popupIcon={<ChevronDownIcon />}
                      openOnFocus
                      options={languages}
                      getOptionLabel={(option) => option.label}
                      value={selectedLanguage || null}
                      onChange={(_, newValue) => {
                        field.onChange(newValue!.id);
                      }}
                      slotProps={{
                        paper: {
                          sx: {
                            margin: '0 -4px'
                          }
                        }
                      }}
                      renderInput={(params) => {
                        return (
                          <StyledTransparentInput
                            {...params}
                            required
                            label={t('site_localization_label.primary_language', { ns: 'onboarding' })}
                            placeholder={t('site_localization_placeholder.languages', { ns: 'onboarding' })}
                            InputLabelProps={{ shrink: true }}
                          />
                        );
                      }}
                    />
                  );
                }}
              />
              <Controller
                name="currency"
                control={control}
                render={({ field }) => {
                  const selectedCurrency = field.value && allCurrencies.find((l) => l === field.value);

                  return (
                    <StyledAutocomplete
                      {...field}
                      disableClearable={selectedCurrency !== null}
                      autoHighlight
                      openOnFocus
                      includeInputInList
                      openText="Show currency list"
                      popupIcon={<ChevronDownIcon />}
                      options={allCurrencies}
                      getOptionLabel={(option) => option}
                      value={selectedCurrency || null}
                      onChange={(_, newValue) => {
                        setValue('currency', newValue!);
                        debounceValidate('currency');
                      }}
                      slotProps={{
                        paper: {
                          sx: {
                            margin: '0 -4px'
                          }
                        }
                      }}
                      renderInput={(params) => {
                        return (
                          <StyledTransparentInput
                            {...params}
                            required
                            InputLabelProps={{ shrink: true }}
                            label={t('site_localization_label.currency', { ns: 'onboarding' })}
                            placeholder={t('site_localization_placeholder.currency', { ns: 'onboarding' })}
                          />
                        );
                      }}
                      renderOption={(props, option) => (
                        <ListItem {...props} key={crypto.randomUUID()}>
                          {option}
                        </ListItem>
                      )}
                    />
                  );
                }}
              />
              <Controller
                name="timeZone"
                control={control}
                render={({ field }) => {
                  const selectedTimeZone = field.value && timezones.find((t) => t.text === field.value);
                  return (
                    <StyledAutocomplete
                      {...field}
                      autoHighlight
                      openOnFocus
                      includeInputInList
                      noOptionsText="No results"
                      popupIcon={<ChevronDownIcon />}
                      clearIcon={null}
                      options={timezones}
                      getOptionLabel={(option) => option.text}
                      value={selectedTimeZone || null}
                      onChange={(_, newValue) => {
                        field.onChange(newValue!.text);
                      }}
                      slotProps={{
                        paper: {
                          sx: {
                            margin: '0 -4px'
                          }
                        }
                      }}
                      renderInput={(params) => {
                        return (
                          <StyledTransparentInput
                            {...params}
                            label={t('site_localization_label.timezone', { ns: 'onboarding' })}
                            placeholder={t('site_localization_placeholder.timezone', { ns: 'onboarding' })}
                            InputLabelProps={{ shrink: true }}
                          />
                        );
                      }}
                      renderOption={(props, option) => (
                        <ListItem {...props} key={crypto.randomUUID()}>
                          {option.text}
                        </ListItem>
                      )}
                    />
                  );
                }}
              />
              <Controller
                name="timeFormat"
                control={control}
                render={({ field }) => {
                  const selectedTimeFormat = field.value && timeFormats.find((tf) => tf.id === field.value);
                  return (
                    <StyledAutocomplete
                      {...field}
                      autoHighlight
                      openOnFocus
                      includeInputInList
                      noOptionsText="No results"
                      popupIcon={<ChevronDownIcon />}
                      clearIcon={null}
                      options={timeFormats}
                      clearOnBlur
                      getOptionLabel={(option) => option.label}
                      value={selectedTimeFormat || null}
                      onChange={(_, newValue) => {
                        field.onChange(newValue!.id);
                      }}
                      slotProps={{
                        paper: {
                          sx: {
                            margin: '0 -4px'
                          }
                        }
                      }}
                      renderInput={(params) => {
                        return (
                          <StyledTransparentInput
                            {...params}
                            required
                            label={t('site_localization_label.time_format', { ns: 'onboarding' })}
                            placeholder={t('site_localization_placeholder.time_format', { ns: 'onboarding' })}
                            InputLabelProps={{ shrink: true }}
                          />
                        );
                      }}
                      renderOption={(props, option) => (
                        <ListItem {...props} key={crypto.randomUUID()}>
                          {option.label}
                        </ListItem>
                      )}
                    />
                  );
                }}
              />
              <Controller
                name="dateFormat"
                control={control}
                render={({ field }) => {
                  const selectedDateFormat = field.value && dateFormats.find((tf) => tf.id === field.value);
                  return (
                    <StyledAutocomplete
                      {...field}
                      disableClearable={selectedDateFormat !== null}
                      autoHighlight
                      openOnFocus
                      includeInputInList
                      noOptionsText="No results"
                      popupIcon={<ChevronDownIcon />}
                      options={dateFormats}
                      clearOnBlur
                      getOptionLabel={(option) => option.label}
                      value={selectedDateFormat || null}
                      onChange={(_, newValue) => {
                        field.onChange(newValue!.id);
                      }}
                      slotProps={{
                        paper: {
                          sx: {
                            margin: '0 -4px'
                          }
                        }
                      }}
                      renderInput={(params) => {
                        return (
                          <StyledTransparentInput
                            {...params}
                            required
                            label={t('site_localization_label.date_format', { ns: 'onboarding' })}
                            placeholder={t('site_localization_placeholder.time_format', { ns: 'onboarding' })}
                            InputLabelProps={{ shrink: true }}
                          />
                        );
                      }}
                      renderOption={(props, option) => (
                        <ListItem {...props} key={crypto.randomUUID()}>
                          {option.label}
                        </ListItem>
                      )}
                    />
                  );
                }}
              />
              <Controller
                name="firstDayOfWeek"
                control={control}
                render={({ field }) => {
                  const selectedFirstDayOfTheWeek = field.value && firstDayOfWeek.find((sfdotw) => sfdotw.id === field.value);
                  return (
                    <StyledAutocomplete
                      {...field}
                      disableClearable={selectedFirstDayOfTheWeek !== null}
                      autoHighlight
                      openOnFocus
                      includeInputInList
                      noOptionsText="No results"
                      popupIcon={<ChevronDownIcon />}
                      options={firstDayOfWeek}
                      clearOnBlur
                      getOptionLabel={(option) => option.label}
                      value={selectedFirstDayOfTheWeek || null}
                      onChange={(_, newValue) => {
                        field.onChange(newValue!.id);
                        setValue('firstDayOfWeek', newValue!.id);
                        debounceValidate('firstDayOfWeek');
                      }}
                      slotProps={{
                        paper: {
                          sx: {
                            margin: '0 -4px'
                          }
                        }
                      }}
                      renderInput={(params) => {
                        return (
                          <StyledTransparentInput
                            {...params}
                            required
                            label={t('site_localization_label.first_day', { ns: 'onboarding' })}
                            placeholder={t('site_localization_placeholder.first_day', { ns: 'onboarding' })}
                            InputLabelProps={{ shrink: true }}
                          />
                        );
                      }}
                    />
                  );
                }}
              />
            </Stack>
          </Stack>
          <RowCenterStack
            gap={2}
            sx={{
              height: 127,
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            <Button
              variant="contained"
              sx={{
                minWidth: 100
              }}
              type="submit"
              disabled={!formState.isValid}
              endIcon={<AddIcon color="#FFFFFF" />}
            >
              {!isStepCompleted && t('submit', { ns: 'common' })}
              {isStepCompleted && t('update', { ns: 'common' })}
              {siteLocalizationStepStatus === ActionStatus.Pending && <CircularProgress />}
            </Button>
          </RowCenterStack>
        </Box>
      </Grid>
    </Grid>
  );
};
