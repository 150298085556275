import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import Holidays from 'date-holidays';
import { Autocomplete, Box, Button, CircularProgress, FormControl, Grid, Skeleton, Stack, Typography } from '@mui/material';
import {
  AddIcon,
  AutoCompleteInput,
  ChevronDownIcon,
  EditPenIcon,
  RowCenterStack,
  StyledAutocomplete,
  StyledEditButton,
  StyledTransparentInput
} from '@/shared';
import { ActionStatus } from '@shared/shared.model';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch } from 'react-redux';
import { UpdateSiteCalendarStepRequest, CalendarEvent, CalendarEventStatus } from '@thrivea/organization-client';
import { useAppSelector, useDebounceValidate } from '@app/hooks';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  selectAdminOnboardingFlowStatus,
  selectIsSitesCalendarStepFinished,
  selectSiteCalendarStep,
  selectSiteDetailsStep,
  selectSiteId,
  updateSiteCalendarStepRequested,
  AdminOnboardingNotificationBackDrop,
  selectIsSitesWorkingPatternsStepFinished,
  selectIncorporationStepStatus,
  selectCalendarStepStatus
} from '@features/admin-onboarding';

interface Country {
  code: string;
  name: string;
}

interface Holiday {
  name: string;
  date: string;
  type: string;
  start: Date;
  end: Date;
}

const currentYear = new Date().getFullYear();
const holidays = new Holidays();

const countryArray: Country[] = Object.entries(holidays.getCountries('en')).map(([code, name]) => {
  return { code, name };
});

interface AdminOnboardingSiteCalendarProps {
  isEditable: boolean;
  handleSetActiveSubStep: (stepName: string) => (newExpanded: boolean) => void;
}

export const AdminOnboardingSiteCalendar: React.FC<AdminOnboardingSiteCalendarProps> = ({ isEditable, handleSetActiveSubStep }) => {
  const { t } = useTranslation(['onboarding', 'common']);
  const dispatch = useDispatch();
  const siteCalendar = useAppSelector(selectSiteCalendarStep);
  const siteDetails = useAppSelector(selectSiteDetailsStep);
  const adminOnboardingFlowStatus = useAppSelector(selectAdminOnboardingFlowStatus);
  const currentSiteId = useAppSelector(selectSiteId);
  const calendarStepStatus = useAppSelector(selectCalendarStepStatus);
  const previousStepStatus = useAppSelector(selectIncorporationStepStatus);
  const isStepCompleted = useAppSelector(selectIsSitesCalendarStepFinished);
  const isNextStepCompleted = useAppSelector(selectIsSitesWorkingPatternsStepFinished);

  const [siteHolidays, setSiteHolidays] = useState<Holiday[]>([
    {
      name: '',
      date: '',
      type: '',
      start: {} as Date,
      end: {} as Date
    }
  ]);
  const [isCalendarAdded, setIsCalendarAdded] = useState(false);

  const { handleSubmit, control, setValue, getValues, trigger, reset } = useForm<UpdateSiteCalendarStepRequest>({
    defaultValues: {
      name: '',
      events: [],
      siteId: ''
    }
  });
  const debounceValidate = useDebounceValidate<UpdateSiteCalendarStepRequest>(trigger, 500);

  const handleSetEditable = () => {
    handleSetActiveSubStep('Step2_3')(!isEditable);
  };

  const getHolidays = (country: Country) => {
    if (country) {
      holidays.init(country.code);
    }
    setSiteHolidays(holidays.getHolidays(currentYear, 'en'));
    if (siteHolidays) {
      setValue(
        'events',
        holidays.getHolidays(currentYear, 'en').map(
          (ch) =>
            new CalendarEvent({
              name: ch.name,
              startDate: DateTime.fromJSDate(ch.start).toISODate()!,
              endDate: DateTime.fromJSDate(ch.end).toISODate()!,
              status: ch.type === 'public' ? CalendarEventStatus.WORKING : CalendarEventStatus.NON_WORKING
            })
        )
      );
    }
  };

  useEffect(() => {
    if (siteCalendar) {
      const selectedCalendar = countryArray.find((fc) => fc.name === siteCalendar!.name);

      getHolidays(selectedCalendar!);

      reset({
        name: siteCalendar.name,
        events: holidays.getHolidays(currentYear, 'en').map(
          (ch) =>
            new CalendarEvent({
              name: ch.name,
              startDate: DateTime.fromJSDate(ch.start).toISODate()!,
              endDate: DateTime.fromJSDate(ch.end).toISODate()!,
              status: ch.type === 'public' ? CalendarEventStatus.WORKING : CalendarEventStatus.NON_WORKING
            })
        ),
        siteId: siteDetails?.siteId
      });
    }
  }, [siteCalendar]);

  if (!isEditable) {
    if (adminOnboardingFlowStatus === ActionStatus.Pending || previousStepStatus === ActionStatus.Pending) {
      return (
        <Grid container>
          <Grid item xs={3}>
            <Stack>
              <Skeleton width={180} height={20} />
              <Skeleton width={90} height={10} />
            </Stack>
          </Grid>
          <Grid item xs={9}>
            <Stack
              sx={{
                alignItems: 'flex-end',
                justifyContent: 'center',
                height: '100%'
              }}
            >
              <Skeleton width={90} height={40} />
            </Stack>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container>
        <Grid item xs={6}>
          <Stack gap={1}>
            <Typography
              variant="subtitle1"
              sx={{
                color: (theme) => theme.palette.primary.dark
              }}
            >
              {t('site_calendar_title', { ns: 'onboarding' })}
            </Typography>
            <Stack>
              <Typography
                sx={{
                  fontWeight: 600
                }}
              >
                {getValues('name')}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack
            sx={{
              alignItems: 'flex-end',
              justifyContent: 'center',
              height: '100%'
            }}
          >
            <StyledEditButton variant="contained" startIcon={<EditPenIcon />} isStepCompleted={isStepCompleted} onClick={handleSetEditable}>
              {t('edit', { ns: 'common' })}
            </StyledEditButton>
          </Stack>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      rowSpacing={6}
      sx={{
        justifyContent: 'space-between'
      }}
    >
      <Grid item xs={12} lg={3}>
        <Typography variant="subtitle1">Calendar</Typography>
        <Typography>{t('site_calendar_copy', { ns: 'onboarding' })}</Typography>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Box
          component="form"
          onSubmit={handleSubmit((data) => {
            handleSetEditable();
            handleSetActiveSubStep('Step2_4')(!isNextStepCompleted);
            dispatch(
              updateSiteCalendarStepRequested(
                new UpdateSiteCalendarStepRequest({
                  name: data.name,
                  events: getValues('events'),
                  siteId: currentSiteId ? currentSiteId : data.siteId
                })
              )
            );
          })}
        >
          <Stack gap={3}>
            <Stack gap={3}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => {
                  const selectedCalendar = field.value && countryArray.find((fc) => fc.name === field.value);

                  return (
                    <StyledAutocomplete
                      {...field}
                      disableClearable={selectedCalendar !== null}
                      popupIcon={<ChevronDownIcon />}
                      openOnFocus
                      options={countryArray}
                      getOptionLabel={(option) => option.name}
                      value={selectedCalendar || null}
                      onChange={(_, newValue) => {
                        getHolidays(newValue!);
                        setValue('name', newValue!.name);
                        debounceValidate('name');
                        setIsCalendarAdded(true);
                      }}
                      renderInput={(params) => {
                        return (
                          <StyledTransparentInput
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            required
                            label={t('site_calendar_title', { ns: 'onboarding' })}
                            placeholder={t('site_calendar_placeholder', { ns: 'onboarding' })}
                          />
                        );
                      }}
                    />
                  );
                }}
              />
              <FormControl
                sx={{
                  maxHeight: 440,
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    width: '0.3em'
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.3)'
                  }
                }}
              >
                <Controller
                  name="events"
                  control={control}
                  render={() => {
                    return (
                      <>
                        {siteHolidays.length !== 0 && (
                          <Stack
                            sx={{
                              padding: '0'
                            }}
                          >
                            <Grid
                              container
                              columnSpacing={2}
                              sx={{
                                position: 'sticky',
                                top: '-20px',
                                backgroundColor: (theme) => theme.palette.common.white,
                                borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`,
                                padding: '16px 0'
                              }}
                            >
                              <Grid item xs={3}>
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontWeight: 600
                                  }}
                                >
                                  {t('site_calendar_table_header.event_name', { ns: 'onboarding' })}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontWeight: 600
                                  }}
                                >
                                  {t('site_calendar_table_header.date', { ns: 'onboarding' })}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontWeight: 600
                                  }}
                                >
                                  {t('site_calendar_table_header.time_period', { ns: 'onboarding' })}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontWeight: 600
                                  }}
                                >
                                  {t('site_calendar_table_header.employee_activity', { ns: 'onboarding' })}
                                </Typography>
                              </Grid>
                            </Grid>
                            {siteHolidays.length !== 0 && (
                              <Grid
                                container
                                sx={{
                                  backgroundColor: (theme) => theme.palette.common.white
                                }}
                              >
                                {siteHolidays.map((holiday) => (
                                  <Grid
                                    key={crypto.randomUUID()}
                                    container
                                    columnSpacing={2}
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      padding: '16px 0',
                                      '&:not(:first-of-type)': {
                                        borderTop: (theme) => `1px solid ${theme.palette.primary.main}`
                                      }
                                    }}
                                  >
                                    <Grid item xs={3}>
                                      <Typography>{holiday.name}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Typography>{DateTime.fromJSDate(new Date(holiday.date)).toFormat('EEEE, MM/dd/yyyy')}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Typography>{t('site_calendar_duration', { ns: 'onboarding' })}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Typography>
                                        {holiday.type === 'public'
                                          ? t('site_calendar_non_working', { ns: 'onboarding' })
                                          : t('site_calendar_working', { ns: 'onboarding' })}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                ))}
                              </Grid>
                            )}
                          </Stack>
                        )}
                      </>
                    );
                  }}
                />
              </FormControl>
            </Stack>
            <RowCenterStack
              gap={2}
              sx={{
                justifyContent: 'flex-end',
                mt: 4
              }}
            >
              <Button
                variant="contained"
                sx={{
                  minWidth: 100
                }}
                type="submit"
                disabled={!isCalendarAdded}
                endIcon={<AddIcon color="#FFFFFF" />}
              >
                {!isStepCompleted && t('submit', { ns: 'common' })}
                {isStepCompleted && t('update', { ns: 'common' })}
                {calendarStepStatus === ActionStatus.Pending && <CircularProgress />}
              </Button>
            </RowCenterStack>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};
