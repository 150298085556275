// SignalRContext.tsx
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { tokenRequest } from '@utils/authConfig';
import { setSignalRConnectionId } from '@features/homepage';
import { useAppDispatch } from '@app/hooks';
import { ConsecutiveBreaker, ExponentialBackoff, retry, handleAll, circuitBreaker, wrap } from 'cockatiel';
import * as Sentry from '@sentry/react';

interface SignalRContextType {
  connection: HubConnection | null;
}

const SignalRContext = createContext<SignalRContextType | undefined>(undefined);

export const useSignalR = () => {
  const context = useContext(SignalRContext);

  if (context === undefined) {
    throw new Error('useSignalR must be used within a SignalRProvider');
  }

  return context;
};

interface SignalRProviderProps {
  children: ReactNode; // This explicitly types the children prop
}

export const SignalRProvider: React.FC<SignalRProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [connection, setConnection] = useState<HubConnection | null>(null);

  useEffect(() => {
    if (!isAuthenticated) return;

    // Create a retry policy that'll try whatever function we execute 3
    // times with a randomized exponential backoff.
    const retryPolicy = retry(handleAll, { maxAttempts: 3, backoff: new ExponentialBackoff() });

    // Create a circuit breaker that'll stop calling the executed function for 10
    // seconds if it fails 5 times in a row. This can give time for e.g. a database
    // to recover without getting tons of traffic.
    const circuitBreakerPolicy = circuitBreaker(handleAll, {
      halfOpenAfter: 10 * 1000,
      breaker: new ConsecutiveBreaker(5)
    });

    // Combine these! Create a policy that retries 3 times, calling through the circuit breaker
    const retryWithBreaker = wrap(retryPolicy, circuitBreakerPolicy);

    const conn = new HubConnectionBuilder()
      .withUrl(new URL('/notifications', import.meta.env.VITE_APP_API_URL).toString(), {
        accessTokenFactory: async () => (await instance.acquireTokenSilent(tokenRequest)).accessToken,
        transport: HttpTransportType.WebSockets
      })
      .build();

    const startConnection = async () => {
      try {
        await retryWithBreaker.execute(async () => await conn.start());

        console.log('Notifications Connected!');

        setConnection(conn);
        dispatch(setSignalRConnectionId(conn.connectionId!));
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    startConnection();

    return () => {
      conn.stop().then(() => {
        console.log('Notifications Disconnected.');
        setConnection(null); // Reset connection on cleanup
      });
    };
  }, [isAuthenticated]);

  return <SignalRContext.Provider value={{ connection }}>{children}</SignalRContext.Provider>;
};
