import { all, fork } from 'redux-saga/effects';
import { userSagas } from '@app/user';
import { abacSagas } from '@features/abac';
import { adminSettingsSagas, audienceSagas, permissionsSagas } from '@features/admin-settings';
import { adminOnoardingFlowSagas } from '@features/admin-onboarding';
import { audienceDrawerSagas } from '@features/drawer';
import { employeeProfileSagas } from '@features/employee-profile';
import { postsSagas, widgetsSagas } from '@features/homepage';
import { protectedRoutesSagas } from '@features/protected-routes';
import { snackbarSagas } from '@features/snackbar';
import { notificationsSagas } from '@features/notifications';
import { welcomeSagas } from '@features/welcome';
import { employeeRecordBuilderSagas } from '@features/employee-record-builder';
import { employeeRecordSagas } from '@features/employee-record-page';
import { peopleDirSagas } from '@features/people-directory';
import { sitesSagas } from '@shared/sites';

export function* rootSaga() {
  yield all([
    fork(abacSagas),
    fork(adminSettingsSagas),
    fork(adminOnoardingFlowSagas),
    fork(audienceDrawerSagas),
    fork(audienceSagas),
    fork(employeeProfileSagas),
    fork(permissionsSagas),
    fork(protectedRoutesSagas),
    fork(postsSagas),
    fork(notificationsSagas),
    fork(snackbarSagas),
    fork(sitesSagas),
    fork(userSagas),
    fork(welcomeSagas),
    fork(widgetsSagas),
    fork(employeeRecordBuilderSagas),
    fork(employeeRecordSagas),
    fork(peopleDirSagas)
  ]);
}
