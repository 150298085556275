import React, { useId, useState } from 'react';
import { Button, Box, Typography, Stack, Collapse, List, ListItem, ClickAwayListener } from '@mui/material';
import {
  ChevronDownIcon,
  BinIcon,
  StyledModalContent,
  StyledCancelButton,
  StyledFormSubmitButton,
  StyledBlurryModal,
  RowCenterStack,
  CancelEditIcon,
  StyledListItemButton
} from '@/shared';
import { DeleteWorkingPatternByIdRequest, RetrieveWorkingPatternByIdRequest, WorkingPatternListItem } from '@thrivea/organization-client';
import { useAppDispatch } from '@/app/hooks';
import { deleteWorkingPatternByIdRequested, retrieveWorkingPatternByIdRequested } from '@features/admin-settings';
import { useTranslation } from 'react-i18next';
import { AdminSettingsWorkingPatternEdit } from './AdminSettingsWorkingPatternEdit';

interface WorkingPatternActionsProps {
  workingPatternListItem: WorkingPatternListItem;
  menuName: string;
  isWorkingPatternMenuOpen: boolean;
  handleWorkingPatternMenuToggle: (menuName: string) => void;
  closeMenu: (menuName: string) => void;
}

export const WorkingPatternActions: React.FC<WorkingPatternActionsProps> = ({
  workingPatternListItem,
  menuName,
  isWorkingPatternMenuOpen,
  handleWorkingPatternMenuToggle,
  closeMenu
}) => {
  const id = useId();
  const { t } = useTranslation(['settings_working_pattern', 'common']);
  const dispatch = useAppDispatch();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [isEditWorkingPatternModalOpen, setIsEditWorkingPatternModalOpen] = useState(false);

  const handleEditWorkingPatternModalOpen = (event: React.MouseEvent<HTMLElement>, wpId: string) => {
    event.stopPropagation();
    setIsEditWorkingPatternModalOpen(true);
    dispatch(
      retrieveWorkingPatternByIdRequested(
        new RetrieveWorkingPatternByIdRequest({
          workingPatternId: wpId
        })
      )
    );
  };

  const handleEditWorkingPatternModalClose = () => {
    setIsEditWorkingPatternModalOpen(false);
  };

  const handleDeleteSite = () => {
    dispatch(
      deleteWorkingPatternByIdRequested(
        new DeleteWorkingPatternByIdRequest({
          workingPatternId: workingPatternListItem.id
        })
      )
    );
    setIsDeleteModalOpen(false);
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={() => closeMenu(menuName)}>
      <Box>
        <Button
          aria-controls={workingPatternListItem.id ? id + workingPatternListItem.name : undefined}
          aria-haspopup="true"
          onClick={() => handleWorkingPatternMenuToggle(menuName)}
          variant="contained"
          endIcon={<ChevronDownIcon />}
          sx={{
            backgroundColor: (theme) => (isWorkingPatternMenuOpen ? theme.palette.primary.main : 'transparent'),
            color: (theme) => (isWorkingPatternMenuOpen ? theme.palette.common.white : theme.palette.common.black),
            border: (theme) => (isWorkingPatternMenuOpen ? undefined : `1px solid ${theme.palette.primary.main}`),
            borderRadius: '7px',
            width: 180,
            padding: '12px',
            lineHeight: 'normal',
            fontWeight: 700,
            boxShadow: 'none',
            '& .MuiButton-endIcon': {
              marginTop: '2px',
              transform: isWorkingPatternMenuOpen ? 'rotate(180deg)' : undefined,
              transition: 'transform 0.3s ease-in-out',
              '& path': {
                fill: (theme) => (isWorkingPatternMenuOpen ? theme.palette.common.white : theme.palette.common.black)
              }
            },
            '&:hover': {
              backgroundColor: (theme) => (isWorkingPatternMenuOpen ? theme.palette.primary.dark : theme.palette.secondary.dark),
              borderColor: 'transparent'
            }
          }}
        >
          {t('actions', { ns: 'common' })}
        </Button>
        <Collapse in={isWorkingPatternMenuOpen}>
          <Stack
            component={List}
            gap={1}
            sx={{
              width: 180,
              backgroundColor: (theme) => theme.palette.common.white,
              padding: '7px'
            }}
          >
            <ListItem disableGutters disablePadding>
              <StyledListItemButton disableRipple divider onClick={(e) => handleEditWorkingPatternModalOpen(e, workingPatternListItem.id)}>
                View & Edit
              </StyledListItemButton>
            </ListItem>
            <ListItem disableGutters disablePadding>
              <Button
                variant="contained"
                endIcon={<BinIcon color="#FFFFFF" />}
                sx={{
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  backgroundColor: (theme) => theme.palette.primary.dark,
                  color: (theme) => theme.palette.common.white,
                  padding: '8px 20px',
                  boxShadow: 'none',
                  '& .MuiButton-endIcon': {
                    marginTop: '-4px'
                  },
                  '&:hover': {
                    boxShadow: 'none'
                  }
                }}
                onClick={handleDeleteModalOpen}
              >
                {t('delete', { ns: 'common' })}
              </Button>
            </ListItem>
          </Stack>
        </Collapse>
        <StyledBlurryModal component="div" open={isDeleteModalOpen} onClose={handleDeleteModalClose}>
          <StyledModalContent
            sx={{
              position: 'relative',
              justifyContent: 'center',
              alignItems: 'flex-start',
              minWidth: {
                xs: 'auto',
                lg: '670px'
              },
              height: {
                xs: '80%',
                lg: 'auto'
              },
              padding: '120px',
              display: 'flex',
              borderRadius: '20px',
              boxShadow: 'none'
            }}
          >
            <Stack
              sx={{
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'
              }}
            >
              <Typography variant="subtitle1">{t('delete_working_pattern', { ns: 'settings_working_patterns' })}</Typography>
              <Typography
                variant="body1"
                sx={{
                  margin: '24px 0 35px',
                  color: '#637381'
                }}
              >
                {t('delete_question', { ns: 'settings_sites' })} <Box component="b">{workingPatternListItem.name}</Box>
              </Typography>
              <RowCenterStack gap={2}>
                <StyledCancelButton variant="contained" startIcon={<CancelEditIcon />} onClick={handleDeleteModalClose}>
                  {t('cancel', { ns: 'common' })}
                </StyledCancelButton>
                <StyledFormSubmitButton variant="contained" onClick={handleDeleteSite}>
                  {t('delete', { ns: 'common' })}
                </StyledFormSubmitButton>
              </RowCenterStack>
            </Stack>
          </StyledModalContent>
        </StyledBlurryModal>
        <AdminSettingsWorkingPatternEdit
          isOpen={isEditWorkingPatternModalOpen}
          handleCloseModal={handleEditWorkingPatternModalClose}
          workingPatternListItem={workingPatternListItem}
        />
      </Box>
    </ClickAwayListener>
  );
};
