import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import {
  DeleteCommentRequest,
  DeletePostRequest,
  Post,
  Comment,
  ReactToCommentRequest,
  WithdrawPostReactionRequest,
  WithdrawCommentReactionRequest,
  ReactToPostRequest,
  CommentOnPostDeleted,
  CommentOnPostRequest,
  CommentReactionWithdrawn,
  CommentedOnPost,
  KudosEdited,
  KudosPublished,
  PostDeleted,
  PostReactionWithdrawn,
  PublishShoutoutRequest,
  ReactedToComment,
  ReactedToPost,
  Reaction,
  RetrievePostsRequest,
  Shoutout,
  ShoutoutEdited,
  Kudos,
  RetrievePostsResponse,
  RetrieveRecentPostsByAuthorRequest,
  RetrieveRecentPostsByAuthorResponse,
  SaveDraftPostRequest,
  RetrieveLastDraftPostRequest,
  DraftPost,
  DeleteDraftPostsRequest,
  EditShoutoutRequest,
  EditCommentRequest,
  RetrieveAudiencesWithPostingRightsByEmployeeIdRequest,
  RetrieveAudiencesWithPostingRightsByEmployeeIdResponse,
  AudienceWithName,
  RetrieveAudiencesByIdsResponse,
  RetrieveEmployeeIdsByAudienceIdRequest
} from '@thrivea/networking-client';
import { CommentModel, KudosModel, PostModel, ShoutoutModel } from '@features/homepage';
import { ActionStatus } from '@/shared';
import groupBy from 'lodash/groupBy';
import { FileWithStatus, UploadProgress, UploadedFile } from '@api/blob-storage.api';
import {
  Author,
  EmployeeItem,
  EmployeeListItem,
  RetrieveAuthorsResponse,
  RetrieveEmployeeItemsResponse
} from '@thrivea/organization-client';
import { DateTime } from 'luxon';
import { trimQueryParams } from '@utils/trimQueryParams';
import { RefreshPostCreateDeleteSasUriRequest, SasToken, SasUri } from '@thrivea/auth-client';

const mapShoutout = (shoutout: Shoutout): ShoutoutModel => {
  const reactionDict = groupBy(shoutout.reactions, (r) => r.emoji);

  return {
    id: shoutout.id,
    authorId: shoutout.authorId,
    title: shoutout.title,
    text: shoutout.text,
    docUrls: shoutout.docUrls,
    mediaUrls: shoutout.mediaUrls,
    publishTime: DateTime.fromJSDate(shoutout.publishTime!.toDate()),
    commentIds: [],
    audienceIds: shoutout.audienceIds,
    reactions: reactionDict,
    editedTime: shoutout.editedTime ? DateTime.fromISO(shoutout.editedTime) : undefined,
    case: 'shoutout'
  } as ShoutoutModel;
};

const mapKudos = (kudos: Kudos): KudosModel => {
  const reactionDict = groupBy(kudos.reactions, (r) => r.emoji);

  return {
    id: kudos.id,
    authorId: kudos.authorId,
    title: kudos.title,
    reason: kudos.reason,
    publishTime: DateTime.fromJSDate(kudos.publishTime!.toDate()),
    commentIds: [],
    audienceIds: kudos.audienceIds,
    reactions: reactionDict,
    case: 'kudos'
  } as KudosModel;
};

const mapComment = (comment: Comment): CommentModel => {
  const reactionDict = groupBy(comment.reactions, (r) => r.emoji);

  return {
    id: comment.id,
    authorId: comment.authorId,
    text: comment.text,
    commentTime: DateTime.fromJSDate(comment.commentTime!.toDate()),
    editedTime: comment.editedTime ? DateTime.fromISO(comment.editedTime) : undefined,
    reactions: reactionDict
  } as CommentModel;
};

interface PostsState {
  entities: {
    posts: {
      byId: { [key: string]: PostModel };
      allIds: string[];
    };
    comments: {
      byId: { [key: string]: CommentModel };
      allIds: string[];
    };
    authors: {
      byId: { [key: string]: Author };
      allIds: string[];
    };
    employeeItems: {
      byId: { [key: string]: EmployeeItem };
      allIds: string[];
    };
    employeeRecentPosts: {
      byId: { [key: string]: PostModel };
      allIds: string[];
    };
    audiences: {
      byId: { [key: string]: AudienceWithName };
      withPostingPrivilegesAudienceIds: string[];
      retrievedPostsAudienceIds: string[];
    };
    mentionEmployees: {
      byId: { [key: string]: EmployeeListItem };
      allIds: string[];
    };
  };
  ui: {
    isPostDialogOpened: boolean;
    isNewPostPublished: boolean;
    newPostsCounter: number;
    publishPostStatus: ActionStatus;
    commentOnPostStatus: ActionStatus;
    deleteCommentStatus: ActionStatus;
    deletePostStatus: ActionStatus;
    reactToCommentStatus: ActionStatus;
    postsStatus: ActionStatus;
    uploadPostFileStatus: ActionStatus;
    fileUploadProgress: UploadProgress;
    postFilesWithStatus: FileWithStatus[];
    employeeListStatus: ActionStatus;
    employeeRecentPostsStatus: ActionStatus;
    isPostSavedToDraftStatus: ActionStatus;
    isPostSavedToDraft: boolean;
    isPostDraftDeletedStatus: ActionStatus;
    isPostDraftDeleted: boolean;
    audiencesWithPostingPrivilegesStatus: ActionStatus;
    mentionEmployeesStatus: ActionStatus;
  };
  commentedPostId?: string;
  currentAuthor: string;
  signalRConnectionId: string;
  postCreateDeleteSasUri: string;
  postReadSasToken: string;
  hasMore: boolean;
  hasMoreRecentPosts: boolean;
  currentPostsPage: number;
  currentRecentPostsPage: number;
  deletedCommentId?: string;
  postDraft: DraftPost;
  postDraftText: string;
}

const initialState: PostsState = {
  entities: {
    posts: { byId: {}, allIds: [] },
    comments: { byId: {}, allIds: [] },
    authors: { byId: {}, allIds: [] },
    employeeItems: { byId: {}, allIds: [] },
    employeeRecentPosts: { byId: {}, allIds: [] },
    audiences: {
      byId: {},
      retrievedPostsAudienceIds: [],
      withPostingPrivilegesAudienceIds: []
    },
    mentionEmployees: {
      byId: {},
      allIds: []
    }
  },
  ui: {
    isPostDialogOpened: false,
    isNewPostPublished: false,
    newPostsCounter: 0,
    publishPostStatus: ActionStatus.Idle,
    commentOnPostStatus: ActionStatus.Idle,
    deleteCommentStatus: ActionStatus.Idle,
    reactToCommentStatus: ActionStatus.Idle,
    deletePostStatus: ActionStatus.Idle,
    postsStatus: ActionStatus.Idle,
    uploadPostFileStatus: ActionStatus.Idle,
    fileUploadProgress: {
      fileProgress: 0,
      totalProgress: 0
    },
    postFilesWithStatus: [],
    employeeListStatus: ActionStatus.Idle,
    employeeRecentPostsStatus: ActionStatus.Idle,
    isPostSavedToDraftStatus: ActionStatus.Idle,
    isPostSavedToDraft: false,
    isPostDraftDeletedStatus: ActionStatus.Idle,
    isPostDraftDeleted: false,
    audiencesWithPostingPrivilegesStatus: ActionStatus.Idle,
    mentionEmployeesStatus: ActionStatus.Idle
  },
  commentedPostId: undefined,
  currentAuthor: '',
  signalRConnectionId: '',
  postReadSasToken: '',
  postCreateDeleteSasUri: '',
  hasMore: false,
  hasMoreRecentPosts: false,
  currentPostsPage: 0,
  currentRecentPostsPage: 1,
  postDraft: {} as DraftPost,
  postDraftText: ''
};

export const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setSignalRConnectionId: (state, action: PayloadAction<string>) => {
      state.signalRConnectionId = action.payload;
    },
    setCurrentAuthor: (state, action: PayloadAction<string>) => {
      state.currentAuthor = action.payload;
    },
    updateAuthorProfilePicture: (state, action: PayloadAction<Author>) => {
      if (state.entities.authors.byId.hasOwnProperty(action.payload.id)) {
        state.entities.authors.byId[action.payload.id].profilePictureUrl = action.payload.profilePictureUrl;
      }
    },
    openPostDialog: (state) => {
      state.ui.isPostDialogOpened = true;
    },
    closePostDialogRequested: (state) => {
      state.ui.fileUploadProgress = {
        fileProgress: 0,
        totalProgress: 0
      };
      state.ui.isPostDialogOpened = false;
    },
    closePostDialogSucceeded: (state) => {
      state.ui.isPostDialogOpened = false;
      state.ui.postFilesWithStatus = [];
    },
    closePostDialogFailed: (state) => {
      // TODO:
    },
    newPostPublished: (state) => {
      state.ui.newPostsCounter++;
    },
    showNewReactionRequested: (state, action: PayloadAction<ReactedToPost>) => {},
    showNewReactionSucceeded: (state, action: PayloadAction<ReactedToPost>) => {
      if (!state.entities.posts.byId.hasOwnProperty(action.payload.postId)) return;
      const post = state.entities.posts.byId[action.payload.postId];

      state.entities.posts.byId[post.id].reactions[action.payload.emoji] ??= [];

      state.entities.posts.byId[post.id].reactions[action.payload.emoji].push(action.payload as Reaction);
    },
    withdrawPostReaction: (state, action: PayloadAction<PostReactionWithdrawn>) => {
      if (!state.entities.posts.byId.hasOwnProperty(action.payload.postId)) return;

      state.entities.posts.byId[action.payload.postId].reactions[action.payload.emoji] = state.entities.posts.byId[action.payload.postId].reactions[
        action.payload.emoji
      ].filter((r) => r.authorId !== action.payload.authorId);

      if (state.entities.posts.byId[action.payload.postId].reactions[action.payload.emoji].length === 0)
        delete state.entities.posts.byId[action.payload.postId].reactions[action.payload.emoji];
    },
    showNewCommentRequested: (state, action: PayloadAction<CommentedOnPost>) => {},
    showNewCommentSucceeded: (state, action: PayloadAction<CommentedOnPost>) => {
      if (!state.entities.posts.byId.hasOwnProperty(action.payload.postId)) return;

      state.entities.comments.byId[action.payload.commentId] = {
        id: action.payload.commentId,
        authorId: action.payload.authorId,
        text: action.payload.comment,
        commentTime: DateTime.utc(),
        reactions: {}
      } as CommentModel;

      state.entities.comments.allIds.push(action.payload.commentId);
      state.entities.posts.byId[action.payload.postId].commentIds.push(action.payload.commentId);
    },
    deleteCommentOnPost: (state, action: PayloadAction<CommentOnPostDeleted>) => {
      if (!state.entities.posts.byId.hasOwnProperty(action.payload.postId)) return;

      delete state.entities.comments.byId[action.payload.commentId];
      state.entities.comments.allIds = state.entities.comments.allIds.filter((commentId) => commentId !== action.payload.commentId);
      state.entities.posts.byId[action.payload.postId].commentIds = state.entities.posts.byId[action.payload.postId].commentIds.filter((commentId) => {
        commentId !== action.payload.commentId;
      });
    },
    showNewReactionToCommentRequested: (state, action: PayloadAction<ReactedToComment>) => {},
    showNewReactionToCommentSucceeded: (state, action: PayloadAction<ReactedToComment>) => {
      if (!state.entities.comments.byId.hasOwnProperty(action.payload.commentId)) return;

      state.entities.comments.byId[action.payload.commentId].reactions[action.payload.emoji] ??= [];
      state.entities.comments.byId[action.payload.commentId].reactions[action.payload.emoji].push(action.payload as Reaction);
    },
    withdrawCommentReaction: (state, action: PayloadAction<CommentReactionWithdrawn>) => {
      if (!state.entities.comments.byId.hasOwnProperty(action.payload.commentId)) return;

      state.entities.comments.byId[action.payload.commentId].reactions[action.payload.emoji] = state.entities.comments.byId[action.payload.commentId].reactions[
        action.payload.emoji
      ].filter((r) => r.authorId !== action.payload.authorId);

      if (state.entities.comments.byId[action.payload.commentId].reactions[action.payload.emoji].length === 0)
        delete state.entities.comments.byId[action.payload.commentId].reactions[action.payload.emoji];
    },
    deletePost: (state, action: PayloadAction<PostDeleted>) => {
      for (const commentId of state.entities.posts.byId[action.payload.postId].commentIds) {
        delete state.entities.comments.byId[commentId];
        state.entities.comments.allIds = state.entities.comments.allIds.filter((id) => id !== commentId);
      }

      delete state.entities.posts.byId[action.payload.postId];
      state.entities.posts.allIds = state.entities.posts.allIds.filter((postId) => postId !== action.payload.postId);
    },
    editPostRequested: (state, action: PayloadAction<EditShoutoutRequest>) => {},
    editPostSucceeded: (state, action: PayloadAction<EditShoutoutRequest>) => {
      state.entities.posts.byId[action.payload.id] = {
        ...state.entities.posts.byId[action.payload.id],
        text: action.payload.text,
        mediaUrls: action.payload.mediaUrls,
        docUrls: action.payload.docUrls,
        editedTime: DateTime.utc()
      } as ShoutoutModel;
      state.ui.postFilesWithStatus = [];
    },
    editPostFailed: (state) => {},
    editShoutout: (state, action: PayloadAction<ShoutoutEdited>) => {
      // TODO: add functionality when edit post feature is added
    },
    editKudos: (state, action: PayloadAction<KudosEdited>) => {
      // TODO: add functionality when edit kudos feature is added
    },
    publishKudos: (state, action: PayloadAction<KudosPublished>) => {
      // TODO: add functionality when publish kudos feature is added
    },
    closeNewPostNotification: (state) => {
      state.ui.isNewPostPublished = false;
    },
    addAuthor: (state, action: PayloadAction<Author[]>) => {
      const authors = action.payload;

      for (const author of authors.filter((a) => !state.entities.authors.allIds.includes(a.id))) {
        state.entities.authors.byId[author.id] = author;
        state.entities.authors.allIds.push(author.id);
      }
    },
    retrievePostsRequested: (state, action: PayloadAction<RetrievePostsRequest>) => {
      state.ui.postsStatus = ActionStatus.Pending;
      if (action.payload.pageNumber === 1) {
        state.ui.newPostsCounter = 0;
        state.entities.posts = initialState.entities.posts;
      }
    },
    retrievePostsSucceeded: (
      state,
      action: PayloadAction<{ postsResponse: RetrievePostsResponse; authors: RetrieveAuthorsResponse; audiences: RetrieveAudiencesByIdsResponse }>
    ) => {
      const { posts, totalCount } = action.payload.postsResponse;
      const { authors } = action.payload.authors;
      const { audiences } = action.payload.audiences;

      for (const { content } of posts) {
        const post = content.value!;

        state.entities.posts.allIds.push(post.id);

        switch (content.case) {
          case 'shoutout':
            const shoutout = post as Shoutout;
            state.entities.posts.byId[shoutout.id] = mapShoutout(shoutout);
            break;
          case 'kudos':
            const kudos = post as Kudos;
            state.entities.posts.byId[kudos.id] = mapKudos(kudos);
            break;
          case 'poll':
            break;
        }

        for (const comment of post.comments) {
          state.entities.posts.byId[post.id].commentIds.push(comment.id);

          state.entities.comments.byId[comment.id] = mapComment(comment);
          state.entities.comments.allIds.push(comment.id);
        }
      }

      for (const author of authors.filter((a) => !state.entities.authors.allIds.includes(a.id))) {
        state.entities.authors.byId[author.id] = author;
        state.entities.authors.allIds.push(author.id);
      }

      for (const audience of audiences.filter((a) => !state.entities.audiences.retrievedPostsAudienceIds.includes(a.audienceId))) {
        state.entities.audiences.byId[audience.audienceId] = audience;
        state.entities.audiences.retrievedPostsAudienceIds.push(audience.audienceId);
      }

      state.currentPostsPage += 1;
      state.hasMore = state.entities.posts.allIds.length < totalCount;
      state.ui.postsStatus = ActionStatus.Idle;
    },
    retrievePostsFailed: (state) => {
      state.ui.postsStatus = ActionStatus.Failed;
    },
    uploadPostFilesRequested: (state, action: PayloadAction<FileList>) => {
      const files: FileWithStatus[] = [];

      for (const file of action.payload) {
        files.push({
          id: crypto.randomUUID(),
          isUploaded: false,
          isUploading: false,
          name: file.name,
          file,
          url: ''
        });
      }

      state.ui.postFilesWithStatus = state.ui.postFilesWithStatus.concat(files);
      state.ui.uploadPostFileStatus = ActionStatus.Pending;
    },
    uploadPostFilesSucceeded: (state) => {
      state.ui.uploadPostFileStatus = ActionStatus.Idle;
    },
    uploadPostFilesFailed: (state) => {
      // TODO: remove only file that failed to send?
      state.ui.uploadPostFileStatus = ActionStatus.Failed;
    },
    uploadPostFile: (state, action: PayloadAction<FileWithStatus>) => {
      const fileWithStatus = state.ui.postFilesWithStatus.find((fws) => fws.id === action.payload.id)!;
      fileWithStatus.isUploading = true;
      fileWithStatus.isUploaded = false;
    },
    postFileUploaded: (state, action: PayloadAction<UploadedFile>) => {
      const fileWithStatus = state.ui.postFilesWithStatus.find((fws) => fws.id === action.payload.fileWithStatus.id)!;
      fileWithStatus.isUploading = false;
      fileWithStatus.isUploaded = true;
      fileWithStatus.url = trimQueryParams(action.payload.url);
    },
    deletePostSingleFileRequested: (state, action: PayloadAction<string>) => {},
    deletePostSingleFileSucceeded: (state, action: PayloadAction<string>) => {
      state.ui.postFilesWithStatus = state.ui.postFilesWithStatus.filter((fws) => fws.url !== action.payload);
    },
    deletePostSingleFileFailed: (state, action: PayloadAction<string>) => {},
    publishShoutoutRequested: (state, _: PayloadAction<PublishShoutoutRequest>) => {
      state.ui.publishPostStatus = ActionStatus.Pending;
    },
    publishShoutoutSucceeded: (state, action: PayloadAction<Post>) => {
      const shoutout = action.payload.content.value as Shoutout;

      state.entities.posts.byId[shoutout.id] = mapShoutout(shoutout);
      state.entities.posts.allIds.unshift(shoutout.id);
      state.entities.posts.byId[shoutout.id].commentIds = [];

      state.ui.publishPostStatus = ActionStatus.Idle;
      state.ui.postFilesWithStatus = [];
      state.ui.isPostDialogOpened = false;
      state.postDraft = {} as DraftPost;
    },
    publishShoutoutFailed: (state) => {
      state.ui.publishPostStatus = ActionStatus.Failed;
      state.ui.isPostDialogOpened = false;
      state.ui.postFilesWithStatus = [];
    },
    commentOnPostRequested: (state, action: PayloadAction<CommentOnPostRequest>) => {
      state.commentedPostId = action.payload.postId;
      state.ui.commentOnPostStatus = ActionStatus.Pending;
    },
    commentOnPostSucceeded: (state, action: PayloadAction<Comment>) => {
      state.entities.comments.allIds.push(action.payload.id);
      state.entities.comments.byId[action.payload.id] = mapComment(action.payload);
      state.entities.posts.byId[state.commentedPostId!].commentIds.push(action.payload.id);

      state.ui.commentOnPostStatus = ActionStatus.Idle;
      state.commentedPostId = undefined;
    },
    commentOnPostFailed: (state) => {
      state.ui.isPostDialogOpened = false;
      state.ui.commentOnPostStatus = ActionStatus.Failed;
      state.commentedPostId = undefined;
    },
    deleteCommentRequested: (state, action: PayloadAction<DeleteCommentRequest>) => {
      state.ui.deleteCommentStatus = ActionStatus.Pending;
      state.deletedCommentId = action.payload.commentId;
    },
    deleteCommentSucceeded: (state, action: PayloadAction<DeleteCommentRequest>) => {
      state.entities.comments.allIds = state.entities.comments.allIds.filter((commentId) => commentId !== action.payload.commentId);

      delete state.entities.comments.byId[action.payload.commentId];

      state.entities.posts.byId[action.payload.postId].commentIds = state.entities.posts.byId[action.payload.postId].commentIds.filter(
        (commentId) => commentId !== action.payload.commentId
      );
      state.ui.deleteCommentStatus = ActionStatus.Idle;
    },
    deleteCommentFailed: (state) => {
      state.ui.deleteCommentStatus = ActionStatus.Failed;
    },
    reactToPostRequested: (state, action: PayloadAction<ReactToPostRequest>) => {
      state.entities.posts.byId[action.payload.postId].reactions[action.payload.emoji] ??= [];
      state.entities.posts.byId[action.payload.postId].reactions[action.payload.emoji].push(action.payload as Reaction);
    },
    reactToPostSucceeded: (state) => {},
    reactToPostFailed: (state, action: PayloadAction<ReactToPostRequest>) => {
      state.entities.posts.byId[action.payload.postId].reactions[action.payload.emoji] = state.entities.posts.byId[action.payload.postId].reactions[
        action.payload.emoji
      ].filter((r) => r.authorId !== action.payload.authorId);
    },
    withdrawPostReactionRequested: (state, action: PayloadAction<WithdrawPostReactionRequest>) => {
      state.entities.posts.byId[action.payload.postId].reactions[action.payload.emoji] = state.entities.posts.byId[action.payload.postId].reactions[
        action.payload.emoji
      ].filter((r) => r.authorId !== action.payload.authorId);

      if (state.entities.posts.byId[action.payload.postId].reactions[action.payload.emoji].length === 0)
        delete state.entities.posts.byId[action.payload.postId].reactions[action.payload.emoji];
    },
    withdrawPostReactionSucceeded: (state) => {},
    withdrawPostReactionFailed: (state, action: PayloadAction<WithdrawPostReactionRequest>) => {
      state.entities.posts.byId[action.payload.postId].reactions[action.payload.emoji] ??= [];
      state.entities.posts.byId[action.payload.postId].reactions[action.payload.emoji].push(action.payload as Reaction);
    },
    reactToCommentRequested: (state, action: PayloadAction<ReactToCommentRequest>) => {
      state.entities.comments.byId[action.payload.commentId].reactions[action.payload.emoji] ??= [];
      state.entities.comments.byId[action.payload.commentId].reactions[action.payload.emoji].push(action.payload as Reaction);
    },
    reactToCommentSucceeded: (state) => {},
    reactToCommentFailed: (state, action: PayloadAction<ReactToCommentRequest>) => {
      state.entities.comments.byId[action.payload.commentId].reactions[action.payload.emoji] = state.entities.comments.byId[action.payload.commentId].reactions[
        action.payload.emoji
      ].filter((r) => r.authorId !== action.payload.authorId);
    },
    withdrawCommentReactionRequested: (state, action: PayloadAction<WithdrawCommentReactionRequest>) => {
      state.entities.comments.byId[action.payload.commentId].reactions[action.payload.emoji] = state.entities.comments.byId[action.payload.commentId].reactions[
        action.payload.emoji
      ].filter((r) => r.authorId !== action.payload.authorId);

      if (state.entities.comments.byId[action.payload.commentId].reactions[action.payload.emoji].length === 0)
        delete state.entities.comments.byId[action.payload.commentId].reactions[action.payload.emoji];
    },
    withdrawCommentReactionSucceeded: (state) => {},
    withdrawCommentReactionFailed: (state, action: PayloadAction<WithdrawCommentReactionRequest>) => {
      state.entities.comments.byId[action.payload.commentId].reactions[action.payload.emoji] ??= [];
      state.entities.comments.byId[action.payload.commentId].reactions[action.payload.emoji].push(action.payload as Reaction);
    },
    deletePostRequested: (state, action: PayloadAction<DeletePostRequest>) => {
      state.ui.deletePostStatus = ActionStatus.Pending;
    },
    deletePostSucceeded: (state, action: PayloadAction<DeletePostRequest>) => {
      // TODO: delete reactions and comments
      for (const commentId of state.entities.posts.byId[action.payload.postId].commentIds) {
        delete state.entities.comments.byId[commentId];
        state.entities.comments.allIds = state.entities.comments.allIds.filter((id) => id !== commentId);
      }

      delete state.entities.posts.byId[action.payload.postId];
      state.entities.posts.allIds = state.entities.posts.allIds.filter((postId) => postId !== action.payload.postId);
      state.ui.deletePostStatus = ActionStatus.Idle;
    },
    deletePostFailed: (state) => {
      state.ui.deletePostStatus = ActionStatus.Failed;
    },
    updatePostFileUploadProgress: (state, action: PayloadAction<UploadProgress>) => {
      state.ui.fileUploadProgress = {
        fileProgress: action.payload.fileProgress,
        totalProgress: action.payload.totalProgress
      };
    },
    addCurrentUserToAuthors: (state, action: PayloadAction<Author>) => {
      state.entities.authors.byId[action.payload.id] = action.payload;
      state.entities.authors.allIds.push(action.payload.id);
    },
    retrieveEmployeeItemsRequested: (state) => {
      state.ui.employeeListStatus = ActionStatus.Pending;
    },
    retrieveEmployeeItemsSucceeded: (state, action: PayloadAction<RetrieveEmployeeItemsResponse>) => {
      state.entities.employeeItems.byId = {};
      state.entities.employeeItems.allIds = [];
      for (let i = 0; i < action.payload.employeeItems.length; i++) {
        const employeeItem = action.payload.employeeItems[i];

        state.entities.employeeItems.byId[employeeItem.employeeId] = {
          employeeId: employeeItem.employeeId,
          displayName: employeeItem.displayName,
          profilePictureUrl: employeeItem.profilePictureUrl
        } as EmployeeItem;
        state.entities.employeeItems.allIds.push(employeeItem.employeeId);
      }
      state.ui.employeeListStatus = ActionStatus.Idle;
    },
    retrieveEmployeeItemsFailed: (state) => {},
    retrievePostCreateDeleteSasUriRequested: (state) => {
      state.postCreateDeleteSasUri = '';
    },
    retrievePostCreateDeleteSasUriSucceeded: (state, action: PayloadAction<SasUri>) => {
      state.postCreateDeleteSasUri = action.payload.uri;
    },
    retrievePostCreateDeleteSasUriFailed: (state) => {},
    retrievePostReadSasTokenRequested: (state) => {},
    retrievePostReadSasTokenSucceeded: (state, action: PayloadAction<SasToken>) => {
      state.postReadSasToken = action.payload.token;
    },
    retrievePostReadSasTokenFailed: (state) => {},
    refreshPostCreateDeleteSasUriRequested: (state, action: PayloadAction<RefreshPostCreateDeleteSasUriRequest>) => {},
    refreshPostCreateDeleteSasUriSucceeded: (state, action: PayloadAction<SasUri>) => {
      state.postCreateDeleteSasUri = action.payload.uri;
    },
    refreshPostCreateDeleteSasUriFailed: (state) => {},
    retrieveEmployeeRecentPostsRequested: (state, action: PayloadAction<RetrieveRecentPostsByAuthorRequest>) => {
      state.ui.employeeRecentPostsStatus = ActionStatus.Pending;
      if (action.payload.pageNumber === 1) {
        state.entities.employeeRecentPosts.allIds = [];
        state.entities.employeeRecentPosts.byId = {};
      }
      state.currentRecentPostsPage = action.payload.pageNumber;
    },
    retrieveEmployeeRecentPostsSucceeded: (state, action: PayloadAction<RetrieveRecentPostsByAuthorResponse>) => {
      const { posts } = action.payload;

      for (let i = 0; i < posts.length; i++) {
        const post = posts[i].content.value!;

        state.entities.employeeRecentPosts.allIds.push(post.id);

        switch (posts[i].content.case) {
          case 'shoutout':
            const shoutout = post as Shoutout;
            state.entities.employeeRecentPosts.byId[shoutout.id] = mapShoutout(shoutout);
            break;
          case 'kudos':
            const kudos = post as Kudos;
            state.entities.employeeRecentPosts.byId[kudos.id] = mapKudos(kudos);
            break;
          case 'poll':
            break;
        }
      }
      state.hasMoreRecentPosts = state.entities.employeeRecentPosts.allIds.length < action.payload.totalCount;
      state.ui.employeeRecentPostsStatus = ActionStatus.Idle;
    },
    retrieveEmployeeRecentPostsFailed: (state) => {
      state.ui.employeeRecentPostsStatus = ActionStatus.Failed;
    },
    updateAuthorDisplayName: (state, action: PayloadAction<Author>) => {
      if (state.entities.authors.byId.hasOwnProperty(action.payload.id)) state.entities.authors.byId[action.payload.id].name = action.payload.name;
    },
    saveDraftPostRequested: (state, action: PayloadAction<SaveDraftPostRequest>) => {
      state.ui.isPostSavedToDraftStatus = ActionStatus.Pending;
    },
    saveDraftPostSucceeded: (state) => {
      state.ui.isPostSavedToDraft = true;
      state.ui.isPostSavedToDraftStatus = ActionStatus.Idle;
    },
    saveDraftPostFailed: (state) => {
      state.ui.isPostSavedToDraftStatus = ActionStatus.Failed;
    },
    retrieveLastDraftPostRequested: (state, action: PayloadAction<RetrieveLastDraftPostRequest>) => {},
    retrieveLastDraftPostSucceeded: (state, action: PayloadAction<DraftPost>) => {
      state.postDraft = action.payload;
    },
    retrieveLastDraftPostFailed: (state) => {},
    deleteDraftPostsRequested: (state, action: PayloadAction<DeleteDraftPostsRequest>) => {
      state.ui.isPostDraftDeletedStatus = ActionStatus.Pending;
    },
    deleteDraftPostsSucceeded: (state) => {
      state.ui.isPostDraftDeleted = true;
      state.ui.isPostDraftDeletedStatus = ActionStatus.Idle;
      state.postDraft = {} as DraftPost;
    },
    deleteDraftPostsFailed: (state) => {
      state.ui.isPostDraftDeletedStatus = ActionStatus.Failed;
    },
    applyDraftedPost: (state) => {
      state.postDraftText = state.postDraft.text;
    },
    clearDraftedPost: (state) => {
      state.postDraftText = '';
    },
    editCommentRequested: (state, action: PayloadAction<EditCommentRequest>) => {},
    editCommentSucceeded: (state, action: PayloadAction<EditCommentRequest>) => {
      state.entities.comments.byId[action.payload.commentId] = {
        ...state.entities.comments.byId[action.payload.commentId],
        text: action.payload.commentText,
        editedTime: DateTime.utc()
      } as CommentModel;
    },
    editCommentFailed: (state) => {},
    retrieveAudiencesWithPostingRightsByEmployeeIdRequested: (state, action: PayloadAction<RetrieveAudiencesWithPostingRightsByEmployeeIdRequest>) => {
      state.entities.audiences.byId = {};
      state.entities.audiences.withPostingPrivilegesAudienceIds = [];
      state.ui.audiencesWithPostingPrivilegesStatus = ActionStatus.Pending;
    },
    retrieveAudiencesWithPostingRightsByEmployeeIdSucceeded: (state, action: PayloadAction<RetrieveAudiencesWithPostingRightsByEmployeeIdResponse>) => {
      const { audiences } = action.payload;

      for (const audience of audiences) {
        state.entities.audiences.byId[audience.audienceId] = audience;
        state.entities.audiences.withPostingPrivilegesAudienceIds.push(audience.audienceId);
      }

      state.ui.audiencesWithPostingPrivilegesStatus = ActionStatus.Idle;
    },
    retrieveAudiencesWithPostingRightsByEmployeeIdFailed: (state) => {
      state.ui.audiencesWithPostingPrivilegesStatus = ActionStatus.Failed;
    },
    retrieveMentionEmployeesRequested: (state, action: PayloadAction<RetrieveEmployeeIdsByAudienceIdRequest>) => {
      state.ui.mentionEmployeesStatus = ActionStatus.Pending;
      state.entities.mentionEmployees.byId = {};
      state.entities.mentionEmployees.allIds = [];
    },
    retrieveMentionEmployeesSucceeded: (state, action: PayloadAction<EmployeeListItem[]>) => {
      const employees = action.payload;
      for (const employee of employees) {
        state.entities.mentionEmployees.byId[employee.employeeId] = employee;
        state.entities.mentionEmployees.allIds.push(employee.employeeId);
      }
      state.ui.mentionEmployeesStatus = ActionStatus.Idle;
    },
    retrieveMentionEmployeesFailed: (state) => {
      state.ui.mentionEmployeesStatus = ActionStatus.Failed;
    }
  }
});

export const selectIsPostDialogOpened = (state: RootState) => state.posts.ui.isPostDialogOpened;
export const selectIsNewPostPublished = (state: RootState) => state.posts.ui.isNewPostPublished;
export const selectNewPostsCounter = (state: RootState) => state.posts.ui.newPostsCounter;
export const selectPostsStatus = (state: RootState) => state.posts.ui.postsStatus;
export const selectPublishPostStatus = (state: RootState) => state.posts.ui.publishPostStatus;
export const selectCommentOnPostStatus = (state: RootState) => state.posts.ui.commentOnPostStatus;
export const selectDeleteCommentStatus = (state: RootState) => state.posts.ui.deleteCommentStatus;
export const selectUploadPostFileStatus = (state: RootState) => state.posts.ui.uploadPostFileStatus;
export const selectPostFileUploadProgress = (state: RootState) => state.posts.ui.fileUploadProgress.fileProgress;
export const selectPostFilesUploadProgress = (state: RootState) => state.posts.ui.fileUploadProgress.totalProgress;

export const selectPinnedPostReactionsGroupedByEmoji = (state: RootState, postId: string) => state.posts.entities.posts.byId[postId].reactions;

export const selectIsPostFileUploading = (state: RootState, filename: string) =>
  state.posts.ui.postFilesWithStatus.find((fws) => fws.file.name === filename)!.isUploading;

export const selectIsPostFileUploaded = (state: RootState, filename: string) =>
  state.posts.ui.postFilesWithStatus.find((fws) => fws.file.name === filename)!.isUploaded;

export const selectPostFileUrl = (state: RootState, localFilename: string) => state.posts.ui.postFilesWithStatus.find((fws) => fws.name === localFilename)!.url;

export const selectNewPostFiles = (state: RootState) => state.posts.ui.postFilesWithStatus;

export const selectPostFilesWithStatus = (state: RootState) => state.posts.ui.postFilesWithStatus;

export const selectPostFileWithStatus = (state: RootState, filename: string) => state.posts.ui.postFilesWithStatus.find((fws) => fws.file.name === filename);
export const selectPostFilesWithStatusForUpload = (state: RootState) =>
  state.posts.ui.postFilesWithStatus.filter((fws) => fws.isUploaded === false && fws.isUploading === false && fws.url === '');

export const selectNewFilesUrls = createSelector([selectNewPostFiles], (fws) => fws.map((m) => m.url));

export const selectAuthorsByIds = (state: RootState) => state.posts.entities.authors.byId;
export const selectAuthorById = createSelector([selectAuthorsByIds, (_, authorId: string) => authorId], (byId, authorId) => byId[authorId]);
export const selectAuthorIds = (state: RootState) => state.posts.entities.authors.allIds;

export const selectDeletePostStatus = (state: RootState) => state.posts.ui.deletePostStatus;

export const selectPostReactionsGroupedByEmoji = (state: RootState, postId: string) => state.posts.entities.posts.byId[postId].reactions;

export const selectCommentReactionsGroupedByEmoji = (state: RootState, commentId: string) => state.posts.entities.comments.byId[commentId].reactions;

export const selectComments = (state: RootState) => state.posts.entities.comments;

export const selectCommentIdsByPostId = (state: RootState, postId: string) => state.posts.entities.posts.byId[postId].commentIds;

export const selectPostStatus = (state: RootState) => state.posts.ui.postsStatus;

export const selectPostIds = (state: RootState) => state.posts.entities.posts.allIds;

export const selectPosts = (state: RootState) => state.posts.entities.posts.byId;

export const selectPostCount = (state: RootState) => state.posts.entities.posts.allIds.length;

export const selectPostById = (state: RootState, postId: string) => state.posts.entities.posts.byId[postId];

export const selectSignalRConnectionId = (state: RootState) => state.posts.signalRConnectionId;

export const selectEmployeeItemIds = (state: RootState) => state.posts.entities.employeeItems.allIds;
export const selectEmployeeItems = (state: RootState) => state.posts.entities.employeeItems.byId;

export const selectEmployeeItemsStatus = (state: RootState) => state.posts.ui.employeeListStatus;

export const selectPostCreateDeleteSasUri = (state: RootState) => state.posts.postCreateDeleteSasUri;
export const selectPostCreateDeleteSasToken = createSelector([selectPostCreateDeleteSasUri], (pwds) => new URL(pwds).search);
export const selectPostReadSasToken = (state: RootState) => state.posts.postReadSasToken;
export const selectHasMorePosts = (state: RootState) => state.posts.hasMore;
export const selectCurrentPostsPage = (state: RootState) => state.posts.currentPostsPage;
export const selectEmployeeRecentPostsIds = (state: RootState) => state.posts.entities.employeeRecentPosts.allIds;
export const selectEmployeeRecentPosts = (state: RootState) => state.posts.entities.employeeRecentPosts.byId;
export const selectEmployeeRecentPost = (state: RootState, postId: string) => state.posts.entities.employeeRecentPosts.byId[postId];
export const selectEmployeeRecentPostsStatus = (state: RootState) => state.posts.ui.employeeRecentPostsStatus;
export const selectEmployeeRecentPostReactionsGroupedByEmoji = (state: RootState, postId: string) =>
  state.posts.entities.employeeRecentPosts.byId[postId].reactions;
export const selectEmployeeHasMoreRecentPosts = (state: RootState) => state.posts.hasMoreRecentPosts;
export const selectEmployeeCurrentRecentPostsPage = (state: RootState) => state.posts.currentRecentPostsPage;
export const selectDeletedCommentId = (state: RootState) => state.posts.deletedCommentId!;

export const selectIsPostSavedToDraft = (state: RootState) => state.posts.ui.isPostSavedToDraft;
export const selectIsPostSavedToDraftStatus = (state: RootState) => state.posts.ui.isPostSavedToDraftStatus;
export const selectIsPostDraftDeleted = (state: RootState) => state.posts.ui.isPostDraftDeleted;
export const selectIsPostDraftDeletedStatus = (state: RootState) => state.posts.ui.isPostDraftDeletedStatus;
export const selectPostDraft = (state: RootState) => state.posts.postDraft;
export const selectPostDraftText = (state: RootState) => state.posts.postDraftText;

export const selectAudiencesById = (state: RootState) => state.posts.entities.audiences.byId;
export const selectPostsAudienceIds = (state: RootState) => state.posts.entities.audiences.retrievedPostsAudienceIds;
export const selectAudiencesWithPostingPrivilegesIds = (state: RootState) => state.posts.entities.audiences.withPostingPrivilegesAudienceIds;
export const selectAudienceIdsWithPostingPrivilegesStatus = (state: RootState) => state.posts.ui.audiencesWithPostingPrivilegesStatus;

export const selectAudiencesWithNames = createSelector([selectAudiencesById, selectAudiencesWithPostingPrivilegesIds], (byId, ids) =>
  ids.map((id) => byId[id])
);

export const selectAudienceIdByPostId = createSelector([selectPostById], (post) => post.audienceIds[0]);

export const selectMentionEmployeesById = (state: RootState) => state.posts.entities.mentionEmployees.byId;
export const selectMentionEmployeesIds = (state: RootState) => state.posts.entities.mentionEmployees.allIds;

export const selectMentionEmployees = createSelector([selectMentionEmployeesById, selectMentionEmployeesIds], (byId, ids) => ids.map((id) => byId[id]));

export const {
  setCurrentAuthor,
  openPostDialog,
  newPostPublished,
  showNewReactionRequested,
  showNewReactionSucceeded,
  closePostDialogRequested,
  closePostDialogSucceeded,
  closePostDialogFailed,
  withdrawPostReaction,
  showNewCommentRequested,
  showNewCommentSucceeded,
  deleteCommentOnPost,
  showNewReactionToCommentRequested,
  showNewReactionToCommentSucceeded,
  withdrawCommentReaction,
  deletePost,
  editPostRequested,
  editPostSucceeded,
  editPostFailed,
  editShoutout,
  closeNewPostNotification,
  retrievePostsRequested,
  retrievePostsSucceeded,
  retrievePostsFailed,
  uploadPostFilesRequested,
  uploadPostFilesSucceeded,
  uploadPostFilesFailed,
  deletePostSingleFileRequested,
  deletePostSingleFileSucceeded,
  deletePostSingleFileFailed,
  publishShoutoutRequested,
  publishShoutoutSucceeded,
  publishShoutoutFailed,
  commentOnPostRequested,
  commentOnPostSucceeded,
  commentOnPostFailed,
  deleteCommentRequested,
  deleteCommentSucceeded,
  deleteCommentFailed,
  reactToPostRequested,
  reactToPostSucceeded,
  reactToPostFailed,
  withdrawPostReactionRequested,
  withdrawPostReactionSucceeded,
  withdrawPostReactionFailed,
  reactToCommentRequested,
  reactToCommentSucceeded,
  reactToCommentFailed,
  withdrawCommentReactionRequested,
  withdrawCommentReactionSucceeded,
  withdrawCommentReactionFailed,
  deletePostRequested,
  deletePostSucceeded,
  deletePostFailed,
  updatePostFileUploadProgress,
  postFileUploaded,
  uploadPostFile,
  editKudos,
  publishKudos,
  addCurrentUserToAuthors,
  setSignalRConnectionId,
  retrieveEmployeeItemsRequested,
  retrieveEmployeeItemsSucceeded,
  retrieveEmployeeItemsFailed,
  retrievePostCreateDeleteSasUriFailed,
  retrievePostCreateDeleteSasUriRequested,
  retrievePostCreateDeleteSasUriSucceeded,
  retrievePostReadSasTokenFailed,
  retrievePostReadSasTokenRequested,
  retrievePostReadSasTokenSucceeded,
  refreshPostCreateDeleteSasUriFailed,
  refreshPostCreateDeleteSasUriRequested,
  refreshPostCreateDeleteSasUriSucceeded,
  retrieveEmployeeRecentPostsRequested,
  retrieveEmployeeRecentPostsSucceeded,
  retrieveEmployeeRecentPostsFailed,
  updateAuthorProfilePicture,
  updateAuthorDisplayName,
  saveDraftPostRequested,
  saveDraftPostSucceeded,
  saveDraftPostFailed,
  retrieveLastDraftPostRequested,
  retrieveLastDraftPostSucceeded,
  retrieveLastDraftPostFailed,
  deleteDraftPostsRequested,
  deleteDraftPostsSucceeded,
  deleteDraftPostsFailed,
  applyDraftedPost,
  clearDraftedPost,
  editCommentRequested,
  editCommentSucceeded,
  editCommentFailed,
  retrieveAudiencesWithPostingRightsByEmployeeIdFailed,
  retrieveAudiencesWithPostingRightsByEmployeeIdRequested,
  retrieveAudiencesWithPostingRightsByEmployeeIdSucceeded,
  retrieveMentionEmployeesRequested,
  retrieveMentionEmployeesSucceeded,
  retrieveMentionEmployeesFailed,
  addAuthor
} = postsSlice.actions;
export default postsSlice.reducer;
