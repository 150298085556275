import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { RowCenterStack, StyledAlert, StyledBlurryModal, StyledModalContent, StyledTransparentInput, StyledWorkDayInput } from '@/shared';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { DayOfWeek, UpdateWorkingPatternRequest, WorkDay, WorkingPatternListItem } from '@thrivea/organization-client';
import { createWorkingPatternSchema, selectSingleWorkingPattern, updateWorkingPatternByIdRequested } from '@features/admin-settings';
import { zodResolver } from '@hookform/resolvers/zod';
import { handleNumericInputChange } from '@utils/parseNumberInput';

interface AdminSettingsWorkingPatternEditProps {
  isOpen: boolean;
  handleCloseModal: () => void;
  workingPatternListItem: WorkingPatternListItem;
}

const dayOfWeek = Object.keys(DayOfWeek)
  .filter((key) => key !== 'DAY_OF_WEEK_UNSPECIFIED' && isNaN(Number(key))) // Filter out 'DAY_OF_WEEK_UNSPECIFIED' and numeric keys
  .map((key) => ({
    id: DayOfWeek[key as keyof typeof DayOfWeek],
    name: key
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
  }));

export const AdminSettingsWorkingPatternEdit: React.FC<AdminSettingsWorkingPatternEditProps> = ({ isOpen, handleCloseModal, workingPatternListItem }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'sites_working_patterns']);
  const workingPattern = useAppSelector(selectSingleWorkingPattern);
  const [totalHours, setTotalHours] = useState(workingPatternListItem.weeklyHours);
  const [workHours, setWorkHours] = useState(workingPattern.workWeek!.workDays.map((day) => day.hours));
  const workingPatternSchema = useMemo(() => createWorkingPatternSchema(t), [t]);

  const { handleSubmit, control, reset } = useForm<UpdateWorkingPatternRequest>({
    mode: 'all',
    resolver: zodResolver(workingPatternSchema),
    defaultValues: {
      name: workingPatternListItem.name,
      description: workingPatternListItem.description,
      workDays: workingPattern.workWeek!.workDays
    }
  });

  const handleHoursChange = (index: number, value: number) => {
    setWorkHours((prev) => {
      const newHours = [...prev];
      newHours[index] = value;
      return newHours;
    });
  };

  const onSubmit = (data: UpdateWorkingPatternRequest) => {
    dispatch(
      updateWorkingPatternByIdRequested(
        new UpdateWorkingPatternRequest({
          workingPatternId: workingPatternListItem.id,
          name: data.name,
          description: data.description,
          workDays: dayOfWeek.map(
            (dow, index) =>
              new WorkDay({
                id: data.workDays[index].id,
                day: dow.id,
                hours: data.workDays[index].hours
              })
          )
        })
      )
    );
    handleCloseModal();
  };

  useEffect(() => {
    reset({
      name: workingPatternListItem.name,
      description: workingPatternListItem.description,
      workDays: workingPattern.workWeek!.workDays
    });
    setWorkHours(workingPattern.workWeek!.workDays.map((day) => day.hours));
  }, [workingPattern]);

  useEffect(() => {
    setTotalHours(workHours.reduce((acc, hour) => acc + hour, 0));
  }, [workHours]);

  return (
    <StyledBlurryModal open={isOpen} onClose={handleCloseModal}>
      <StyledModalContent
        sx={{
          position: 'relative',
          justifyContent: 'center',
          minWidth: {
            xs: 'auto',
            lg: 1438
          },
          height: {
            xs: '80%',
            lg: 'auto'
          },
          minHeight: {
            xs: 'auto',
            lg: 598
          },
          padding: '60px 120px',
          display: 'flex',
          borderRadius: '20px'
        }}
      >
        <Stack
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          gap={2}
          sx={{
            width: 600,
            my: 2,
            mx: 'auto'
          }}
        >
          <RowCenterStack
            sx={{
              width: '100%',
              justifyContent: 'space-between'
            }}
          >
            <Typography variant="h4">{t('edit_working_pattern', { ns: 'settings_working_patterns', value: workingPatternListItem.name })}</Typography>
            <IconButton
              disableRipple
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                zIndex: 1
              }}
              onClick={handleCloseModal}
            >
              <CloseIcon />
            </IconButton>
          </RowCenterStack>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 600
            }}
          >
            {t('add_working_pattern_form.title', { ns: 'settings_working_patterns' })}
          </Typography>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <StyledTransparentInput
                {...field}
                required
                id={field.name}
                label={t('add_working_pattern_form.name_label', { ns: 'settings_working_patterns' })}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            render={({ field, fieldState }) => (
              <StyledTransparentInput
                {...field}
                multiline
                minRows={3}
                id={field.name}
                label={t('add_working_pattern_form.desc_label', { ns: 'settings_working_patterns' })}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <RowCenterStack
            sx={{
              border: (theme) => `1px solid ${theme.palette.primary.main}`,
              width: 600,
              justifyContent: 'center',
              pt: 2,
              pb: 1
            }}
          >
            {dayOfWeek.map((day, index) => {
              return (
                <Fragment key={day.id}>
                  <Controller
                    name={`workDays.${index}.hours`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <Stack
                        sx={{
                          position: 'relative'
                        }}
                      >
                        <StyledWorkDayInput
                          {...field}
                          label={t(`add_working_pattern_form.${day.name.toLowerCase()}`, { ns: 'settings_working_patterns' })}
                          id={`working_pattern_${day.name}`}
                          width={50}
                          onChange={(e) => {
                            handleHoursChange(index, parseFloat(e.target.value));
                            handleNumericInputChange({ e, onChange: field.onChange });
                          }}
                          inputProps={{ inputMode: 'numeric' }}
                          error={!!fieldState.error}
                          helperText={
                            fieldState.error ? (
                              <StyledAlert
                                sx={{
                                  position: 'absolute',
                                  width: 250,
                                  top: 70,
                                  left: 0
                                }}
                                severity="error"
                              >
                                {fieldState.error.message}
                              </StyledAlert>
                            ) : null
                          }
                        />
                      </Stack>
                    )}
                  />
                </Fragment>
              );
            })}
          </RowCenterStack>
          <RowCenterStack gap={1} sx={{ alignSelf: 'flex-end' }}>
            <Typography variant="body1">{t('add_working_pattern_form.weekly_total', { ns: 'settings_working_patterns' })}</Typography>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              {totalHours} hours
            </Typography>
          </RowCenterStack>
          <RowCenterStack
            gap={2}
            sx={{
              justifyContent: 'flex-end'
            }}
          >
            <Button variant="outlined" onClick={handleCloseModal}>
              {t('cancel', { ns: 'common' })}
            </Button>
            <Button type="submit" variant="contained">
              {t('save', { ns: 'common' })}
            </Button>
          </RowCenterStack>
        </Stack>
      </StyledModalContent>
    </StyledBlurryModal>
  );
};
