import { useTheme } from '@mui/material';
import React from 'react';

interface TalentIconAttributes {
  className?: string;
}

export const TalentIcon: React.FC<TalentIconAttributes> = ({ className = '' }: TalentIconAttributes) => {
  const theme = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 26">
      <path
        fill={theme.palette.common.black}
        d="M23.75 9.988a2.25 2.25 0 0 0-4.5 0 2.25 2.25 0 0 0 .585 1.5l-2.715 1.89a.75.75 0 0 1-1.08-.24L13.617 8.93a2.25 2.25 0 1 0-2.25 0L8.96 13.138a.75.75 0 0 1-1.08.24l-2.715-1.89a2.25 2.25 0 0 0 .585-1.5 2.25 2.25 0 1 0-2.558 2.25l.975 7.088a2.25 2.25 0 0 0 2.25 1.942h12.188a2.25 2.25 0 0 0 2.25-1.942l.952-7.088a2.25 2.25 0 0 0 1.943-2.25ZM12.5 6.238a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5Zm-9.75 3.75a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0Zm16.597 9.105a.75.75 0 0 1-.75.645H6.395a.75.75 0 0 1-.75-.645l-.825-6 2.197 1.5a2.25 2.25 0 0 0 3.233-.75l2.25-3.855 2.25 3.893a2.25 2.25 0 0 0 3.232.75l2.198-1.5-.833 5.962Zm2.153-8.355a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
      />
    </svg>
  );
};
