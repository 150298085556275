import React, { useState } from 'react';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { RowCenterStack, StyledBlurryModal, StyledModalContent, StyledTransparentInput, StyledWorkDayInput } from '@/shared';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { CreateWorkingPatternRequest, DayOfWeek } from '@thrivea/organization-client';
import { Controller, useForm } from 'react-hook-form';
import { createWorkingPatternRequested } from '@features/admin-settings';
import { useAppDispatch } from '@app/hooks';

interface AdminSettingsWorkingPatternAddProps {
  isOpen: boolean;
  handleCloseModal: () => void;
}

const dayOfWeek = Object.keys(DayOfWeek)
  .filter((key) => key !== 'DAY_OF_WEEK_UNSPECIFIED' && isNaN(Number(key))) // Filter out 'DAY_OF_WEEK_UNSPECIFIED' and numeric keys
  .map((key) => ({
    id: DayOfWeek[key as keyof typeof DayOfWeek],
    name: key
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
  }));

export const AdminSettingsWorkingPatternAdd: React.FC<AdminSettingsWorkingPatternAddProps> = ({ isOpen, handleCloseModal }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'sites_working_patterns']);
  const {
    formState: { errors },
    handleSubmit,
    reset,
    control
  } = useForm<CreateWorkingPatternRequest>({
    mode: 'all',
    defaultValues: {
      name: '',
      description: '',
      workDays: []
    }
  });
  const [totalHours, setTotalHours] = useState(0);
  const [workHours, setWorkHours] = useState(dayOfWeek.map(() => 0));

  const handleHoursChange = (index: number, value: number) => {
    setWorkHours((prev) => {
      const newHours = [...prev];
      newHours[index] = value;
      setTotalHours(newHours.reduce((acc, hour) => acc + hour, 0));
      return newHours;
    });
  };

  const onSubmit = (data: CreateWorkingPatternRequest) => {
    dispatch(
      createWorkingPatternRequested(
        new CreateWorkingPatternRequest({
          name: data.name,
          description: data.description,
          workDays: data.workDays
        })
      )
    );
    reset({
      name: '',
      description: '',
      workDays: []
    });
    handleCloseModal();
  };

  return (
    <StyledBlurryModal open={isOpen} onClose={handleCloseModal}>
      <StyledModalContent
        sx={{
          position: 'relative',
          justifyContent: 'center',
          minWidth: {
            xs: 'auto',
            lg: 1438
          },
          height: {
            xs: '80%',
            lg: 'auto'
          },
          minHeight: {
            xs: 'auto',
            lg: 598
          },
          padding: '60px 120px',
          display: 'flex',
          borderRadius: '20px'
        }}
      >
        <Stack
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          gap={2}
          sx={{
            width: 600,
            my: 2,
            mx: 'auto'
          }}
        >
          <RowCenterStack
            sx={{
              width: '100%',
              justifyContent: 'space-between'
            }}
          >
            <Typography variant="h4"> {t('add_working_pattern_form.btn_txt', { ns: 'settings_working_patterns' })}</Typography>
            <IconButton
              disableRipple
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                zIndex: 1
              }}
              onClick={handleCloseModal}
            >
              <CloseIcon />
            </IconButton>
          </RowCenterStack>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 600
            }}
          >
            {t('add_working_pattern_form.title', { ns: 'settings_working_patterns' })}
          </Typography>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <StyledTransparentInput
                {...field}
                required
                id={field.name}
                label={t('add_working_pattern_form.name_label', { ns: 'settings_working_patterns' })}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            render={({ field, fieldState }) => (
              <StyledTransparentInput
                {...field}
                multiline
                minRows={3}
                id={field.name}
                label={t('add_working_pattern_form.desc_label', { ns: 'settings_working_patterns' })}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <RowCenterStack
            sx={{
              border: (theme) => `1px solid ${theme.palette.primary.main}`,
              width: 600,
              justifyContent: 'center',
              pt: 2,
              pb: 1
            }}
          >
            {dayOfWeek.map((day, index) => (
              <div key={day.id}>
                <Controller
                  name={`workDays.${index}.hours`}
                  defaultValue={0}
                  control={control}
                  rules={{ min: 0, max: 24 }}
                  render={({ field, fieldState }) => (
                    <StyledWorkDayInput
                      {...field}
                      label={t(`add_working_pattern_form.${day.name.toLowerCase()}`, { ns: 'settings_working_patterns' })}
                      id={`working_pattern_${day.name}`}
                      width={50}
                      onChange={(e) => {
                        const val = parseFloat(e.target.value);
                        if (isNaN(val) || val <= 0 || val > 24) {
                          e.preventDefault();
                        } else {
                          field.onChange(val);
                          handleHoursChange(index, val);
                        }
                      }}
                      inputProps={{ inputMode: 'numeric' }}
                    />
                  )}
                />
                <input type="hidden" {...control.register(`workDays.${index}.id`)} value={crypto.randomUUID()} />
                <input type="hidden" {...control.register(`workDays.${index}.day`, { valueAsNumber: true })} value={day.id} />
              </div>
            ))}
          </RowCenterStack>
          <RowCenterStack gap={1} sx={{ alignSelf: 'flex-end' }}>
            <Typography variant="body1"> {t('add_working_pattern_form.weekly_total', { ns: 'settings_working_patterns' })}</Typography>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              {' '}
              {totalHours} hours
            </Typography>
          </RowCenterStack>
          <RowCenterStack
            gap={2}
            sx={{
              justifyContent: 'flex-end'
            }}
          >
            <Button variant="outlined" onClick={handleCloseModal}>
              {t('cancel', { ns: 'common' })}
            </Button>
            <Button type="submit" variant="contained">
              {t('save', { ns: 'common' })}
            </Button>
          </RowCenterStack>
        </Stack>
      </StyledModalContent>
    </StyledBlurryModal>
  );
};
