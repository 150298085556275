import React from 'react';
import { Avatar, AvatarGroup, Box, Divider, Grid, IconButton, List, ListItem, Modal, Stack, Typography } from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  timelineItemClasses,
  timelineContentClasses
} from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { RowCenterStack, StyledModalContent } from '@/shared';
import { useAppSelector } from '@app/hooks';
import { selectCurrentUserFullName, selectCurrentUserInitials, selectCurrentUserProfilePictureUrl } from '@app/user';
import { blobWithSasTokenUrl } from '@utils/blobWithSasTokenUrl';
import { selectEmployeeProfileAndCoverReadSasToken } from '@features/employee-profile';

interface ProfileTimelineModalProps {
  isModalOpen: boolean;
  handleModalOpen: () => void;
  handleModalClose: () => void;
}

export const ProfileTimelineModal: React.FC<ProfileTimelineModalProps> = ({ isModalOpen, handleModalOpen, handleModalClose }) => {
  const currentUserFullName = useAppSelector(selectCurrentUserFullName);
  const currentUserInitials = useAppSelector(selectCurrentUserInitials);
  const currentUserProfilePicture = useAppSelector(selectCurrentUserProfilePictureUrl);
  const employeeProfileSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);

  return (
    <Modal open={isModalOpen} onClose={handleModalClose}>
      <StyledModalContent
        sx={{
          position: 'relative',
          minWidth: {
            xs: 'auto',
            lg: '600px'
          },
          width: {
            xs: 'calc(100% - 40px)',
            lg: 'auto'
          },
          height: {
            xs: '80%',
            lg: 'auto'
          },
          overflowY: 'scroll',
          padding: '24px',
          display: 'flex'
        }}
      >
        <Stack gap={4}>
          <RowCenterStack
            sx={{
              width: '100%',
              justifyContent: 'space-between',
              backgroundColor: (theme) => theme.palette.common.white,
              zIndex: 1
            }}
          >
            <RowCenterStack gap={1}>
              <Avatar src={blobWithSasTokenUrl(currentUserProfilePicture, employeeProfileSasToken)}>{currentUserInitials}</Avatar>
              <Typography variant="h4">{currentUserFullName}'s employment timeline</Typography>
            </RowCenterStack>
            <IconButton onClick={handleModalClose}>
              <CloseIcon />
            </IconButton>
          </RowCenterStack>
          <Grid container>
            <Grid item xs={12} md={5}>
              <Timeline
                position="left"
                sx={{
                  alignItems: 'flex-start',
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0
                  },
                  [`& .${timelineContentClasses.root}`]: {
                    flex: 0
                  }
                }}
              >
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector
                      sx={{
                        backgroundColor: (theme) => theme.palette.divider,
                        minHeight: 300,
                        width: '1px'
                      }}
                    />
                  </TimelineSeparator>
                  <TimelineContent>Dec</TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <RowCenterStack>
                      <TimelineDot color="secondary" />
                      <Box
                        sx={{
                          width: 250,
                          height: '1px',
                          backgroundColor: (theme) => theme.palette.secondary.main
                        }}
                      />
                    </RowCenterStack>
                  </TimelineSeparator>
                  <TimelineContent>Dec</TimelineContent>
                </TimelineItem>
              </Timeline>
            </Grid>
            <Grid item xs={12} md={7}>
              <Stack
                gap={2}
                sx={{
                  marginTop: '-53px'
                }}
              >
                <Stack gap={2}>
                  <RowCenterStack gap={1}>
                    <Avatar>{currentUserInitials}</Avatar>
                    <Stack>
                      <Typography>{currentUserFullName}</Typography>
                      <Typography>Account manager</Typography>
                    </Stack>
                  </RowCenterStack>
                  <Stack gap={0.5}>
                    <Typography variant="h4">Kudos</Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 400
                      }}
                    >
                      "Esse eu adipisicing pariatur amet minim mollit tempor ex occaecat excepteur elit elit in."
                    </Typography>
                  </Stack>
                  <Divider
                    sx={{
                      mt: 1,
                      mb: 2
                    }}
                  />
                </Stack>
                <Stack gap={2}>
                  <RowCenterStack gap={1}>
                    <Avatar>{currentUserInitials}</Avatar>
                    <Stack>
                      <Typography>{currentUserFullName}</Typography>
                      <Typography>Account manager</Typography>
                    </Stack>
                  </RowCenterStack>
                  <Stack gap={0.5}>
                    <Typography variant="h4">Kudos</Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 400
                      }}
                    >
                      "Duis voluptate proident do ad reprehenderit magna."
                    </Typography>
                  </Stack>
                  <Divider
                    sx={{
                      mt: 1,
                      mb: 1
                    }}
                  />
                </Stack>
              </Stack>
              <Stack>
                <List>
                  <ListItem disablePadding>Site: Novi Sad</ListItem>
                  <ListItem disablePadding>Employment contract: Full time</ListItem>
                </List>
                <RowCenterStack gap={1}>
                  <AvatarGroup
                    max={3}
                    sx={{
                      width: 115
                    }}
                  >
                    <Avatar>MR</Avatar>
                    <Avatar>FR</Avatar>
                    <Avatar>FR</Avatar>
                    <Avatar>GR</Avatar>
                    <Avatar>GR</Avatar>
                  </AvatarGroup>
                  <RowCenterStack gap={1}>
                    <Typography>25/11/2023 -</Typography>
                    <Typography>01/12/2023</Typography>
                  </RowCenterStack>
                </RowCenterStack>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </StyledModalContent>
    </Modal>
  );
};
