import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import {
  CheckNewNotificationRequest,
  CheckNewNotificationResponse,
  RetrieveNotificationsRequest,
  RetrieveNotificationsResponse,
  Notification,
  DeleteNotificationByIdRequest,
  DeleteAllNotificationsRequest,
  MarkNotificationAsReadRequest,
  MarkAllAsReadRequest,
  HasUnreadNotificationsRequest,
  HasUnreadNotificationsResponse
} from '@thrivea/notification-client';
import { EmployeeWithDisplayName, RetrieveDisplayNamesByIdsResponse } from '@thrivea/organization-client';
import { ActionStatus } from '@/shared';

export interface MentionItem {
  id: string;
  value: string;
}

interface NotificationState {
  entities: {
    notifications: {
      byId: { [key: number]: Notification };
      allIds: number[];
      totalCount: number;
    };
    employeesWithNames: {
      byId: { [key: string]: EmployeeWithDisplayName };
      allIds: string[];
    };
  };
  ui: {
    lastNotificationDateTime: string;
    hasUnreadNotification: boolean;
    hasMore: boolean;
    notificationStatus: ActionStatus;
    currentPageNumber: number;
  };
}

const initialState: NotificationState = {
  entities: {
    notifications: { byId: {}, allIds: [], totalCount: 0 },
    employeesWithNames: { byId: {}, allIds: [] }
  },
  ui: {
    lastNotificationDateTime: '',
    hasUnreadNotification: false,
    hasMore: false,
    notificationStatus: ActionStatus.Idle,
    currentPageNumber: 1
  }
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setLastNotificationDateTime: (state, action: PayloadAction<string>) => {
      state.ui.lastNotificationDateTime = action.payload;
      state.ui.hasUnreadNotification = true;
    },
    retrieveNotificationsRequested: (state, action: PayloadAction<RetrieveNotificationsRequest>) => {
      state.ui.notificationStatus = ActionStatus.Pending;
      state.ui.currentPageNumber = action.payload.pageNumber;
    },
    retrieveNotificationsSucceeded: (state, action: PayloadAction<RetrieveNotificationsResponse>) => {
      for (const notification of action.payload.notifications) {
        if (state.entities.notifications.byId.hasOwnProperty(notification.notificationId)) continue;
        state.entities.notifications.byId[notification.notificationId] = notification;
        state.entities.notifications.allIds.push(notification.notificationId);
      }

      state.entities.notifications.totalCount = action.payload.totalCount;
      if (state.entities.notifications.allIds.length < action.payload.totalCount) {
        state.ui.hasMore = true;
      } else {
        state.ui.hasMore = false;
      }
      state.ui.notificationStatus = ActionStatus.Idle;
    },
    retrieveNotificationsFailed: (state) => {},
    retrieveNewNotificationsRequested: (state, action: PayloadAction<RetrieveNotificationsRequest>) => {
      state.ui.notificationStatus = ActionStatus.Pending;
    },
    retrieveNewNotificationsSucceeded: (state, action: PayloadAction<RetrieveNotificationsResponse>) => {
      const newNotifications: number[] = [];
      for (const notification of action.payload.notifications) {
        if (state.entities.notifications.byId.hasOwnProperty(notification.notificationId)) continue;

        state.entities.notifications.byId[notification.notificationId] = notification;
        newNotifications.push(notification.notificationId);
      }

      state.entities.notifications.allIds = [...newNotifications, ...state.entities.notifications.allIds];
      state.entities.notifications.totalCount = action.payload.totalCount;

      state.ui.hasMore = state.entities.notifications.allIds.length < action.payload.totalCount;
      state.ui.notificationStatus = ActionStatus.Idle;
    },
    retrieveNewNotificationsFailed: (state) => {},
    retrieveLastNotificationDateTimeRequested: (state, action: PayloadAction<CheckNewNotificationRequest>) => {},
    retrieveLastNotificationDateTimeSucceeded: (state, action: PayloadAction<CheckNewNotificationResponse>) => {
      state.ui.lastNotificationDateTime = action.payload.notificationCreationDateTime;
    },
    retrieveLastNotificationDateTimeFailed: (state) => {},
    deleteNotificationByIdRequested: (state, action: PayloadAction<DeleteNotificationByIdRequest>) => {},
    deleteNotificationByIdSucceeded: (state, action: PayloadAction<DeleteNotificationByIdRequest>) => {
      delete state.entities.notifications.byId[action.payload.notificationId];
      state.entities.notifications.allIds = state.entities.notifications.allIds.filter((notificationId) => notificationId !== action.payload.notificationId);
      state.entities.notifications.totalCount--;
    },
    deleteNotificationByIdFailed: (state) => {},
    deleteAllNotificationsRequested: (state, action: PayloadAction<DeleteAllNotificationsRequest>) => {},
    deleteAllNotificationsSucceeded: (state) => {
      state.entities = initialState.entities;
      state.ui.hasUnreadNotification = false;
    },
    deleteAllNotificationsFailed: (state) => {},
    markNotificationAsReadRequested: (state, action: PayloadAction<MarkNotificationAsReadRequest>) => {
      state.entities.notifications.byId[action.payload.notificationId] = { ...state.entities.notifications.byId[action.payload.notificationId], isRead: true };
    },
    markNotificationAsReadSucceeded: (state, action: PayloadAction<MarkNotificationAsReadRequest>) => {},
    markNotificationAsReadFailed: (state, action: PayloadAction<MarkNotificationAsReadRequest>) => {
      state.entities.notifications.byId[action.payload.notificationId] = { ...state.entities.notifications.byId[action.payload.notificationId], isRead: false };
    },
    markAllNotificationsAsReadRequested: (state, action: PayloadAction<MarkAllAsReadRequest>) => {},
    markAllNotificationsAsReadSucceeded: (state) => {
      state.ui.hasUnreadNotification = false;
      for (const notificationId of state.entities.notifications.allIds) {
        state.entities.notifications.byId[notificationId].isRead = true;
      }
    },
    markAllNotificationsAsReadFailed: (state) => {},
    hasUnreadNotificationsRequested: (state, action: PayloadAction<HasUnreadNotificationsRequest>) => {},
    hasUnreadNotificationsSucceeded: (state, action: PayloadAction<HasUnreadNotificationsResponse>) => {
      state.ui.hasUnreadNotification = action.payload.hasUnread;
    },
    hasUnreadNotificationsFailed: (state) => {},
    addEmployeesWithNames: (state, action: PayloadAction<RetrieveDisplayNamesByIdsResponse>) => {
      for (const employeeWithName of action.payload.employeesWithDisplayNames) {
        if (state.entities.employeesWithNames.byId.hasOwnProperty(employeeWithName.employeeId)) continue;

        state.entities.employeesWithNames.byId[employeeWithName.employeeId] = employeeWithName;
        state.entities.employeesWithNames.allIds.push(employeeWithName.employeeId);
      }
    }
  }
});

export const selectNotifications = (state: RootState) => state.notifications.entities.notifications;
export const selectLastNotificationDateTime = (state: RootState) => state.notifications.ui.lastNotificationDateTime;
export const selectEmployeesWithDisplayNamesByIds = (state: RootState) => state.notifications.entities.employeesWithNames.byId;
export const selectEmployeesWithDisplayNamesById = (state: RootState, employeeId: string) => state.notifications.entities.employeesWithNames.byId[employeeId];
export const selectEmployeesWithDisplayNames = (state: RootState) => state.notifications.entities.employeesWithNames;
export const selectHasUnreadNotifications = (state: RootState) => state.notifications.ui.hasUnreadNotification;
export const selectHasMore = (state: RootState) => state.notifications.ui.hasMore;
export const selectNotificationStatus = (state: RootState) => state.notifications.ui.notificationStatus;
export const selectCurrentNotificationPageNumber = (state: RootState) => state.notifications.ui.currentPageNumber;
export const selectNotificationsById = (state: RootState, notificationId: number) => state.notifications.entities.notifications.byId[notificationId];
export const selectNotificationAllIds = (state: RootState) => state.notifications.entities.notifications.allIds;
export const selectNotificationTotalCount = (state: RootState) => state.notifications.entities.notifications.totalCount;
export const selectNotificationIsReadById = (state: RootState, notificationId: number) =>
  state.notifications.entities.notifications.byId[notificationId].isRead;

export const {
  setLastNotificationDateTime,
  retrieveNotificationsFailed,
  retrieveNotificationsRequested,
  retrieveNotificationsSucceeded,
  retrieveLastNotificationDateTimeFailed,
  retrieveLastNotificationDateTimeRequested,
  retrieveLastNotificationDateTimeSucceeded,
  deleteNotificationByIdFailed,
  deleteNotificationByIdRequested,
  deleteNotificationByIdSucceeded,
  deleteAllNotificationsFailed,
  deleteAllNotificationsRequested,
  deleteAllNotificationsSucceeded,
  markNotificationAsReadFailed,
  markNotificationAsReadRequested,
  markNotificationAsReadSucceeded,
  markAllNotificationsAsReadFailed,
  markAllNotificationsAsReadRequested,
  markAllNotificationsAsReadSucceeded,
  hasUnreadNotificationsFailed,
  hasUnreadNotificationsRequested,
  hasUnreadNotificationsSucceeded,
  addEmployeesWithNames,
  retrieveNewNotificationsFailed,
  retrieveNewNotificationsRequested,
  retrieveNewNotificationsSucceeded
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
