import React, { useEffect, useState } from 'react';
import { Typography, IconButton, Stack } from '@mui/material';
import { RowCenterStack, StyledTooltip } from '@/shared';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  RenderEmoji,
  reactToCommentRequested,
  selectCommentReactionsGroupedByEmoji,
  selectEmployeeItems,
  withdrawCommentReactionRequested
} from '@features/homepage';
import { ReactToCommentRequest, WithdrawCommentReactionRequest } from '@thrivea/networking-client';
import keys from 'lodash/keys';
import { selectCurrentEmployeeId } from '@app/user';
import { fetchEmojisWithShortcodes } from '@utils/emojiMartShortcode';

interface CommentReactionsProps {
  postId: string;
  commentId: string;
}

export const CommentReactions: React.FC<CommentReactionsProps> = ({ postId, commentId }) => {
  const dispatch = useAppDispatch();
  const commentReactionsGroupedByEmoji = useAppSelector((state) => selectCommentReactionsGroupedByEmoji(state, commentId));
  const currentUserId = useAppSelector(selectCurrentEmployeeId);
  const employeeItems = useAppSelector(selectEmployeeItems);
  const [emojisWithShortcodes, setEmojisWithShortcodes] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const fetchEmojis = async () => {
      const emojis = await fetchEmojisWithShortcodes(commentReactionsGroupedByEmoji);
      setEmojisWithShortcodes(emojis);
    };

    fetchEmojis();
  }, [commentReactionsGroupedByEmoji]);

  const handleEmojiClick = (emoji: string) => {
    if (commentReactionsGroupedByEmoji[emoji].some((reaction) => reaction.authorId === currentUserId)) {
      dispatch(
        withdrawCommentReactionRequested(
          new WithdrawCommentReactionRequest({
            postId,
            commentId,
            emoji,
            authorId: currentUserId
          })
        )
      );
    } else {
      dispatch(
        reactToCommentRequested(
          new ReactToCommentRequest({
            postId,
            commentId,
            emoji,
            authorId: currentUserId
          })
        )
      );
    }
  };

  return (
    <RowCenterStack
      gap={1}
      sx={{
        padding: '2px 8px',
        position: 'relative'
      }}
    >
      {keys(commentReactionsGroupedByEmoji).map((emoji) => (
        <StyledTooltip
          key={emoji}
          title={
            <Stack
              direction="row"
              gap={2}
              sx={{
                wordBreak: 'break-all',
                whiteSpace: 'pre-wrap',
                borderRadius: (theme) => theme.spacing(1),
                padding: (theme) => theme.spacing(2),
                '& img': {
                  maxWidth: '24px !important',
                  maxHeight: '24px !important'
                }
              }}
            >
              <RenderEmoji native={emoji || ''} />
              <RowCenterStack
                sx={{
                  flexWrap: 'wrap'
                }}
              >
                {commentReactionsGroupedByEmoji[emoji].slice(0, 10).map((crg, index) => (
                  <Typography key={crg.authorId}>
                    {employeeItems[crg.authorId]?.displayName}
                    {index !== commentReactionsGroupedByEmoji[emoji].length - 1 && ', '}
                  </Typography>
                ))}
                {commentReactionsGroupedByEmoji[emoji].length > 10 && <Typography> and {commentReactionsGroupedByEmoji[emoji].length - 10} others</Typography>}
                <Typography>
                  {' '}
                  reacted with{' '}
                  <Typography component="span" fontWeight={700}>
                    {emojisWithShortcodes[emoji]}
                  </Typography>
                </Typography>
              </RowCenterStack>
            </Stack>
          }
          enterDelay={500}
          leaveDelay={200}
          placement="top"
        >
          <IconButton
            key={emoji}
            value={postId}
            onClick={() => handleEmojiClick(emoji)}
            sx={{
              bgcolor: (theme) =>
                commentReactionsGroupedByEmoji[emoji].some((reaction) => reaction.authorId === currentUserId)
                  ? theme.palette.primary.light
                  : theme.palette.grey[300],
              minWidth: 32,
              maxHeight: 28,
              padding: '0 7px',
              borderRadius: '7px',
              '& img': {
                width: 14,
                height: 14
              }
            }}
          >
            <RowCenterStack
              gap={1}
              sx={{
                justifyContent: 'center'
              }}
            >
              <RenderEmoji native={emoji || ''} />
              <Typography
                variant="caption"
                sx={{
                  fontWeight: 900,
                  mt: 0.15,
                  lineHeight: 'normal'
                }}
              >
                {commentReactionsGroupedByEmoji[emoji].length}
              </Typography>
            </RowCenterStack>
          </IconButton>
        </StyledTooltip>
      ))}
    </RowCenterStack>
  );
};
