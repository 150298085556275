import { Typography, Grid } from '@mui/material';
import { Stack, Box } from '@mui/system';
import { StyledFormWrapper, StyledFormHeader, StyledFilledInput, StyledDatePickerFilledInput } from '@/shared';
import { ForwardedRef, forwardRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppSelector } from '@app/hooks';
import { selectUserDataCategory } from '@features/employee-profile';
import { UserData, UserState } from '@thrivea/organization-client';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon/AdapterLuxon';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { snakeCase } from 'lodash';

interface UserDataCategoryProps {
  ref: ForwardedRef<HTMLDivElement>;
  employeeId: string;
}

export const UserDataCategory = forwardRef<HTMLDivElement, UserDataCategoryProps>(({ employeeId }, ref) => {
  const { t } = useTranslation('employee_profile');
  const name = t('user_data_category_title');
  const userDataCategory = useAppSelector(selectUserDataCategory);
  const { control } = useForm<UserData>({
    mode: 'onChange',
    defaultValues: {
      state: userDataCategory.state,
      creationDate: userDataCategory.creationDate
    }
  });

  const userStates = Object.keys(UserState)
    .filter((key) => !isNaN(Number(key)))
    .map((key) => ({
      id: parseInt(key, 10),
      name: UserState[Number(key)].toLowerCase()
    }));

  return (
    <StyledFormWrapper id={`${name.toLowerCase().replace(/ /g, '_')}`} ref={ref}>
      <Box component="form" name={name}>
        <Stack>
          <StyledFormHeader className="Mui-ProfileFiledHeader">
            <Typography component="h3" variant="h5" fontWeight="bold">
              {name}
            </Typography>
          </StyledFormHeader>
          <Grid
            container
            spacing={2}
            sx={{
              padding: 2
            }}
          >
            <Grid item xs={12} sm={6} lg={4}>
              <Controller
                name="state"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    label="Status"
                    id="state"
                    value={t(userStates[userDataCategory.state].name, { ns: 'user_status' })}
                    disabled={true}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <Controller
                name="creationDate"
                control={control}
                render={({ field, fieldState }) => (
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DatePicker
                      {...field}
                      className="MuiDate-root"
                      format="yyyy-MM-dd"
                      value={field.value ? DateTime.fromISO(field.value) : null}
                      disableFuture
                      disabled={true}
                      slots={{ textField: StyledDatePickerFilledInput }}
                      slotProps={{
                        textField: {
                          id: name,
                          variant: 'filled',
                          fullWidth: true,
                          placeholder: 'YYYY-MM-DD',
                          label: t(snakeCase(field.name), { ns: 'employee_profile' }),
                          required: true,
                          error: !!fieldState.error,
                          helperText: fieldState.error?.message,
                          InputProps: {
                            disableUnderline: true,
                            readOnly: true
                          }
                        }
                      }}
                      onChange={(date: DateTime | null) => {
                        if (date) {
                          const jsDate = date.toJSDate();
                          const stringDate = DateTime.fromJSDate(jsDate).toFormat('yyyy-MM-dd');
                          field.onChange(stringDate);
                        }
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </StyledFormWrapper>
  );
});
