import React, { memo, useState } from 'react';
import { Stack, Box, Typography, Skeleton } from '@mui/material';
import { DateTime } from 'luxon';
import { PostActions, PostAuthor, PostContent, PostDoc, PostMedia, PostMenu } from '@features/homepage';
import { AudienceWithName, RetrieveAudienceByIdRequest } from '@thrivea/networking-client';
import { RowCenterStack, StyledInlinePostAudienceChip, StyledTransparentButton } from '@/shared';
import { useAppDispatch } from '@app/hooks';
import { openAudienceDrawer, retrieveAudienceByIdRequested } from '@features/admin-settings';

interface PostViewProps {
  postId: string;
  authorId: string;
  date: DateTime;
  files: PostMedia[];
  docs: PostDoc[];
  message: string;
  audience: AudienceWithName;
  editedTime?: DateTime;
}

export const PostView: React.FC<PostViewProps> = memo(({ postId, authorId, date, files, docs, message, audience, editedTime }) => {
  const dispatch = useAppDispatch();
  const [editablePost, setEditablePost] = useState<string | null>(null);

  const handleSetPostEditable = (id: string) => {
    setEditablePost(editablePost === id ? null : id);
  };

  const handleRetrieveAudienceById = (audienceId: string) => {
    dispatch(retrieveAudienceByIdRequested(new RetrieveAudienceByIdRequest({ audienceId })));
    dispatch(openAudienceDrawer());
  };

  return (
    <Stack
      component="article"
      sx={{
        backgroundColor: (theme) => theme.palette.grey[600],
        borderRadius: 2,
        padding: 0
      }}
    >
      <RowCenterStack
        sx={{
          padding: '0 16px',
          my: 1,
          justifyContent: 'space-between'
        }}
      >
        <PostAuthor authorId={authorId} date={date} editedTime={editedTime} />
        <RowCenterStack gap={1}>
          <StyledInlinePostAudienceChip
            label={
              <RowCenterStack>
                <Typography component="span" variant="body2" sx={{ color: (theme) => theme.palette.action.focus }}>
                  @
                </Typography>
                {audience === undefined ? (
                  <Skeleton animation="wave" height={20} width={150} />
                ) : (
                  <StyledTransparentButton disableRipple onClick={() => handleRetrieveAudienceById(audience?.audienceId)}>
                    <Typography component="span" variant="body2">
                      {audience?.audienceName}
                    </Typography>
                  </StyledTransparentButton>
                )}
              </RowCenterStack>
            }
          />
          {editablePost !== postId && <PostMenu postId={postId} handleSetPostEditable={handleSetPostEditable} />}
        </RowCenterStack>
      </RowCenterStack>
      <Box
        sx={{
          padding: '0 16px'
        }}
      >
        <PostContent
          postId={postId}
          authorId={authorId}
          message={message}
          files={files}
          docs={docs}
          isEditable={editablePost === postId}
          handleSetPostEditable={handleSetPostEditable}
        />
      </Box>
      {editablePost !== postId && <PostActions postId={postId} />}
    </Stack>
  );
});
